//EXTERNAL
import { useMemo, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
//COMPONENTS
import * as ComponentLibrary from "@picnicmedia/masanatemples123";
//ASSETS
import placeholder from "../assets/placeholder-background-story.jpg";
import config from "../configs/config";
// LIBS
import { s3_signed_url } from "../libs/amplify";
import { logger } from "../libs/logger";
//FUNCTIONS
import { format_time } from "../helpers/format_time";
import { duplicate_creative } from "../helpers/duplicate_creative";
import { delete_creative } from "../helpers/delete_creative";
//CONTEXT
import { useUserState } from "../contexts/user.context";
import { useMediaLoadingDispatch } from "../contexts/mediaLoading.context";
import { useNotificationDispatch } from "../contexts/notification.context";
//MUTATIONS
import * as mutations from "../graphql/mutations";
//QUERIES
import { GET_EXISTING_CREATIVE, LIST_CREATIVES } from "../graphql/queries";
import studio_config from "../configs/studio.json";
//TYPES
export interface StudioConfig {
  [key: string]: any;
}
interface ParamTypes {
  advertiserId: string;
}

interface CreativesTypes {
  [key: string]: any;
}

export default function Advertiser() {
  const user = useUserState();
  const studioConfig: StudioConfig = studio_config;
  const { advertiserId } = useParams<ParamTypes>();
  const [creatives, setCreatives] = useState<CreativesTypes[]>([]);
  const [advertiserName, setAdvertiserName] = useState("");
  const [deleteCreativeModal, setDeleteCreativeModal] = useState({ open: false, creative: "" });
  const { error, loading, data } = useQuery(LIST_CREATIVES, {
    variables: { teamId: user.teamId, advertiserId: advertiserId },
    // this fetchPolicy prevents Apollo Client relying on cache - so when new creatives are made or duplicated
    // they are visible without having to reload. this can also be set as a defaultOption in the Apollo config. KT 24.03.22
    fetchPolicy: "network-only",
    onCompleted: async (creativeData) => {
      setAdvertiserName(creativeData.readAllCreativesAdvertiser[0].advertiserName.toUpperCase());
      setCreatives(await getPreviews(creativeData.readAllCreativesAdvertiser));
    },
    onError: (error) => {
      logger({ error, user });
    },
  });

  const [updateAllCreatives] = useLazyQuery(LIST_CREATIVES, {
    variables: { teamId: user.teamId, advertiserId: advertiserId },
    fetchPolicy: "network-only",
    onCompleted: async (creativeData) => {
      setCreatives(await getPreviews(creativeData.readAllCreativesAdvertiser));
    },
    onError: (error) => {
      logger({ error, user });
    },
  });

  const [saveNewCreative] = useMutation(mutations.SAVE_NEW_CREATIVE);
  const notificationDispatch = useNotificationDispatch();
  const mediaLoadingDispatch = useMediaLoadingDispatch();
  const [getOneCreativeData] = useLazyQuery(GET_EXISTING_CREATIVE, {
    fetchPolicy: "network-only",
    onCompleted: async (creative_data: any) => {
      return creative_data;
    },
    onError: (error) => {
      logger({ error, user });
    },
  });
  const [deleteCreative] = useMutation(mutations.DELETE_CREATIVE);

  const getPreviews = async (data: any) => {
    return Promise.all(
      data.map(async (creative: any) => ({
        ...creative,
        previewThumbnail: (
          <img
            src={`${await s3_signed_url({ teamId: user.teamId, creativeId: creative.id, fileName: "format-preview-original.png" })}`}
            alt="ad preview thumbnail"
            style={{ borderRadius: "10px", maxWidth: "92.5px" }}
            onError={(e: any) => {
              e.target.src = placeholder;
            }}
          />
        ),
      })),
    );
  };

  const handlePreview = (e: any, value: any) => {
    e.preventDefault();
    e.currentTarget.blur();
    const format = value.metadata.format;
    const aspectRatio = value.metadata.aspectRatio || "picnic";
    const creativeId = value.id;
    const url = `${config.preview.URL}/${studioConfig.formats[format].media.aspectRatios[aspectRatio].adslotWidth}x${studioConfig.formats[format].media.aspectRatios[aspectRatio].adslotHeight}/${user.teamId}/${creativeId}`;
    window.open(url);
  };

  const handleDuplicateCreative = async (e: any, existingCreativeId: string) => {
    e.preventDefault();
    const creative_data = await getOneCreativeData({ variables: { id: existingCreativeId } });
    await duplicate_creative(creative_data.data, creative_data.data.readOneCreativeId.id, user, mediaLoadingDispatch, notificationDispatch, saveNewCreative);
    updateAllCreatives();
  };

  const handleDeleteCreative = async (creativeId: any) => {
    let creative_data = await getOneCreativeData({ variables: { id: creativeId } });
    await delete_creative({ id: creative_data.data.readOneCreativeId.id, teamId: user.teamId }, deleteCreative, mediaLoadingDispatch, notificationDispatch);
    updateAllCreatives();
  };

  const openDeleteCreativeModal = async (e: any, creativeId: string) => {
    e.preventDefault();
    setDeleteCreativeModal({ creative: creativeId, open: !deleteCreativeModal.open });
  };

  const columns = useMemo(
    () => [
      {
        Header: "PREVIEW",
        accessor: "previewThumbnail",
      },
      {
        Header: "AD NAME",
        accessor: "adName",
      },
      {
        Header: "CREATIVE TYPE",
        accessor: "metadata.format",
        Cell: (row: any) => {
          let value = row.cell.value;
          if (value === "swipe") value = "swipe Right";
          return value[0].toUpperCase() + value.substring(1);
        },
      },
      {
        Header: "DATE UPDATED",
        accessor: "updatedAt",
        Cell: (row: any) => format_time(row.cell.value),
      },
      {
        Header: " ",
        accessor: "id",
        Cell: (row: any) => <ComponentLibrary.PCActionButton clickHandler={(e: any) => handlePreview(e, row.cell.row.original)} text={"Preview"} />,
      },
      {
        Header: " ",
        Cell: <ComponentLibrary.PCActionButton text={"Edit"} />,
      },
      {
        Header: "",
        accessor: "duplicate",
        Cell: (row: any) => (
          <ComponentLibrary.PCActionButton
            text={"Duplicate"}
            clickHandler={(e: any) => {
              handleDuplicateCreative(e, row.cell.row.original.id);
            }}
          />
        ),
      },
      {
        Header: "",
        accessor: "delete",
        Cell: (row: any) => (
          <ComponentLibrary.PCActionButton
            text={<ComponentLibrary.PCIconPicker icon={"trash"} />}
            clickHandler={(e: any) => {
              openDeleteCreativeModal(e, row.cell.row.original.id);
            }}
          />
        ),
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [{ id: "updatedAt", desc: true }],
    pageSize: 10,
    pageIndex: 0,
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <div className="picnic-font flex flex-column items-center min-vh-100">
            <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={5}>
              <ComponentLibrary.PCContainerMain>
                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                <ComponentLibrary.PCTableTitle text={advertiserName} />
                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={6} />
                <ComponentLibrary.PCTable columns={columns} data={creatives} initialState={initialState} linkDestination={"/creative/"} />
                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
              </ComponentLibrary.PCContainerMain>
            </ComponentLibrary.PCSpacer>
          </div>
          {/* Delete Creative dialogue box */}
          <ComponentLibrary.PCDialogueBox
            open={deleteCreativeModal.open}
            width={"width-13"}
            height={"height-7"}
            onClose={() => {
              setDeleteCreativeModal({ open: !deleteCreativeModal.open, creative: "" });
            }}
            hideInitialFocus={true}>
            <p className="text-size-3 platform-color-typography-titles-3 platform-font ma0 fw5">{`Do you want to permanently delete this creative?`}</p>
            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
            <div className="flex justify-left">
              <ComponentLibrary.PCActionButton
                text="Delete Creative"
                textColor={"color-text-5"}
                color={""}
                clickHandler={async (e: any) => {
                  await handleDeleteCreative(deleteCreativeModal.creative);
                  setDeleteCreativeModal({ open: !deleteCreativeModal.open, creative: "" });
                }}
              />
              <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
              <ComponentLibrary.PCActionButton
                text="Go Back"
                textColor={"color-text-5"}
                color={""}
                clickHandler={async (e: any) => {
                  setDeleteCreativeModal({ open: !deleteCreativeModal.open, creative: "" });
                }}
              />
            </div>
          </ComponentLibrary.PCDialogueBox>
        </>
      )}
    </>
  );
}
