//EXTERNAL
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useApolloClient, useQuery, useMutation, gql } from "@apollo/client";
//COMPONENTS
import * as ComponentLibrary from "@picnicmedia/masanatemples123";
//ASSETS
//LIBS
import { logger } from "../libs/logger";
//CONTEXT
import { useUserState, useUserDispatch } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";
//QUERIES
const LIST_ALL_TEAMS = gql`
  query {
    readAllTeams {
      id
      name
      primaryUsers
      createdAt
      updatedAt
    }
  }
`;
export default function Settings() {
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const notificationDispatch = useNotificationDispatch();
  const apolloClient = useApolloClient();
  const [email] = useState(user.email);
  const [teamId] = useState(user.teamId);
  const [teamName] = useState(user.teamName);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allTeams, setAllTeams] = useState([]);
  useEffect(() => {
    async function onLoad() {
      if (teamName !== "Picnic") {
        return;
      }
      try {
        let teamsList = await scanAllTeams();
        // MODIFY TO ABIDE BY SELECT COMPONENT REQUIRED DATA STRUCTURE
        let modifiedTeamList = teamsList.readAllTeams.map((item: any) => ({
          label: item.name + " " + item.id,
          value: item.id,
        }));
        setAllTeams(modifiedTeamList);
        setIsLoading(false);
      } catch (error) {
        logger({ error, user });
        let errorMessage = "";
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Error scanning teams",
            message: errorMessage,
            type: "error",
            autoDismiss: false,
          },
        });
        setIsLoading(false);
      }
    }
    onLoad();
  }, []);

  const scanAllTeams = async () => {
    const { data } = await apolloClient.query({
      query: LIST_ALL_TEAMS,
    });
    return data;
  };

  const handleSave = async (event: any) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with password change",
          message: "Your password and confirmation password don't match",
          type: "error",
          autoDismiss: false,
        },
      });
    }
    if (!newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/g)) {
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with sign up",
          message: "Please enter a valid password",
          type: "error",
          autoDismiss: false,
        },
      });
    }
    setIsLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      setIsLoading(false);
      await Auth.changePassword(user, oldPassword, newPassword);
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Details changed",
          message: "Password changed",
          type: "success",
          autoDismiss: true,
        },
      });
    } catch (error) {
      setIsLoading(false);
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Something went wrong. Please try again",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };
  const verifyForm = () => {
    return newPassword.length < 8;
  };
  return (
    <>
      <div className="flex flex-column w-100 min-vh-100 picnic-font">
        <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={5}>
          <ComponentLibrary.PCContainerMain>
            <div className="flex flex-row justify-center">
              <div className="flex flex-column items-center">
                <div className="width-8 height-8">
                  <ComponentLibrary.PCUserAvatar firstname={user.firstName} lastname={user.lastName} />
                </div>
                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                <ComponentLibrary.PCHeader text={`${user.firstName} ${user.lastName}`} />
              </div>
            </div>
            <div>
              <ComponentLibrary.PCLabel text={"EMAIL"} />
              <p style={{ cursor: "not-allowed" }} className="text-size-3 margin-top-1 color-text-2">
                {email}
              </p>
              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
              <ComponentLibrary.PCLabel text={"TEAM ID"} />
              <p style={{ cursor: "not-allowed" }} className="text-size-3 margin-top-1 color-text-2">
                {user.teamId}
              </p>
              <ComponentLibrary.PCInput value={oldPassword} label={"old password"} type={"password"} placeholder={"••••••••"} handleChange={(e: any) => setOldPassword(e.target.value)} readOnly={false} />
              <ComponentLibrary.PCInput value={newPassword} label={"new password"} type={"password"} placeholder={"•••••••••"} handleChange={(e: any) => setNewPassword(e.target.value)} tooltipMessage={"At least 8 characters or more, include numbers, symbols, upper and lower case characters."} readOnly={false} />
              {newPassword.length > 0 ? <ComponentLibrary.PCInput value={confirmNewPassword} label={"confirm new password"} type={"password"} placeholder={"•••••••••"} handleChange={(e: any) => setConfirmNewPassword(e.target.value)} readOnly={false} /> : null}
            </div>
          </ComponentLibrary.PCContainerMain>
          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
          <ComponentLibrary.PCActionButton isDisabled={verifyForm()} text={"Save"} clickHandler={handleSave} />
          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
        </ComponentLibrary.PCSpacer>
        {/* {teamName === "Picnic" ? (
          <>
            <PCContainerMain>
              <PCSpacer type={"margin"} position={"top"} size={3} />

              <PCLabel text={"CHANGE TEAM ID"} />
              <PCSpacer type={"margin"} position={"top"} size={2} />
              <PCSelect
                label
                options
                selectedOption={teamId}
                handleChange={(e: any) => {
                  const { value } = e;
                  userDispatch({
                    type: "UPDATE_USER_TEAMID",
                    payload: value,
                  });
                }}
                handleCreate={() =>
                  // addToast("Can't create new teams", { appearance: "info" })
                  console.log("done")
                }
                placeholder={"Change to a Secondary Team"}
              />
            </PCContainerMain>
          </>
        ) : null} */}
      </div>
    </>
  );
}
