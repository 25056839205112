// HELPERS
import generate_jpg_image from "./generate_jpg_image";
// LIBS
import { s3_media_upload, s3_signed_url } from "../libs/amplify";

export const create_new_slide_state = async ({ creative, splits, activeSlide, activeSlideId, teamId }) => {
  //
  // This function creates new array of slides in correct order once splits are added, and fires SET_CREATIVE_DATA
  //
  let arrayOfExistingIds = creative.metadata.slides.filter(x => !(x.media.videoSplitFrame && x.media.id === activeSlide.media.id)).map(x => x.id);
  let sortedSplits = splits.sort((a, b) => a.start - b.start);
  let arrayOfIdsToBeAdded = sortedSplits.slice(1).map(x => x.id);
  let arrayOfIds = arrayOfExistingIds.concat(arrayOfIdsToBeAdded);
  const newSlidesState = await Promise.all(
    arrayOfIds
      .map(async (idItem, i) => {
        let slideToCheck = creative.metadata.slides[creative.metadata.slides.map(x => x.id).indexOf(idItem)];
        let newSplit = !slideToCheck && arrayOfIdsToBeAdded.indexOf(idItem) !== -1;
        let existingSplit = !newSplit && activeSlide.media.id === slideToCheck.media.id && slideToCheck.media.videoSplitFrame;
        let existingSlide = slideToCheck && activeSlide.media.id !== slideToCheck.media.id;
        //
        // Slide doesn't exist i.e. is split frame => add new slide with ID
        //
        if (newSplit) {
          let splitObject = sortedSplits[sortedSplits.map(x => x.id).indexOf(idItem)];
          let plainImage = await generate_jpg_image(activeSlide.media.bodyOriginal, "video", Number(Number.parseFloat(activeSlide.media.originalVideoDuration * splitObject.start).toFixed(2)));
          let plainImageUrl = await s3_media_upload({
            fileKey: `${splitObject.fileName}-${splitObject.videoId}-plainImage.jpeg`,
            mimeType: "image/jpeg",
            body: plainImage,
            teamId,
            creativeId: creative.creativeId,
          });
          let posterImage = await generate_jpg_image(activeSlide.media.bodyCropped, "video", Number(Number.parseFloat(activeSlide.media.originalVideoDuration * splitObject.start).toFixed(2)));
          let posterImageUrl = await s3_media_upload({
            fileKey: `${splitObject.fileName}-${splitObject.videoId}-poster.jpeg`,
            mimeType: "image/jpeg",
            body: posterImage,
            teamId,
            creativeId: creative.creativeId,
          });
          return {
            id: idItem,
            slideNumber: arrayOfIds.indexOf(activeSlideId) + sortedSplits.map(x => x.id).indexOf(idItem) + 1,
            duration: Number(Number.parseFloat(activeSlide.media.originalVideoDuration * splitObject.duration).toFixed(2)),
            content: "",
            control: {
              isToggleContainerOpen: false,
            },
            button: {
              text: "",
              font: "",
              clickout: "",
              backgroundColor: "#000000",
              textColor: "#FFFFFF",
              hidden: false,
            },
            media: {
              mimeType: activeSlide.media.mimeType,
              audio: activeSlide.media.audio,
              audioExists: activeSlide.media.audioExists,
              id: splitObject.videoId,
              fileName: splitObject.fileName,
              bodyOriginal: "",
              bodyCropped: "",
              plainImage: await s3_signed_url({ teamId, creativeId: creative.creativeId, fileName: plainImageUrl.fileName }),
              poster: await s3_signed_url({ teamId, creativeId: creative.creativeId, fileName: posterImageUrl.fileName }),
              spriteSheet: "",
              crop: { x: 0, y: 0 },
              zoom: 1,
              croppedAreaPixels: {
                width: 0,
                height: 0,
                x: 0,
                y: 0,
              },
              videoStartRef: Number(Number.parseFloat(activeSlide.media.originalVideoDuration * splitObject.start).toFixed(2)),
              originalVideoDuration: activeSlide.media.originalVideoDuration,
              videoSplitFrame: true,
            },
            elements: [],
          };
        }
        //
        // Slide is an existing split => delete
        // This will return "undefined", the .filter() method after this map will remove is from the new array of slides
        //
        if (existingSplit) {
          return;
        }
        //
        // If base frame
        // change slideNumber, duration
        //
        if (idItem === activeSlideId) {
          return {
            ...slideToCheck,
            slideNumber: i + 1,
            duration: Number(Number.parseFloat(activeSlide.media.originalVideoDuration * sortedSplits[sortedSplits.map(x => x.id).indexOf(idItem)].duration).toFixed(2)),
          };
        }
        //
        // Existing slide needs slideNumber updating
        // Check whether it's lower slide number than base frame
        // if yes, apply index + 1 as slidenumber (there're no existing splits here)
        // if no, add index + (splits.length )
        //
        if (existingSlide) {
          return { ...slideToCheck, slideNumber: creative.metadata.slides.map(slide => slide.id).indexOf(idItem) < creative.metadata.slides.map(slide => slide.id).indexOf(activeSlideId) ? i + 1 : i + splits.length };
        }
      })
      .filter(x => x),
  );

  return newSlidesState;
};
