//EXTERNAL
import { useState } from "react";
//COMPONENTS
import * as ComponentLibrary from "@picnicmedia/masanatemples123";
import AnimationDropdown from "../AnimationDropdown";
//ASSETS
import placeholderStoryBackground from "../../assets/placeholder-background-story.jpg";
import placeholderElement from "../../assets/placeholder-element.jpg";
import studio_config from "../../configs/studio.json";
//HELPERS
import { handleDragStart, handleDragOver, handleDragEnd, updateFrameData } from "../../helpers/draggable_frames";
import { create_new_slide_state } from "../../helpers/video_splitting";
import add_asset from "../../helpers/add_asset";
import add_element from "../../helpers/add_element";
import edit_asset from "../../helpers/edit_asset";
//CONTEXT
import { useCreativeState, useCreativeDispatch } from "../../contexts/creative.context";
import { useNotificationDispatch } from "../../contexts/notification.context";
import { useUserState } from "../../contexts/user.context";
import { useMediaLoadingDispatch, useMediaLoadingState } from "../../contexts/mediaLoading.context";
//TYPES
export interface StudioConfig {
  [key: string]: any;
}
//QUERIES
//MUTATIONS
//CONFIG

export default function StoryFormat() {
  const studioConfig: StudioConfig = studio_config;
  const user = useUserState();
  const creative = useCreativeState();
  const mediaLoading = useMediaLoadingState();
  const creativeDispatch = useCreativeDispatch();
  const notificationDispatch = useNotificationDispatch();
  const mediaLoadingDispatch = useMediaLoadingDispatch();
  const [activeSlideId, setActiveSlideId] = useState(creative.metadata.slides[0].id);
  const activeSlide = creative.metadata.slides[creative.metadata.slides.map((slide: any) => slide.id).indexOf(activeSlideId)];
  const [backgroundModalState, setBackgroundModalState] = useState(false);
  const [videoSplitModalState, setVideoSplitModalState] = useState(false);

  return (
    <>
      <div className="flex justify-center flex-column">
        <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
        {/* ********************** */}
        {/* FRAMES */}
        {/* ********************** */}
        {creative.metadata.slides
          .sort((a: any, b: any) => a.slideNumber - b.slideNumber)
          .slice(0, studioConfig.formats.story.maxSlides)
          .map((slide: any) => (
            <div key={slide.id}>
              <ComponentLibrary.PCContainerDraggable isDraggable={!slide.control.isToggleContainerOpen} handleDragStart={(e: any) => handleDragStart(e, creative, creativeDispatch)} handleDragOver={(e: any) => handleDragOver(e, creative, creativeDispatch)} handleDragEnd={(e: any) => handleDragEnd(e, creative, creativeDispatch)} containerId={slide.id} dataPosition={slide.slideNumber}>
                <ComponentLibrary.PCContainerToggle
                  isOpen={slide.control.isToggleContainerOpen}
                  borderOption={slide.control.isToggleContainerOpen ? "border-radius-top" : null}
                  thumbnail={slide.media.plainImage || slide.media.thumbnail || placeholderStoryBackground}
                  thumbnailHeight={"48px"}
                  thumbnailWidth={"35px"}
                  frameNumber={slide.slideNumber}
                  handleOpen={() => {
                    setActiveSlideId(slide.id);
                    updateFrameData(slide, creativeDispatch, creative);
                  }}
                  assetMimeType={slide.media.mimeType}
                  splitFrame={slide.media.videoSplitFrame}
                  handleDelete={
                    creative.metadata.slides.length > 1
                      ? () => {
                          if (slide.media.originalVideoDuration === 0) {
                            creativeDispatch({
                              type: "UPDATE_CREATIVE_SLIDEINDEX",
                              payload: { slideIndex: 0 },
                            });
                            creativeDispatch({
                              type: "UPDATE_CREATIVE_ELEMENTINDEX",
                              payload: { elementIndex: 0 },
                            });
                            creativeDispatch({
                              type: "REMOVE_SLIDE",
                              payload: {
                                id: slide.id,
                              },
                            });
                            setActiveSlideId(creative.metadata.slides.filter((x: any) => x.id !== slide.id)[0].id);
                            creativeDispatch({
                              type: "UPDATE_CREATIVE_METADATA_SLIDE_SLIDENUMBERS",
                              payload: { newSlideOrder: creative.metadata.slides.filter((frame: any) => frame.slideNumber !== slide.slideNumber).map((filteredFrame: any) => filteredFrame.id) },
                            });
                          }
                          if (slide.media.originalVideoDuration > 0) {
                            const slidesToRemove = creative.metadata.slides.filter((item: any) => {
                              return item.media.id === slide.media.id;
                            });
                            // if there will be no slides left after deleting split slides then add a new slide and update it's slide number to 1
                            if (creative.metadata.slides.length - slidesToRemove.length === 0) {
                              creativeDispatch({
                                type: "ADD_NEW_SLIDE",
                                payload: { slideNumber: 1 },
                              });
                              slidesToRemove.map((item: any) => {
                                creativeDispatch({
                                  type: "REMOVE_SLIDE",
                                  payload: {
                                    id: item.id,
                                  },
                                });
                              });
                              setActiveSlideId(null);
                            }
                            // if there will be slides remaining, remove associated split slides and update slide numbers of existing slides
                            if (creative.metadata.slides.length - slidesToRemove.length > 0) {
                              slidesToRemove.map((item: any) => {
                                creativeDispatch({
                                  type: "REMOVE_SLIDE",
                                  payload: {
                                    id: item.id,
                                  },
                                });
                              });
                              setActiveSlideId(creative.metadata.slides.filter((x: any) => !slidesToRemove.includes(x))[0].id);
                              creativeDispatch({
                                type: "UPDATE_CREATIVE_METADATA_SLIDE_SLIDENUMBERS",
                                payload: { newSlideOrder: creative.metadata.slides.filter((frame: any) => !slidesToRemove.includes(frame)).map((filteredFrame: any) => filteredFrame.id) },
                              });
                            }
                          }
                          creativeDispatch({
                            type: "UPDATE_CREATIVE_SLIDEINDEX",
                            payload: { slideIndex: 0 },
                          });
                          creativeDispatch({
                            type: "UPDATE_CREATIVE_ELEMENTINDEX",
                            payload: { elementIndex: 0 },
                          });
                        }
                      : null
                  }>
                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={1} />
                  <ComponentLibrary.PCContainerMain borderOption={"border-radius-none"}>
                    <ComponentLibrary.PCSpacer type={"margin"} position={"around"} size={4}>
                      <ComponentLibrary.PCSubHeader text={"Frame Settings"} />
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                      <div className="flex">
                        <ComponentLibrary.PCPreviewImage height={"66px"} width={"48px"} backgroundImage={slide.media.plainImage || slide.media.thumbnail || placeholderStoryBackground} />
                        <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                        <div className="flex flex-column justify-center">
                          <div className="self-start">
                            <ComponentLibrary.PCCaption text={slide.media.fileName.length > 30 ? `${slide.media.fileName.substring(0, 29)}...` : slide.media.fileName} fontWeight={"4"} />
                          </div>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                          <div className="flex">
                            <ComponentLibrary.PCActionButton text={slide.media.mimeType === "video/mp4" ? "Crop video" : "Crop image"} textColor={"platform-color-typography-titles-3"} color={"platform-background-platform-background-4"} clickHandler={() => setBackgroundModalState(!backgroundModalState)} isDisabled={slide.media.videoSplitFrame} />
                            <ComponentLibrary.PCDialogueBox open={backgroundModalState} width={"width-13"} height={"height-14"} onClose={() => setBackgroundModalState(!backgroundModalState)}>
                              <>
                                <ComponentLibrary.PCCropper
                                  media={slide.media.bodyOriginal || placeholderStoryBackground}
                                  mimeType={slide.media.mimeType}
                                  crop={slide.media.crop}
                                  zoom={slide.media.zoom}
                                  aspect={studioConfig.formats.story.media.aspectRatios[creative.metadata.aspectRatio].width / studioConfig.formats.story.media.aspectRatios[creative.metadata.aspectRatio].height}
                                  onCropChange={(crop: any) => {
                                    let activeSlideMatchingMediaIds = creative.metadata.slides.filter((slide: any) => slide.media.id === activeSlide.media.id);
                                    // update crop for all slides with the same media id
                                    activeSlideMatchingMediaIds.map((x: any) => {
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_X",
                                        payload: {
                                          id: x.slideNumber,
                                          x: crop.x,
                                        },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_Y",
                                        payload: {
                                          id: x.slideNumber,
                                          y: crop.y,
                                        },
                                      });
                                    });
                                  }}
                                  onZoomChange={(zoom: any) => {
                                    let activeSlideMatchingMediaIds = creative.metadata.slides.filter((slide: any) => slide.media.id === activeSlide.media.id);
                                    // update zoom for all slides with the same media id
                                    activeSlideMatchingMediaIds.map((x: any) => {
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ZOOM",
                                        payload: {
                                          id: x.slideNumber,
                                          zoom,
                                        },
                                      });
                                    });
                                  }}
                                  onCropComplete={async (croppedArea: any, croppedAreaPixels: any) => {
                                    let activeSlideMatchingMediaIds = creative.metadata.slides.filter((slide: any) => slide.media.id === activeSlide.media.id);
                                    // update cropped area pixels for all slides with the same media id
                                    activeSlideMatchingMediaIds.map((x: any) => {
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_WIDTH",
                                        payload: {
                                          id: x.slideNumber,
                                          width: croppedAreaPixels.width,
                                        },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_HEIGHT",
                                        payload: {
                                          id: x.slideNumber,
                                          height: croppedAreaPixels.height,
                                        },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_X",
                                        payload: {
                                          id: x.slideNumber,
                                          x: croppedAreaPixels.x,
                                        },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_Y",
                                        payload: {
                                          id: x.slideNumber,
                                          y: croppedAreaPixels.y,
                                        },
                                      });
                                    });
                                  }}
                                  buttonClickHandler={async () => {
                                    let activeSlideMatchingMediaIds = creative.metadata.slides.filter((slide: any) => slide.media.id === activeSlide.media.id);
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "crop", message: "Cropping asset", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                    try {
                                      setBackgroundModalState(false);
                                      // fire the edit_asset function for all slides with the same media id and apply crop
                                      await Promise.all(
                                        activeSlideMatchingMediaIds.map(async (x: any) => {
                                          await edit_asset({
                                            method: "crop",
                                            slideNumber: x.slideNumber,
                                            teamId: user.teamId,
                                            creativeId: creative.creativeId,
                                            fileName: x.media.fileName,
                                            fileId: x.media.id,
                                            mimeType: x.media.mimeType,
                                            inputWidth: x.media.croppedAreaPixels.width,
                                            inputHeight: x.media.croppedAreaPixels.height,
                                            outputWidth: studioConfig.formats[creative.metadata.format].media.aspectRatios[creative.metadata.aspectRatio].width,
                                            cropX: x.media.croppedAreaPixels.x,
                                            cropY: x.media.croppedAreaPixels.y,
                                            videoSplitFrame: x.media.videoSplitFrame,
                                            notificationDispatch,
                                            creativeDispatch,
                                            mediaLoadingDispatch,
                                          });
                                        }),
                                      );
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    } catch (error) {
                                      setBackgroundModalState(false);
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                      return notificationDispatch({
                                        type: "ADD",
                                        payload: {
                                          header: "Error",
                                          message: "Something went wrong.",
                                          type: "error",
                                          autoDismiss: false,
                                        },
                                      });
                                    }
                                  }}
                                />
                              </>
                            </ComponentLibrary.PCDialogueBox>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                            <ComponentLibrary.PCDivider vertical={true} />
                            <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                            <div className="upload-button-wrapper dim relative overflow-hidden dib-ns">
                              <input
                                type="file"
                                className="o-0 absolute top-0 left-0 h-100"
                                style={{
                                  cursor: "pointer",
                                }}
                                name="frame_background_upload"
                                id="frame_background_upload"
                                onChange={async (e: any) => {
                                  try {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Uploading asset...", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                    // if frame is original video frame, remove all associated split frames when replacing the asset and update slide numbers
                                    if (slide.media.originalVideoDuration > 0) {
                                      const slidesToRemove = creative.metadata.slides.filter((item: any) => {
                                        return item.media.id === slide.media.id && item.id !== slide.id;
                                      });
                                      slidesToRemove.map((item: any) => {
                                        creativeDispatch({
                                          type: "REMOVE_SLIDE",
                                          payload: {
                                            id: item.id,
                                          },
                                        });
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_SLIDENUMBERS",
                                        payload: { newSlideOrder: creative.metadata.slides.filter((frame: any) => !slidesToRemove.includes(frame)).map((filteredFrame: any) => filteredFrame.id) },
                                      });
                                    }
                                    await add_asset({
                                      file: e.target.files[0],
                                      slideNumber: slide.slideNumber,
                                      formatType: creative.metadata.format,
                                      aspectRatio: creative.metadata.aspectRatio,
                                      teamId: user.teamId,
                                      creativeId: creative.creativeId,
                                      creativeDispatch,
                                      mimeType: e.target.files[0].type,
                                      name: e.target.files[0].name,
                                      notificationDispatch,
                                      mediaLoadingDispatch,
                                    });
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                  } catch (error) {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    return notificationDispatch({
                                      type: "ADD",
                                      payload: {
                                        header: "Error",
                                        message: "Something went wrong.",
                                        type: "error",
                                        autoDismiss: false,
                                      },
                                    });
                                  }
                                }}
                                onClick={(e: any) => {
                                  e.target.value = null;
                                }}
                              />
                              <ComponentLibrary.PCActionButton text={"Replace"} textColor={"platform-color-typography-titles-3"} color={"platform-background-platform-background-4"} isDisabled={slide.media.videoSplitFrame} />
                            </div>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                            {slide.media.mimeType.includes("video") ? (
                              //
                              // VIDEO SPLIT MODAL
                              //
                              <>
                                <ComponentLibrary.PCActionButton
                                  iconLeft={"scissors"}
                                  text={"Split"}
                                  textColor={"platform-color-typography-titles-3"}
                                  color={"platform-background-platform-background-4"}
                                  clickHandler={() => {
                                    setVideoSplitModalState(true);
                                  }}
                                  isDisabled={slide.media.videoSplitFrame || !slide.media.mimeType.includes("video")}
                                />
                                <div className="relative">
                                  <ComponentLibrary.PCDialogueBox open={videoSplitModalState} width={"width-13"} height={"height-13"} onClose={() => setVideoSplitModalState(!videoSplitModalState)}>
                                    <>
                                      <ComponentLibrary.PCVideoSplitter
                                        videoDuration={slide.media.originalVideoDuration}
                                        aspectRatio={`${slide.media.width} / ${slide.media.height}`}
                                        videoURL={slide.media.bodyCropped}
                                        maxSlides={10}
                                        existingSplits={creative.metadata.slides.filter((slide: any) => slide.media.id === activeSlide.media.id)}
                                        handleSave={async (splits: any) => {
                                          setVideoSplitModalState(false);
                                          const newSlidesState = await create_new_slide_state({ creative, splits, activeSlide, activeSlideId, teamId: user.teamId });
                                          creativeDispatch({
                                            type: "SET_CREATIVE_DATA",
                                            payload: {
                                              ...creative,
                                              metadata: {
                                                ...creative.metadata,
                                                slides: newSlidesState,
                                              },
                                            },
                                          });
                                        }}
                                        videoId={slide.media.id}
                                        spriteSheetURL={slide.media.spriteSheet}
                                        baseVideoFrameId={slide.id}
                                        baseVideoFileName={slide.media.fileName}
                                      />
                                    </>
                                  </ComponentLibrary.PCDialogueBox>
                                </div>
                                <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                      <ComponentLibrary.PCDivider />
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                      <div className="w-50">
                        <ComponentLibrary.PCInput
                          value={Number(slide.duration)}
                          handleChange={(e: any) => {
                            const { value } = e.target;
                            if (slide.media.videoSplitFrame) return;
                            creativeDispatch({
                              type: "UPDATE_CREATIVE_METADATA_SLIDE_DURATION",
                              payload: {
                                id: slide.slideNumber,
                                duration: Number(value),
                              },
                            });
                          }}
                          label={"Slide duration"}
                          placeholder={"5"}
                          type={"number"}
                          subHeader={"seconds"}
                          readOnly={slide.media.mimeType.includes("video")}
                        />
                      </div>
                    </ComponentLibrary.PCSpacer>
                  </ComponentLibrary.PCContainerMain>
                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={1} />

                  {/*  */}
                  {/* ELEMENTS */}
                  {/*  */}
                  {slide.elements.map((element: any, i: any) => (
                    <div key={element.id}>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={1} />
                      <ComponentLibrary.PCContainerMain borderOption="border-radius-none">
                        <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                          <div className="flex nowrap">
                            <ComponentLibrary.PCHeader text={`Element ${i + 1}`} />
                            <div
                              onClick={() => {
                                creativeDispatch({
                                  type: "REMOVE_CREATIVE_METADATA_SLIDE_ELEMENT",
                                  payload: {
                                    id: slide.slideNumber,
                                    elementId: element.id,
                                  },
                                });
                              }}
                              className="pointer">
                              <ComponentLibrary.PCIconPicker icon={"trash"} color="platform-color-typography-links-3" />
                            </div>
                          </div>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                          <div className="flex flex-column w-100">
                            <div className="flex flex-row w-100">
                              <ComponentLibrary.PCPreviewImage height={"72px"} width={"72px"} backgroundImage={element.bodyOriginal || placeholderElement} />
                              <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                              <div className="flex flex-column justify-center">
                                <div className="self-start">
                                  <ComponentLibrary.PCCaption text={`${element.fileName || "element"}.${element.mimeType.split("/")[1] || "jpg"}`} fontWeight={"4"} />
                                </div>
                                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                                <div className="flex">
                                  <div className="upload-button-wrapper dim relative overflow-hidden dib-ns">
                                    <input
                                      type="file"
                                      className="o-0 absolute top-0 left-0 h-100"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      name="element_upload"
                                      id="element_upload"
                                      onChange={async (e: any) => {
                                        await add_element({
                                          file: e.target.files[0],
                                          slideNumber: slide.slideNumber,
                                          elementId: element.id,
                                          creativeDispatch,
                                          formatType: creative.metadata.format,
                                          mimeType: e.target.files[0].type,
                                          name: e.target.files[0].name,
                                          teamId: user.teamId,
                                          creativeId: creative.creativeId,
                                          notificationDispatch,
                                        });
                                      }}
                                      onClick={(e: any) => {
                                        e.target.value = null;
                                      }}
                                    />
                                    <ComponentLibrary.PCActionButton text={"Replace"} textColor={"platform-color-typography-titles-3"} color={"platform-background-platform-background-4"} />
                                  </div>
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                                  <ComponentLibrary.PCActionButton
                                    text={<ComponentLibrary.PCIconPicker icon={"trash"} />}
                                    textColor={"platform-color-typography-titles-3"}
                                    color={"platform-background-platform-background-4"}
                                    clickHandler={() => {
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_MIMETYPE",
                                        payload: {
                                          id: slide.slideNumber,
                                          elementId: element.id,
                                          mimeType: "",
                                        },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_FILENAME",
                                        payload: {
                                          id: slide.slideNumber,
                                          elementId: element.id,
                                          fileName: "",
                                        },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_BODYORIGINAL",
                                        payload: {
                                          id: slide.slideNumber,
                                          elementId: element.id,
                                          bodyOriginal: "",
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                            <ComponentLibrary.PCDivider />
                            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                            <div className="flex flex-row w-100">
                              <div className="w-50">
                                <ComponentLibrary.PCInput
                                  value={element.dimensions.width}
                                  handleChange={(e: any) => {
                                    const { value } = e.target;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_DIMENSIONS_WIDTH",
                                      payload: {
                                        id: slide.slideNumber,
                                        elementId: element.id,
                                        width: Number(value),
                                      },
                                    });
                                  }}
                                  label={"Width"}
                                  placeholder={"30"}
                                  type={"number"}
                                  subHeader={"px"}
                                />
                              </div>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                              <div className="w-50">
                                <ComponentLibrary.PCInput
                                  value={element.dimensions.height}
                                  handleChange={(e: any) => {
                                    const { value } = e.target;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_DIMENSIONS_HEIGHT",
                                      payload: {
                                        id: slide.slideNumber,
                                        elementId: element.id,
                                        height: Number(value),
                                      },
                                    });
                                  }}
                                  label={"Height"}
                                  placeholder={"30"}
                                  type={"number"}
                                  subHeader={"px"}
                                />
                              </div>
                            </div>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                            <div className="flex flex-row w-100">
                              <div className="w-50">
                                <ComponentLibrary.PCInput
                                  value={element.position.x}
                                  handleChange={(e: any) => {
                                    const { value } = e.target;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_POSITION_X",
                                      payload: {
                                        id: slide.slideNumber,
                                        elementId: element.id,
                                        x: Number(value),
                                      },
                                    });
                                  }}
                                  label={"X position"}
                                  placeholder={"30"}
                                  type={"number"}
                                  subHeader={"px"}
                                />
                              </div>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                              <div className="w-50">
                                <ComponentLibrary.PCInput
                                  value={element.position.y}
                                  handleChange={(e: any) => {
                                    const { value } = e.target;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_POSITION_Y",
                                      payload: {
                                        id: slide.slideNumber,
                                        elementId: element.id,
                                        y: Number(value),
                                      },
                                    });
                                  }}
                                  label={"Y position"}
                                  placeholder={"30"}
                                  type={"number"}
                                  subHeader={"px"}
                                />
                              </div>
                            </div>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                            <div className="flex flex-row w-100">
                              <div className="w-50">
                                <ComponentLibrary.PCInput
                                  value={element.clickout}
                                  handleChange={(e: any) => {
                                    const { value } = e.target;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_CLICKOUT",
                                      payload: {
                                        id: slide.slideNumber,
                                        elementId: element.id,
                                        clickout: value,
                                      },
                                    });
                                  }}
                                  label={"Clickout"}
                                  placeholder={""}
                                  type={"text"}
                                  subHeader={"url"}
                                />
                              </div>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                              <div className="w-50"></div>
                            </div>
                            {/* ANIMATIONS */}
                            {element.animations.length > 0 ? (
                              <>
                                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                                <ComponentLibrary.PCDivider />
                                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                                {element.animations.map((animation: any) => (
                                  <div className="flex flex-column w-100" key={animation.id}>
                                    <div className="w-50">
                                      <AnimationDropdown activeSlide={slide} animationId={animation.id} elementId={element.id} />
                                    </div>
                                    <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                                    <div className="w-50"></div>
                                    <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                                    <div className="flex flex-row w-100">
                                      <div className="w-50">
                                        <ComponentLibrary.PCInput
                                          value={animation.duration}
                                          handleChange={(e: any) => {
                                            const { value } = e.target;
                                            creativeDispatch({
                                              type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_DURATION",
                                              payload: {
                                                id: slide.slideNumber,
                                                elementId: element.id,
                                                animationId: animation.id,
                                                duration: value,
                                              },
                                            });
                                          }}
                                          label={"Duration"}
                                          placeholder={"0"}
                                          type={"number"}
                                          minNum={0.1}
                                          subHeader={"seconds"}
                                        />
                                      </div>
                                      <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                                      <div className="w-50">
                                        <ComponentLibrary.PCInput
                                          value={animation.delay}
                                          handleChange={(e: any) => {
                                            const { value } = e.target;
                                            creativeDispatch({
                                              type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_DELAY",
                                              payload: {
                                                id: slide.slideNumber,
                                                elementId: element.id,
                                                animationId: animation.id,
                                                delay: value,
                                              },
                                            });
                                          }}
                                          label={"Delay"}
                                          placeholder={"30"}
                                          type={"number"}
                                          subHeader={"seconds"}
                                        />
                                      </div>
                                    </div>
                                    <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                                    <div className="flex flex-column w-100 justify-center">
                                      <ComponentLibrary.PCActionButton
                                        text={"Remove animation"}
                                        iconLeft={"trash"}
                                        iconColor={"platform-color-typography-titles-3"}
                                        textColor={"platform-color-typography-titles-3"}
                                        color={"platform-background-platform-background-4"}
                                        clickHandler={() => {
                                          creativeDispatch({
                                            type: "REMOVE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATION",
                                            payload: {
                                              id: slide.slideNumber,
                                              elementId: element.id,
                                              animationId: animation.id,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                                <div className="flex flex-column w-100 justify-center">
                                  <ComponentLibrary.PCActionButton
                                    text={"Add animation"}
                                    iconLeft={"magic"}
                                    iconColor={"platform-color-primary-3"}
                                    textColor={"platform-color-primary-3"}
                                    color={"platform-background-elements-background-3"}
                                    borderColor={"platform-border-supportive-3"}
                                    clickHandler={() => {
                                      creativeDispatch({
                                        type: "ADD_NEW_ANIMATION",
                                        payload: {
                                          id: slide.slideNumber,
                                          elementId: element.id,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </ComponentLibrary.PCSpacer>
                      </ComponentLibrary.PCContainerMain>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={1} />
                    </div>
                  ))}
                  <ComponentLibrary.PCExtendButton
                    handleClick={() => {
                      creativeDispatch({
                        type: "ADD_NEW_ELEMENT",
                        payload: { id: slide.slideNumber },
                      });
                    }}
                    label={"+ Add new element"}
                  />
                </ComponentLibrary.PCContainerToggle>
              </ComponentLibrary.PCContainerDraggable>
              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={2} />
            </div>
          ))}
        <ComponentLibrary.PCNewFrame
          thumbnailHeight={"48px"}
          thumbnailWidth={"35px"}
          disabled={creative.metadata.slides.length >= studioConfig.formats.story.maxSlides}
          handleClick={() => {
            if (creative.metadata.slides.length < studioConfig.formats.story.maxSlides) {
              setActiveSlideId(creative.metadata.slides[0].id);
              creativeDispatch({
                type: "UPDATE_CREATIVE_METADATA_SLIDE_CONTROL_ISOPEN",
                payload: {
                  id: activeSlide.id,
                  isToggleContainerOpen: false,
                },
              });
              creativeDispatch({
                type: "ADD_NEW_SLIDE",
                payload: { slideNumber: creative.metadata.slides.length + 1 },
              });
              creativeDispatch({
                type: "UPDATE_CREATIVE_SLIDEINDEX",
                payload: { slideIndex: creative.metadata.slides.length },
              });
            }
          }}
        />
      </div>
    </>
  );
}
