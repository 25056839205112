//EXTERNAL
import { useState } from "react";
import { Auth, Analytics } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
//COMPONENTS
import { PCSubmitButton, PCInput, PCPicnicLogo, PCContainerAuth, PCHyperlink } from "@picnicmedia/masanatemples123";
//ASSETS
import config from "../configs/config";
import picnicLogoBlack from "../assets/picnic-logo-black.png";
//HELPERS
import { create_new_time_isostring, format_date_from_string, format_time_from_string } from "../helpers/format_time";
//LIBS
import { logger } from "../libs/logger";
//CONTEXT
import { useUserState, useUserDispatch } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";
//TYPES
interface PrimaryUsers {
  primaryUsers: [string];
}
//QUERIES
import * as queries from "../graphql/queries";

export default function SignUp() {
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const notificationDispatch = useNotificationDispatch();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const [userEnteredTeam, setUserEnteredTeam] = useState("");
  const [teamValidatedStatus, setTeamValidatedStatus] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [primaryUsers, setPrimaryUsers] = useState<PrimaryUsers[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  let inputValues = [user.firstName, user.lastName, user.email, user.teamId, password, confirmPassword];
  let requiredValues = ["first name", "last name", "email", "team ID", "password", "confirm password"];

  const handleValidateTeam = async () => {
    if (userEnteredTeam.length === 0) return;
    try {
      const { data } = await apolloClient.query({
        query: queries.VALIDATE_TEAM,
        variables: { id: userEnteredTeam },
      });
      setPrimaryUsers(data.readOneTeamId.primaryUsers);
      userDispatch({
        type: "UPDATE_USER_TEAMNAME",
        payload: { teamName: data.readOneTeamId.name },
      });
      userDispatch({
        type: "UPDATE_USER_TEAMID",
        payload: { teamId: data.readOneTeamId.id },
      });
      setTeamValidatedStatus(true);
    } catch (error) {
      setTeamValidatedStatus(false);
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with team id",
          message: "Could not find team",
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  const handleValidatePassword = () => {
    let passwordMessages: string[] = ["Password must contain:"];
    if (password.length < 8) passwordMessages.push(" at least 8 characters;");
    if (!password.match(/[a-z]+/)) passwordMessages.push(" lower case characters;");
    if (!password.match(/[A-Z]+/)) passwordMessages.push(" upper case characters;");
    if (!password.match(/[0-9]+/)) passwordMessages.push(" at least one number;");
    if (!password.match(/[$@#&!]+/)) passwordMessages.push(" at least one special character;");
    if (passwordMessages.length > 1) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with password",
          message: passwordMessages.join("\r\n"),
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  const handleValidateConfirmPassword = () => {
    if (password !== confirmPassword) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with Password",
          message: "Passwords do not match",
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  const handleSignUp = async () => {
    setIsLoading(true);

    //CHECK FOR MISSING INPUTS
    let missingValues = inputValues.map((v, i) => (v.length < 1 ? i : undefined)).filter((x) => x !== undefined);
    let newValues = missingValues.map((x: any) => requiredValues[x]);
    if (newValues.length > 0) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Please enter details for:",
          message: (
            <ul>
              {newValues.map((v: any) => (
                <li>{v}</li>
              ))}
            </ul>
          ),
          type: "error",
          autoDismiss: false,
        },
      });
    }

    //CHECK INCLUDES AN @ SIGN
    if (!user.email.includes("@")) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with sign up",
          message: "Please enter a valid email address",
          type: "error",
          autoDismiss: false,
        },
      });
    }
    //CHECK HAS AT LEAST 8 CHARACTERS
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/g)) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with sign up",
          message: "Please enter a valid password",
          type: "error",
          autoDismiss: false,
        },
      });
    }
    //CHECK IF PASSWORD === PASSWORD CONFIRMATION
    if (password !== confirmPassword) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with sign up",
          message: "Your password and confirmation password don't match",
          type: "error",
          autoDismiss: false,
        },
      });
    }

    try {
      const userData = {
        email: user.email,
        teamId: user.teamId,
        teamName: user.teamName,
        firstName: user.firstName,
        lastName: user.lastName,
        type: primaryUsers.includes(user.email) ? "primary" : "standard",
      };
      await Auth.signUp({
        username: user.email,
        password: password,
        validationData: userData,
      });

      // (HTL - 24/05/22) - aws-amplify@4.x.x types for Analytics.record have changed. We need to re-think how we do analytics and what will work for the publisher team anyway, so commenting out for now.
      //
      // Only apply analytics events to production environment
      //
      // if (config.environment === "production") {
      //   Analytics.record(
      //     {
      //       name: "signUp",
      //       streamName: config.analytics.FIREHOSESTREAMNAME,
      //       data: {
      //         eventName: "signUp",
      //         userId: `${user.id}`,
      //         userEmail: `${user.email}`,
      //         teamId: `${user.teamId}`,
      //         teamName: `${user.teamName}`,
      //         date: `${format_date_from_string(create_new_time_isostring())}`,
      //         time: `${format_time_from_string(create_new_time_isostring())}`,
      //       },
      //     },
      //     "AWSKinesisFirehose",
      //   );
      // }
      history.push("/verify");
    } catch (error) {
      logger({ error, user });
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error signing up",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="app-signup flex flex-column justify-center items-center w-100">
        <div className="signup-form flex justify-center items-center w-20-s w-40-m w-50-l">
          <PCContainerAuth>
            <div className="w-70 self-center padding-top-1 padding-bottom-1">
              <PCPicnicLogo imgUrl={picnicLogoBlack} />
            </div>
            {/* . Speak to your Picnic AM or Primary User if you do not know your team's ID */}
            <PCInput
              value={userEnteredTeam}
              label={"Team ID"}
              subHeader={"Your Team ID is a unique identifier for your agency or publisher."}
              type={"text"}
              placeholder={"0000"}
              readOnly={false}
              handleChange={(e: any) => {
                const { value } = e.target;
                setUserEnteredTeam(value);
              }}
              handleBlur={handleValidateTeam}
            />
            <div className="flex flex-row">
              <div className="flex-column padding-right-1 w-50">
                <PCInput
                  value={user.firstName}
                  label={"First Name"}
                  type={"text"}
                  placeholder={"Pablo"}
                  readOnly={false}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    userDispatch({
                      type: "UPDATE_USER_FIRSTNAME",
                      payload: { firstName: value },
                    });
                  }}
                />
              </div>
              <div className="flex-column padding-left-1 w-50">
                <PCInput
                  value={user.lastName}
                  label={"Last Name"}
                  type={"text"}
                  placeholder={"Picasso"}
                  readOnly={false}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    userDispatch({
                      type: "UPDATE_USER_LASTNAME",
                      payload: { lastName: value },
                    });
                  }}
                />
              </div>
            </div>
            <PCInput
              value={user.email}
              label={"Email"}
              type={"email"}
              placeholder={"hello@picnic-media.com"}
              readOnly={false}
              handleChange={(e: any) => {
                const { value } = e.target;
                userDispatch({ type: "UPDATE_USER_EMAIL", payload: { email: value } });
              }}
            />
            <div className="flex flex-row space-between">
              <div className="flex-column padding-right-1 w-50">
                <PCInput
                  value={password}
                  label={"Password"}
                  type={"password"}
                  handleBlur={handleValidatePassword}
                  tooltipMessage={"At least 8 characters, including numbers, symbols, upper and lower case characters."}
                  placeholder={"••••••••"}
                  readOnly={false}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    setPassword(value);
                  }}
                />
              </div>
              <div className="flex-column padding-left-1 w-50">
                <PCInput
                  value={confirmPassword}
                  label={"Confirm Password"}
                  type={"password"}
                  handleBlur={handleValidateConfirmPassword}
                  placeholder={"••••••••"}
                  readOnly={false}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    setConfirmPassword(value);
                  }}
                />
              </div>
            </div>
            <PCSubmitButton isDisabled={!teamValidatedStatus || password !== confirmPassword} clickHandler={handleSignUp} label={"Submit"} isLoading={isLoading} loadingText={"Loading.."} />
            <div className="margin-around-3 tc">
              <PCHyperlink linkDestination={"/signin"} label={"Already have an account?"} />
            </div>
          </PCContainerAuth>
        </div>
      </div>
    </>
  );
}
