import { configType } from "../types/configType";
let sandboxExport: any;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  let sandbox: configType = require("./sandbox.json");
  sandboxExport = sandbox;
}

const staging: configType = {
  environment: "staging",
  s3: {
    REGION: "eu-west-2",
    BUCKET: "studio-creatives-stag-586151543060-euwest2",
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://qbsouv0gr7.execute-api.eu-west-2.amazonaws.com/stag",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_nrNLm9enD",
    APP_CLIENT_ID: "14cqhua4og138f4u51l184eghj",
    IDENTITY_POOL_ID: "eu-west-2:d344b06e-58b0-4e37-a7d7-8f457c5c77de",
  },
  slack: {
    PINOCCHIBOT: "xoxb-33811491223-1185582194758-6IKWxzPzQfubkbTJJ9SMj7q6",
    FEEDBACKFRIDA: "xoxb-33811491223-1422369160471-LzcFetcMdXZcbl91PylF8efw",
    MUMFORD: "xoxb-33811491223-3557306592870-fgQppiTWUPkzuS4CHjCAb8wI",
    TAGS_CHANNEL: "C015URG6YFM",
    FEEDBACK_CHANNEL: "C01CGH6H4JU",
    URL: "https://5icsvj5tuj.execute-api.eu-west-1.amazonaws.com/stag",
    LOGS_CHANNEL: "C03HAG4V43Y",
  },
  logging: {
    ENABLED: false,
  },
  preview: {
    URL: "https://preview.picnic-media.com/s",
  },
  puppet: {
    URL: "https://a3cn4dcbcg.execute-api.eu-west-2.amazonaws.com/stag/run",
  },
  analytics: {
    DISABLED: true,
    REGION: "",
    FIREHOSESTREAMNAME: "",
  },
  managedServiceTeamIds: ["pi6j", "3ad5"],
};

const production: configType = {
  environment: "production",
  s3: {
    REGION: process.env.REACT_APP_PRODUCTION_REGION,
    BUCKET: process.env.REACT_APP_PRODUCTION_S3_BUCKETCREATIVES,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_PRODUCTION_REGION,
    URL: process.env.REACT_APP_PRODUCTION_APIGATEWAY_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_PRODUCTION_REGION,
    USER_POOL_ID: process.env.REACT_APP_PRODUCTION_COGNITO_USERPOOLID,
    APP_CLIENT_ID: process.env.REACT_APP_PRODUCTION_COGNITO_APPCLIENTID,
    IDENTITY_POOL_ID: process.env.REACT_APP_PRODUCTION_COGNITO_IDENTITYPOOLID,
  },
  slack: {
    PINOCCHIBOT: process.env.REACT_APP_PRODUCTION_SLACK_TOKENPINOCCHIBOT,
    FEEDBACKFRIDA: process.env.REACT_APP_PRODUCTION_SLACK_TOKENFEEDBACKFRIDA,
    MUMFORD: process.env.REACT_APP_PRODUCTION_SLACK_TOKENMUMFORD,
    TAGS_CHANNEL: process.env.REACT_APP_PRODUCTION_SLACK_CHANNELTAGS,
    FEEDBACK_CHANNEL: process.env.REACT_APP_PRODUCTION_SLACK_CHANNELFEEDBACK,
    LOGS_CHANNEL: process.env.REACT_APP_SLACK_CHANNELLOGS,
    URL: process.env.REACT_APP_PRODUCTION_SLACK_APIGATEWAYURL,
  },
  logging: {
    ENABLED: process.env.REACT_APP_PRODUCTION_LOGGING_ENABLED,
  },
  preview: {
    URL: process.env.REACT_APP_PRODUCTION_PREVIEW_URL,
  },
  puppet: {
    URL: process.env.REACT_APP_PRODUCTION_PUPPET_URL,
  },
  analytics: {
    DISABLED: false, // (HTL - 05-09-22 - Analytics should always be enabled for production environment).
    REGION: process.env.REACT_APP_PRODUCTION_REGION,
    FIREHOSESTREAMNAME: process.env.REACT_APP_PRODUCTION_ANALYTICS_FIREHOSESTREAMNAME,
  },
  managedServiceTeamIds: ["pi6j", "3ad5"],
};

let config = process.env.REACT_APP_ENVIRONMENT === "staging" ? staging : sandboxExport;

export default config;
