//EXTERNAL
import { createContext, useContext, useReducer } from "react";
import { createPortal } from "react-dom";
//COMPONENTS
import { PCNotification } from "@picnicmedia/masanatemples123";
//FUNCTIONS
import create_readable_id from "../helpers/create_readable_id";

// initialize two empty react contexts
const NotificationStateContext = createContext();
const NotificationDispatchContext = createContext();

const initialState = [];

function notificationReducer(state, action) {
  switch (action.type) {
    case "ADD": {
      return [
        ...state,
        {
          id: create_readable_id(),
          header: action.payload.header,
          message: action.payload.message,
          type: action.payload.type,
          autoDismiss: action.payload.autoDismiss,
        },
      ];
    }
    case "REMOVE": {
      return state.filter((item) => item.id !== action.payload.id);
    }
    case "REMOVE_ALL": {
      return initialState;
    }
    default:
      return state;
  }
}

function NotificationProvider({ children }) {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
        {/* `createPortal` renders the notification component in the document.body -> easier/less conflicting for styling and document flow */}
        {createPortal(
          <div className="notification-container fixed max-vh-90 width-12" style={{ overflowY: "scroll", top: "32px", right: "32px", zIndex: 1200 }}>
            {state.map((notification) => (
              <div key={notification.id}>
                <PCNotification
                  notification={notification}
                  handleClose={() =>
                    dispatch({
                      type: "REMOVE",
                      payload: { id: notification.id },
                    })
                  }
                />
              </div>
            ))}
          </div>,
          document.body,
        )}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
}

function useNotificationState() {
  const context = useContext(NotificationStateContext);
  if (context === undefined) {
    throw new Error("useNotificationState must be used within a NotificationProvider");
  }
  return context;
}

function useNotificationDispatch() {
  const context = useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error("useNotificationDispatch must be used within a NotificationProvider");
  }
  return context;
}

export { NotificationProvider, useNotificationState, useNotificationDispatch };
