// EXTERNAL
import axios from "axios";
// CONFIG
import config from "../configs/config";
// HELPERS
import get_current_session from "../helpers/get_current_session";

export async function asset_edit(params) {
  /**
   * Required fields: method
   */
  //
  // Map over the incoming params object and convert in to query params string
  //
  let queryParams = Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const response = await axios.get(`${config.apiGateway.URL}/asset-edit?${queryParams}`, {
    headers: {
      Authorization: `Bearer ${await get_current_session()}`,
    },
  });
  return response.data.payload;
}

/**
 *
 * Different function to asset_edit as this function doesn't return anything
 * @param {*} params
 * @returns
 */
export async function file_cleanup(params) {
  let queryParams = Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  axios.get(`${config.apiGateway.URL}/file-cleanup?${queryParams}`, {
    headers: {
      Authorization: `Bearer ${await get_current_session()}`,
    },
  });
}

/**
 *
 * @param {*} params
 * @returns
 */
export async function adform_creative_tags(params) {
  let queryParams = Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const response = await axios.get(`${config.apiGateway.URL}/adform-creative-tags?${queryParams}`);
  return response.data.payload;
}

export const duplicate_creative_files = async ({ existingCreativeId, teamId, duplicateCreativeId }) => {
  try {
    const response = await axios.get(`${config.apiGateway.URL}/duplicate-files?creativeId=${existingCreativeId}&teamId=${teamId}&duplicateId=${duplicateCreativeId}`, {
      headers: {
        Authorization: `Bearer ${await get_current_session()}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const zip_creative_files = async ({ creativeId, teamId }) => {
  try {
    const response = await axios.get(`${config.apiGateway.URL}/zip-creative-files?id=${creativeId}&teamId=${teamId}`, {
      headers: {
        Authorization: `Bearer ${await get_current_session()}`,
      },
    });
    return response.data.payload;
  } catch (error) {
    // console.log(error);
    throw new Error(error);
  }
};
