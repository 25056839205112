// HELPERS
import create_readable_id from "./create_readable_id";
import get_file_width_height from "./get_file_width_height";
// LIBS
import { asset_edit } from "../libs/node";
import { s3_signed_url, s3_media_upload } from "../libs/amplify";
// ASSETS
import studio_config from "../configs/studio.json";

export default async function add_brandlogo({ file, teamId, creativeId, creativeDispatch, formatType, mimeType, name, notificationDispatch, mediaLoadingDispatch }) {
  let fileName = name
    .split(".")[0]
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
    .substring(0, 30);
  let fileId = create_readable_id(4);
  try {
    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (20 - 11 + 1) + 11) } });

    /////////////////////////////////////////////
    // ERROR HANDLING
    /////////////////////////////////////////////
    //
    // If no format exists, exit the process
    //
    if (!formatType) {
      throw new Error("No format chosen");
    }
    //
    // If no file exists, exit the upload process
    //
    if (!file) {
      throw new Error("No file found");
    }
    //
    // If unsupported mimetype
    //
    if (!mimeType.includes("image")) {
      throw new Error("Unsupported file type detected. Please use PNG or JPG files.");
    }
    //
    // If unsupported file format
    //
    if (!studio_config.formats[formatType].brandLogo.acceptedMimeTypes.includes(mimeType)) {
      throw new Error("Unsupported image format detected. Please use PNG or JPG files.");
    }
    //
    // If file size = 0 ) (usually when trying to upload an un-synced file), exit the upload process
    //
    if (file.size === 0) {
      throw new Error("File has missing data. Please check file before uploading.");
    }
    /////////////////////////////////////////////
    // CREATE & UPLOAD ORIGINAL FILES
    /////////////////////////////////////////////
    let originalS3Upload = await s3_media_upload({
      fileKey: `${fileName}-${fileId}-original.${mimeType.split("/")[1]}`,
      mimeType,
      body: file,
      teamId,
      creativeId,
    });
    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (40 - 21 + 1) + 21) } });

    //
    // Send image to server for initial cropping
    // 'dimensions' required for server function scaling, to know if it's a portrait or landscape
    //
    let dimensions = await get_file_width_height(file, mimeType);
    let editedFiles = await asset_edit({
      method: "upload",
      uploadType: "logo",
      originalFileKey: "public/" + originalS3Upload.key,
      teamId,
      creativeId,
      fileName,
      fileId,
      mimeType,
      outputWidth: studio_config.formats[formatType].brandLogo.width * 5,
      outputHeight: studio_config.formats[formatType].brandLogo.height * 5,
      inputWidth: dimensions.width,
      inputHeight: dimensions.height,
    });
    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (90 - 41 + 1) + 41) } });

    let originalUrl = await s3_signed_url({ teamId, creativeId, fileName: originalS3Upload.fileName });
    let croppedUrl = await s3_signed_url({ teamId, creativeId, fileName: await editedFiles.cropped.fileName });
    //
    // RETURN IF MEDIA LOADING IS CANCELLED
    // Tertiary call after files have been created on server
    //
    // if (mediaLoading.isCancelled) {
    //   mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", isCancelled: false } });
    //   return;
    // }

    /////////////////////////////////////////////
    // UPDATE CONTEXT
    /////////////////////////////////////////////
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_WIDTH",
      payload: { width: studio_config.formats[formatType].brandLogo.width },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_HEIGHT",
      payload: { height: studio_config.formats[formatType].brandLogo.height },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_X",
      payload: { x: 0 },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_Y",
      payload: { y: 0 },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_ZOOM",
      payload: { zoom: 1 },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_MIMETYPE",
      payload: { mimeType },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_ID",
      payload: { id: fileId },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_FILENAME",
      payload: { fileName },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYORIGINAL",
      payload: { bodyOriginal: originalUrl },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYCROPPED",
      payload: { bodyCropped: croppedUrl },
    });
  } catch (error) {
    return notificationDispatch({
      type: "ADD",
      payload: {
        header: "Error uploading",
        message: error.message,
        type: "error",
        autoDismiss: true,
      },
    });
  }
}
