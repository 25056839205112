// IS_NEW_CREATIVE
// Custom hook to check whether creative has new in search params
import { useState, useEffect } from "react";

export default function is_new_creative(location) {
  const [isNewCreative, setIsNewCreative] = useState(false);
  useEffect(() => {
    if (location.search !== "?new") {
      setIsNewCreative(false);
    }
    if (location.search === "?new") {
      setIsNewCreative(true);
    }
  });

  return isNewCreative;
}
