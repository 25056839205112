import { useState, useEffect } from "react";

export default function has_ad_blocker() {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    //
    // Call the bait function
    //
    setAdBlockDetected(hasAdBlock);
  }, []);

  const hasAdBlock = () => {
    let a = document.createElement("div");
    //
    //Add space to div
    //
    a.innerHTML = "&nbsp;";
    //
    //Add lots of 'ad bait' classes
    //
    a.className = "adsbox pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad adglare ad-server adBanner ad";
    //
    //Position blank pixel far off the users screen
    //
    a.style = "width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;";
    //
    //Default hasAdBlock to false
    //
    let r = false;
    try {
      document.body.appendChild(a);
      let e = document.getElementsByClassName("adsbox")[0];
      //Check if the height of the blank pixel is 0, if it is 0, chances are ad blocker enabled
      if (e.offsetHeight === 0 || e.clientHeight === 0) r = true;
      //Check if it has height, but doesn't have any styling attached
      if (window.getComputedStyle !== undefined) {
        let tmp = window.getComputedStyle(e, null);
        if (tmp && (tmp.getPropertyValue("display") === "none" || tmp.getPropertyValue("visibility") === "hidden")) r = true;
      }
      //Remove pixel after check
      document.body.removeChild(a);
    } catch (e) {}
    return r;
  };

  return adBlockDetected;
}
