// EXTERNAL
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { gql, useApolloClient } from "@apollo/client";
// COMPONENTS
import { PCSpinner } from "@picnicmedia/masanatemples123";
// LIBS
import { logger } from "../libs/logger";
import { analytics_autotrack_pageView, analytics_autotrack_session } from "../libs/amplify";
// HELPERS
import { format_date_day_from_string, format_date_month_from_string, format_date_year_from_string, format_time_hour_from_string, format_time_minute_from_string, create_new_time_isostring } from "../helpers/format_time";
// CONTEXT
import { useUserDispatch, useUserState } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";
// CONFIGS
import config from "../configs/config";
// TYPES
type Props = { children: React.ReactNode };
// QUERY
const GET_AUTHORIZED_USER = gql`
  query ($id: ID!) {
    readOneUserId(id: $id) {
      id
      email
      teamId
      teamName
      firstName
      lastName
      type
    }
  }
`;

export default function Authorization({ children }: Props) {
  const userDispatch = useUserDispatch();
  const user = useUserState();
  const notificationDispatch = useNotificationDispatch();
  const apolloClient = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    onLoad();
  }, [user.isAuthenticated]);

  const onLoad = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const targetUserIdToken = currentSession.getIdToken();
      if (!targetUserIdToken.payload.sub) {
        setIsLoading(false);
        return;
      }
      const { data } = await apolloClient.query({
        query: GET_AUTHORIZED_USER,
        variables: { id: targetUserIdToken.payload.sub },
      });
      userDispatch({ type: "UPDATE_USER_ID", payload: { id: data.readOneUserId.id } });
      userDispatch({
        type: "UPDATE_USER_FIRSTNAME",
        payload: { firstName: data.readOneUserId.firstName },
      });
      userDispatch({
        type: "UPDATE_USER_LASTNAME",
        payload: { lastName: data.readOneUserId.lastName },
      });
      userDispatch({
        type: "UPDATE_USER_TEAMID",
        payload: { teamId: data.readOneUserId.teamId },
      });
      userDispatch({
        type: "UPDATE_USER_TEAMNAME",
        payload: { teamName: data.readOneUserId.teamName },
      });
      userDispatch({
        type: "UPDATE_USER_EMAIL",
        payload: { email: data.readOneUserId.email },
      });
      userDispatch({
        type: "UPDATE_USER_TYPE",
        payload: { type: data.readOneUserId.type },
      });
      userDispatch({ type: "UPDATE_USER_ISAUTHENTICATED", payload: { isAuthenticated: true } });
      //
      // As soon as user is authorised, start session and pageView tracking
      //
      if (config.analytics.DISABLED === false) {
        let eventTimeString = create_new_time_isostring();
        analytics_autotrack_session({
          attributes: {
            eventType: "session",
            userId: data.readOneUserId.id,
            email: data.readOneUserId.email,
            teamName: data.readOneUserId.teamName,
            teamId: data.readOneUserId.teamId,
            day: `${format_date_day_from_string(eventTimeString)}`,
            month: `${format_date_month_from_string(eventTimeString)}`,
            year: `${format_date_year_from_string(eventTimeString)}`,
            hour: `${format_time_hour_from_string(eventTimeString)}`,
            minute: `${format_time_minute_from_string(eventTimeString)}`,
          },
        });
        analytics_autotrack_pageView({
          attributes: {
            eventType: "pageView",
            userId: data.readOneUserId.id,
            email: data.readOneUserId.email,
            teamName: data.readOneUserId.teamName,
            teamId: data.readOneUserId.teamId,
            day: `${format_date_day_from_string(eventTimeString)}`,
            month: `${format_date_month_from_string(eventTimeString)}`,
            year: `${format_date_year_from_string(eventTimeString)}`,
            hour: `${format_time_hour_from_string(eventTimeString)}`,
            minute: `${format_time_minute_from_string(eventTimeString)}`,
          },
        });
      }
      setIsLoading(false);
    } catch (error) {
      logger({ error, user });
      if (error !== "No current user" && error instanceof Error) {
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Error with authorisation",
            message: "Could not find user.",
            type: "error",
            autoDismiss: false,
          },
        });
      }
      setIsLoading(false);
    }
  };
  return <>{isLoading ? <PCSpinner /> : children}</>;
}
