import config from "./config";
import { awsConfigType } from "../types/configType";

let awsconfig: awsConfigType = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  Analytics: {
    autoSessionRecord: false,
    disabled: config.analytics.DISABLED,
    AWSKinesisFirehose: {
      region: config.analytics.REGION,
      streamName: config.analytics.FIREHOSESTREAMNAME,
    },
  },
};

export default awsconfig;
