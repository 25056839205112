//EXTERNAL
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
//COMPONENTS
import { PCSubmitButton, PCTextInput, PCContainerAuth } from "@picnicmedia/masanatemples123";
//LIBS
import { logger } from "../libs/logger";
//FUNCTIONS
//CONTEXT
import { useUserState, useUserDispatch } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";

export default function Verify() {
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const notificationDispatch = useNotificationDispatch();
  const history = useHistory();
  const [emailValidation, setEmailValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailResendCode = async () => {
    setIsLoading(true);
    try {
      await Auth.resendSignUp(user.email);
      history.push("/verify");
    } catch (error) {
      logger({ error, user });
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error sending code",
          message: errorMessage,
          type: "warning",
          autoDismiss: false,
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="app-resend flex flex-column w-100">
        <div className="resend-form flex justify-center items-center">
          <PCContainerAuth>
            <PCTextInput
              label={"Email"}
              changeHandler={(e: any) => {
                const { value } = e.target;
                userDispatch({ type: "UPDATE_USER_EMAIL", payload: { email: value } });
                setEmailValidation(true);
              }}
              id={"app-resend-email"}
              type={"text"}
              name={"app-resend-email"}
              placeholder={"hello@picnic-media.com"}
              value={user.email}
            />
            <PCSubmitButton isDisabled={!emailValidation} clickHandler={handleEmailResendCode} label={"Submit"} isLoading={isLoading} loadingText={"Loading.."} isHovered={false} isFocused={false} />
          </PCContainerAuth>
          {/* <label>
					Email:
					<input
						type="text"
						value={user.email}
						onChange={(e) => {
							const { value } = e.target
							userDispatch({ type: "UPDATE_USER_EMAIL", payload: value })
						}}
					/>
				</label>
				{/* RESEND CONFIRMATION CODE BUTTON */}
          {/* <input type="submit" value="Submit" onClick={handleEmailResendCode} /> */}
        </div>
      </div>
    </>
  );
}
