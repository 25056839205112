//EXTERNAL
import React from "react";
import { Redirect } from "react-router-dom";
//COMPONENTS
//ASSETS
//FUNCTIONS
//CONTEXT
import { useUserState } from "../contexts/user.context";

export default function NotFound() {
  const user = useUserState();

  return (
    <>
      <p>404.</p>
    </>
  );
}
