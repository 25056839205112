// External
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from "@apollo/client";
import { Auth } from "aws-amplify";
// HELPERS
import get_current_session from "../helpers/get_current_session";
// Configs
import config from "./config";

const httpLink = new HttpLink({ uri: `${config.apiGateway.URL}/graphql` });

const authMiddleware = new ApolloLink(async (operation, forward) => {
  let jwtToken = null;
  // HTL 28/04/22 - adding this in to account for when there is no user data to be found by Amplify.
  // if the jwtToken is null on the backend, the resolver itself works out if it's required.
  const userInfo = await Auth.currentUserInfo();
  if (userInfo) {
    jwtToken = await get_current_session();
  }
  //
  // add the authorization to the headers
  //
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: jwtToken,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Slides: {
        keyFields: ["slideNumber"],
      },
      Media: {
        keyFields: ["fileName", "id"],
      },
    },
  }),
});

export default client;
