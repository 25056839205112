// VIDEO_GET_SPECS
// Takes an object URL that's been previously created & returns an object with a duration and hasAudio property
export default function get_video_specs({ objectURL }) {
  return new Promise((resolve, reject) => {
    let video = document.createElement("video");
    video.preload = "metadata";
    video.src = objectURL;
    video.muted = true;
    video.play();
    video.addEventListener("loadedmetadata", () => {
      setTimeout(alertFunc, 1000);
    });

    function alertFunc() {
      video.pause();
      // Different browser checks for audio in video
      // Firefox - video.mozHasAudio
      // Chrome - video.webkitAudioDecodedByteCount
      // Safari - video.audioTracks && video.audioTracks.length)
      // Edge - video.webkitAudioDecodedByteCount
      return resolve({
        duration: video.duration.toPrecision(3),
        hasAudio: video.mozHasAudio || Boolean(video.webkitAudioDecodedByteCount) || Boolean(video.audioTracks && video.audioTracks.length),
      });
    }
  });
}
