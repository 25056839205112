//EXTERNAL
import React from "react";
//COMPONENTS
//ASSETS
//FUNCTIONS
//CONTEXT

export default function UnauthenticatedLayout({ children }) {
  return (
    <>
      <div className="app-body flex min-vh-100">
        <div className="flex flex-auto">{children}</div>
      </div>
    </>
  );
}
