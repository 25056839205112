//EXTERNAL
import { useState } from "react";
//COMPONENTS
import * as ComponentLibrary from "@picnicmedia/masanatemples123";
//ASSETS
import placeholderClipsBackground from "../../assets/placeholder-background-story.jpg";
import studio_config from "../../configs/studio.json";
//HELPERS
import { handleDragStart, handleDragOver, handleDragEnd, updateFrameData } from "../../helpers/draggable_frames";
import add_asset from "../../helpers/add_asset";
import edit_asset from "../../helpers/edit_asset";
//CONTEXT
import { useCreativeState, useCreativeDispatch } from "../../contexts/creative.context";
import { useNotificationDispatch } from "../../contexts/notification.context";
import { useUserState } from "../../contexts/user.context";
import { useMediaLoadingDispatch } from "../../contexts/mediaLoading.context";
//TYPES
export interface StudioConfig {
  [key: string]: any;
}
//QUERIES
//MUTATIONS
//CONFIG

export default function ClipsFormat() {
  const studioConfig: StudioConfig = studio_config;
  const user = useUserState();
  const creative = useCreativeState();
  const creativeDispatch = useCreativeDispatch();
  const notificationDispatch = useNotificationDispatch();
  const mediaLoadingDispatch = useMediaLoadingDispatch();
  const [activeSlideId, setActiveSlideId] = useState(creative.metadata.slides[0].id);
  const activeSlide = creative.metadata.slides[creative.metadata.slides.map((slide: any) => slide.id).indexOf(activeSlideId)];
  const [backgroundModalState, setBackgroundModalState] = useState(false);

  return (
    <>
      <div className="flex justify-center flex-column">
        <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
        {/* ********************** */}
        {/* FRAMES */}
        {/* ********************** */}
        {creative.metadata.slides
          .sort((a: any, b: any) => a.slideNumber - b.slideNumber)
          .slice(0, studioConfig.formats.clips.maxSlides)
          .map((slide: any) => (
            <div key={slide.id}>
              <ComponentLibrary.PCContainerDraggable isDraggable={!slide.control.isToggleContainerOpen} handleDragStart={(e: any) => handleDragStart(e, creative, creativeDispatch)} handleDragOver={(e: any) => handleDragOver(e, creative, creativeDispatch)} handleDragEnd={(e: any) => handleDragEnd(e, creative, creativeDispatch)} containerId={slide.id} dataPosition={slide.slideNumber}>
                <ComponentLibrary.PCContainerToggle
                  isOpen={slide.control.isToggleContainerOpen}
                  borderOption={slide.control.isToggleContainerOpen ? "border-radius-top" : null}
                  thumbnail={slide.media.plainImage || slide.media.thumbnail || placeholderClipsBackground}
                  thumbnailHeight={"48px"}
                  thumbnailWidth={"35px"}
                  frameNumber={slide.slideNumber}
                  handleOpen={() => {
                    setActiveSlideId(slide.id);
                    updateFrameData(slide, creativeDispatch, creative);
                  }}
                  assetMimeType={slide.media.mimeType}
                  handleDelete={
                    creative.metadata.slides.length > 1
                      ? () => {
                          creativeDispatch({
                            type: "UPDATE_CREATIVE_SLIDEINDEX",
                            payload: { slideIndex: 0 },
                          });
                          creativeDispatch({
                            type: "UPDATE_CREATIVE_ELEMENTINDEX",
                            payload: { elementIndex: 0 },
                          });
                          creativeDispatch({
                            type: "REMOVE_SLIDE",
                            payload: {
                              id: slide.id,
                            },
                          });
                          setActiveSlideId(creative.metadata.slides.filter((x: any) => x.id !== slide.id)[0].id);
                          creativeDispatch({
                            type: "UPDATE_CREATIVE_METADATA_SLIDE_SLIDENUMBERS",
                            payload: { newSlideOrder: creative.metadata.slides.filter((frame: any) => frame.slideNumber !== slide.slideNumber).map((filteredFrame: any) => filteredFrame.id) },
                          });
                        }
                      : null
                  }>
                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={1} />
                  <ComponentLibrary.PCContainerMain borderOption={"border-radius-none"}>
                    <ComponentLibrary.PCSpacer type={"margin"} position={"around"} size={4}>
                      <ComponentLibrary.PCSubHeader text={"Frame Settings"} />
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                      <div className="flex">
                        <ComponentLibrary.PCPreviewImage height={"66px"} width={"48px"} backgroundImage={slide.media.plainImage || slide.media.thumbnail || placeholderClipsBackground} />
                        <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                        <div className="flex flex-column justify-center">
                          <div className="self-start">
                            <ComponentLibrary.PCCaption text={slide.media.fileName.length > 30 ? `${slide.media.fileName.substring(0, 29)}...` : slide.media.fileName} fontWeight={"4"} />
                          </div>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                          <div className="flex">
                            <ComponentLibrary.PCActionButton text={slide.media.mimeType === "video/mp4" ? "Crop video" : "Crop image"} textColor={"platform-color-typography-titles-3"} color={"platform-background-platform-background-4"} clickHandler={() => setBackgroundModalState(!backgroundModalState)} />
                            <ComponentLibrary.PCDialogueBox open={backgroundModalState} width={"width-13"} height={"height-14"} onClose={() => setBackgroundModalState(!backgroundModalState)}>
                              <>
                                <ComponentLibrary.PCCropper
                                  media={slide.media.bodyOriginal || placeholderClipsBackground}
                                  mimeType={slide.media.mimeType}
                                  crop={slide.media.crop}
                                  zoom={slide.media.zoom}
                                  aspect={studioConfig.formats.clips.media.aspectRatios[creative.metadata.aspectRatio].width / studioConfig.formats.clips.media.aspectRatios[creative.metadata.aspectRatio].height}
                                  onCropChange={(crop: any) => {
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_X",
                                      payload: {
                                        id: slide.slideNumber,
                                        x: crop.x,
                                      },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_Y",
                                      payload: {
                                        id: slide.slideNumber,
                                        y: crop.y,
                                      },
                                    });
                                  }}
                                  onZoomChange={(zoom: any) => {
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ZOOM",
                                      payload: {
                                        id: slide.slideNumber,
                                        zoom,
                                      },
                                    });
                                  }}
                                  onCropComplete={async (croppedArea: any, croppedAreaPixels: any) => {
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_WIDTH",
                                      payload: {
                                        id: slide.slideNumber,
                                        width: croppedAreaPixels.width,
                                      },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_HEIGHT",
                                      payload: {
                                        id: slide.slideNumber,
                                        height: croppedAreaPixels.height,
                                      },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_X",
                                      payload: {
                                        id: slide.slideNumber,
                                        x: croppedAreaPixels.x,
                                      },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_Y",
                                      payload: {
                                        id: slide.slideNumber,
                                        y: croppedAreaPixels.y,
                                      },
                                    });
                                  }}
                                  buttonClickHandler={async () => {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "crop", message: "Cropping asset", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                    try {
                                      setBackgroundModalState(false);
                                      await edit_asset({
                                        method: "crop",
                                        slideNumber: slide.slideNumber,
                                        teamId: user.teamId,
                                        creativeId: creative.creativeId,
                                        fileName: slide.media.fileName,
                                        fileId: slide.media.id,
                                        mimeType: slide.media.mimeType,
                                        inputWidth: slide.media.croppedAreaPixels.width,
                                        inputHeight: slide.media.croppedAreaPixels.height,
                                        outputWidth: studioConfig.formats[creative.metadata.format].media.aspectRatios[creative.metadata.aspectRatio].width,
                                        cropX: slide.media.croppedAreaPixels.x,
                                        cropY: slide.media.croppedAreaPixels.y,
                                        videoSplitFrame: slide.media.videoSplitFrame,
                                        notificationDispatch,
                                        creativeDispatch,
                                        mediaLoadingDispatch,
                                      });
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    } catch (error) {
                                      setBackgroundModalState(false);
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                      return notificationDispatch({
                                        type: "ADD",
                                        payload: {
                                          header: "Error",
                                          message: "Something went wrong.",
                                          type: "error",
                                          autoDismiss: false,
                                        },
                                      });
                                    }
                                  }}
                                />
                              </>
                            </ComponentLibrary.PCDialogueBox>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                            <ComponentLibrary.PCDivider vertical={true} />
                            <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                            <div className="upload-button-wrapper dim relative overflow-hidden dib-ns">
                              <input
                                type="file"
                                className="o-0 absolute top-0 left-0 h-100"
                                style={{
                                  cursor: "pointer",
                                }}
                                name="frame_background_upload"
                                id="frame_background_upload"
                                onChange={async (e: any) => {
                                  try {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Uploading asset...", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                    await add_asset({
                                      file: e.target.files[0],
                                      slideNumber: slide.slideNumber,
                                      formatType: creative.metadata.format,
                                      aspectRatio: creative.metadata.aspectRatio,
                                      teamId: user.teamId,
                                      creativeId: creative.creativeId,
                                      creativeDispatch,
                                      mimeType: e.target.files[0].type,
                                      name: e.target.files[0].name,
                                      notificationDispatch,
                                      mediaLoadingDispatch,
                                    });
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                  } catch (error) {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    return notificationDispatch({
                                      type: "ADD",
                                      payload: {
                                        header: "Error",
                                        message: "Something went wrong.",
                                        type: "error",
                                        autoDismiss: false,
                                      },
                                    });
                                  }
                                }}
                                onClick={(e: any) => {
                                  e.target.value = null;
                                }}
                              />
                              <ComponentLibrary.PCActionButton text={"Replace"} textColor={"platform-color-typography-titles-3"} color={"platform-background-platform-background-4"} />
                            </div>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                          </div>
                        </div>
                      </div>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                      <ComponentLibrary.PCDivider />
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                      <div className="w-50">
                        <ComponentLibrary.PCInput
                          value={Number(slide.duration)}
                          handleChange={(e: any) => {
                            const { value } = e.target;
                            creativeDispatch({
                              type: "UPDATE_CREATIVE_METADATA_SLIDE_DURATION",
                              payload: {
                                id: slide.slideNumber,
                                duration: Number(value),
                              },
                            });
                          }}
                          label={"Slide duration"}
                          placeholder={"5"}
                          type={"number"}
                          subHeader={"seconds"}
                          readOnly={slide.media.mimeType.includes("video")}
                        />
                      </div>
                    </ComponentLibrary.PCSpacer>
                  </ComponentLibrary.PCContainerMain>
                </ComponentLibrary.PCContainerToggle>
              </ComponentLibrary.PCContainerDraggable>
              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={2} />
            </div>
          ))}
        <ComponentLibrary.PCNewFrame
          thumbnailHeight={"48px"}
          thumbnailWidth={"35px"}
          disabled={creative.metadata.slides.length >= studioConfig.formats.clips.maxSlides}
          handleClick={() => {
            if (creative.metadata.slides.length < studioConfig.formats.clips.maxSlides) {
              setActiveSlideId(creative.metadata.slides[0].id);
              creativeDispatch({
                type: "UPDATE_CREATIVE_METADATA_SLIDE_CONTROL_ISOPEN",
                payload: {
                  id: activeSlide.id,
                  isToggleContainerOpen: false,
                },
              });
              creativeDispatch({
                type: "ADD_NEW_SLIDE",
                payload: { slideNumber: creative.metadata.slides.length + 1 },
              });
              creativeDispatch({
                type: "UPDATE_CREATIVE_SLIDEINDEX",
                payload: { slideIndex: creative.metadata.slides.length },
              });
            }
          }}
        />
      </div>
    </>
  );
}
