//EXTERNAL
import { gql } from "@apollo/client";

export const VALIDATE_TEAM = gql`
  query ($id: String!) {
    readOneTeamId(id: $id) {
      id
      name
      primaryUsers
    }
  }
`;

export const LIST_TEAM_ADVERTISERS = gql`
  query ($teamId: String!) {
    readAllCreativesTeam(teamId: $teamId) {
      advertiserName
      advertiserId
    }
  }
`;

export const GET_TEAM_ADSERVERS_ADFORM_ADVERTISERID = gql`
  query ($id: String!) {
    readOneTeamId(id: $id) {
      adservers {
        adform {
          advertiserId
        }
      }
    }
  }
`;

export const GET_EXISTING_CREATIVE = gql`
  query ($id: ID!) {
    readOneCreativeId(id: $id) {
      id
      createdAt
      updatedAt
      authorEmail
      authorId
      teamId
      adserver {
        name
        tagId
        campaignId
        tagFileNames
      }
      advertiserName
      advertiserId
      adName
      metadata {
        format
        aspectRatio
        socialUrl
        brandName
        content
        brandLogo {
          mimeType
          id
          fileName
          crop {
            x
            y
          }
          zoom
          croppedAreaPixels {
            width
            height
            x
            y
          }
        }
        button {
          text
          font
          clickout
          backgroundColor
          textColor
          hidden
          logo {
            mimeType
            id
            fileName
            crop {
              x
              y
            }
            zoom
            croppedAreaPixels {
              width
              height
              x
              y
            }
            dimensions {
              width
              height
            }
          }
        }
        icons {
          backgroundColor
          audioIconPosition
          audioOn
        }
        slides {
          id
          slideNumber
          duration
          content
          button {
            text
            font
            clickout
            backgroundColor
            textColor
            hidden
          }
          media {
            mimeType
            videoStartRef
            originalVideoDuration
            videoSplitFrame
            audio
            audioExists
            id
            fileName
            crop {
              x
              y
            }
            zoom
            croppedAreaPixels {
              width
              height
              x
              y
            }
          }
          elements {
            id
            mimeType
            fileName
            clickout
            position {
              x
              y
            }
            dimensions {
              width
              height
            }
            animations {
              id
              name
              valueTo
              valueFrom
              method
              duration
              ease
              delay
              property
            }
          }
        }
      }
    }
  }
`;

export const LIST_CREATIVES = gql`
  query ($teamId: String!, $advertiserId: String!) {
    readAllCreativesAdvertiser(teamId: $teamId, advertiserId: $advertiserId) {
      id
      advertiserName
      advertiserId
      adName
      updatedAt
      metadata {
        brandName
        format
        aspectRatio
      }
    }
  }
`;
