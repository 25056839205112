//EXTERNAL
import { gql } from "@apollo/client";

export const SAVE_NEW_CREATIVE = gql`
  mutation ($input: CreativeInput) {
    createOneCreative(input: $input) {
      id
    }
  }
`;

export const SAVE_EXISTING_CREATIVE = gql`
  mutation ($input: CreativeInput) {
    updateOneCreative(input: $input) {
      id
    }
  }
`;

export const DELETE_CREATIVE = gql`
  mutation ($id: ID!, $teamId: String!) {
    deleteOneCreative(id: $id, teamId: $teamId) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation ($id: ID!, $firstName: String!, $lastName: String!, $email: String!, $teamId: String!, $teamName: String!, $type: String!, $emailVerified: Boolean!) {
    createOneUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, teamId: $teamId, teamName: $teamName, type: $type, emailVerified: $emailVerified) {
      id
    }
  }
`;

export const UPDATE_USER_EMAIL_VERIFIED = gql`
  mutation ($id: ID!, $emailVerified: Boolean!) {
    updateOneUserEmailVerified(id: $id, emailVerified: $emailVerified) {
      id
    }
  }
`;

export const UPDATE_CREATIVE_ADSERVER = gql`
  mutation ($id: ID!, $name: String!, $campaignId: String!, $adformTagId: String!, $tagFileNames: [String!]) {
    updateOneCreativeAdserverId(id: $id, name: $name, campaignId: $campaignId, adformTagId: $adformTagId, tagFileNames: $tagFileNames) {
      id
    }
  }
`;
