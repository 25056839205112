import { useState, useEffect } from "react";

export default function is_format_preview_sticky() {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    const headerOffset = document.getElementById("right-col-inner")?.offsetTop - 50;
    if (window.pageYOffset > headerOffset) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return sticky;
}
