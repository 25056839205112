const create_dynamodb_creative_data = (creative, user, creativeId, isDuplicate) => ({
  id: creativeId,
  advertiserName: creative.advertiserName,
  advertiserId: creative.advertiserId,
  // appends 'copy' to ad name if it is a duplicate creative. KT 24.03.22.
  adName: isDuplicate ? `${creative.adName}-copy` : creative.adName,
  adserver: creative.adserver,
  authorEmail: user.email,
  authorId: user.id,
  teamId: user.teamId,
  writeAccess: creative.writeAccess,
  adserver: {
    name: isDuplicate || !creative.adserver.name ? "adform" : creative.adserver.name,
    campaignId: isDuplicate || !creative.adserver.campaignId ? "" : creative.adserver.campaignId,
    tagId: isDuplicate || !creative.adserver.tagId ? "" : creative.adserver.tagId,
    tagFileNames: isDuplicate || !creative.adserver.tagFileNames ? [] : creative.adserver.tagFileNames,
  },
  metadata: {
    format: creative.metadata.format,
    aspectRatio: creative.metadata.aspectRatio,
    socialUrl: creative.metadata.socialUrl,
    brandName: creative.metadata.brandName,
    content: creative.metadata.content,
    brandLogo: {
      mimeType: creative.metadata.brandLogo.mimeType,
      id: creative.metadata.brandLogo.id,
      fileName: creative.metadata.brandLogo.fileName,
      crop: {
        x: creative.metadata.brandLogo.crop.x,
        y: creative.metadata.brandLogo.crop.y,
      },
      zoom: creative.metadata.brandLogo.zoom,
      croppedAreaPixels: {
        width: creative.metadata.brandLogo.croppedAreaPixels.width,
        height: creative.metadata.brandLogo.croppedAreaPixels.height,
        x: creative.metadata.brandLogo.croppedAreaPixels.x,
        y: creative.metadata.brandLogo.croppedAreaPixels.y,
      },
    },
    button: {
      text: creative.metadata.button.text,
      font: creative.metadata.button.font,
      clickout: creative.metadata.button.clickout,
      backgroundColor: creative.metadata.button.backgroundColor,
      textColor: creative.metadata.button.textColor,
      hidden: creative.metadata.button.hidden,
      logo: {
        mimeType: creative.metadata.button.logo.mimeType,
        id: creative.metadata.button.logo.id,
        fileName: creative.metadata.button.logo.fileName,
        crop: {
          x: creative.metadata.button.logo.crop.x,
          y: creative.metadata.button.logo.crop.y,
        },
        zoom: creative.metadata.button.logo.zoom,
        croppedAreaPixels: {
          width: creative.metadata.button.logo.croppedAreaPixels.width,
          height: creative.metadata.button.logo.croppedAreaPixels.height,
          x: creative.metadata.button.logo.croppedAreaPixels.x,
          y: creative.metadata.button.logo.croppedAreaPixels.y,
        },
        dimensions: {
          width: creative.metadata.button.logo.dimensions.width,
          height: creative.metadata.button.logo.dimensions.height,
        },
      },
    },
    icons: {
      backgroundColor: creative.metadata.icons.backgroundColor,
      audioIconPosition: creative.metadata.icons.audioIconPosition,
      audioOn: creative.metadata.icons.audioOn,
    },
    slides: creative.metadata.slides.map((slide) => ({
      id: slide.id,
      slideNumber: slide.slideNumber,
      duration: slide.duration,
      content: slide.content,
      button: {
        text: slide.button.text,
        font: slide.button.font,
        clickout: slide.button.clickout,
        backgroundColor: slide.button.backgroundColor,
        textColor: slide.button.textColor,
        hidden: slide.button.hidden,
      },
      media: {
        mimeType: slide.media.mimeType,
        audio: slide.media.audio,
        audioExists: slide.media.audioExists,
        id: slide.media.id,
        fileName: slide.media.fileName,
        crop: { x: slide.media.crop.x, y: slide.media.crop.y },
        zoom: slide.media.zoom,
        croppedAreaPixels: {
          width: slide.media.croppedAreaPixels.width,
          height: slide.media.croppedAreaPixels.height,
          x: slide.media.croppedAreaPixels.x,
          y: slide.media.croppedAreaPixels.y,
        },
        videoStartRef: slide.media.videoStartRef,
        originalVideoDuration: slide.media.originalVideoDuration,
        videoSplitFrame: slide.media.videoSplitFrame,
      },
      elements: slide.elements.map((element) => ({
        id: element.id,
        mimeType: element.mimeType,
        fileName: element.fileName,
        clickout: element.clickout,
        position: { x: element.position.x, y: element.position.y },
        dimensions: {
          width: element.dimensions.width,
          height: element.dimensions.height,
        },
        animations: element.animations.map((animation) => ({
          id: animation.id,
          name: animation.name,
          valueTo: animation.valueTo,
          valueFrom: animation.valueFrom,
          method: animation.method,
          duration: animation.duration,
          ease: animation.ease,
          delay: animation.delay,
          property: animation.property,
        })),
      })),
    })),
  },
});

export default create_dynamodb_creative_data;
