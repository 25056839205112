// EXTERNAL
import React from "react";
import { Switch, Route } from "react-router-dom";
//CONTAINERS
import Lander from "../containers/Lander";
import Advertisers from "../containers/Advertisers";
import Advertiser from "../containers/Advertiser";
import Creative from "../containers/Creative";
import SignUp from "../containers/SignUp";
import SignIn from "../containers/SignIn";
import Settings from "../containers/Settings";
import Verify from "../containers/Verify";
import Resend from "../containers/Resend";
import ResetPassword from "../containers/ResetPassword";
import NotFound from "../containers/NotFound";
//ROUTES
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
//CONTEXT
import { MediaLoadingProvider } from "../contexts/mediaLoading.context";

export default function Routes() {
  return (
    <Switch>
      <PublicRoute exact path="/">
        <Lander />
      </PublicRoute>
      <PublicRoute exact path="/signup">
        <SignUp />
      </PublicRoute>
      <PublicRoute exact path="/verify">
        <Verify />
      </PublicRoute>
      <PublicRoute exact path="/resend">
        <Resend />
      </PublicRoute>
      <PublicRoute exact path="/reset-password">
        <ResetPassword />
      </PublicRoute>
      <PublicRoute exact path="/signin">
        <SignIn />
      </PublicRoute>
      <ProtectedRoute exact path="/advertisers">
        <Advertisers />
      </ProtectedRoute>
      <ProtectedRoute exact path="/advertiser/:advertiserId">
        {/* To send user progress updates about duplicating creatives */}
        <MediaLoadingProvider>
          <Advertiser />
        </MediaLoadingProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path="/creative/:creativeId">
        {/* To send user progress updates about files */}
        <MediaLoadingProvider>
          <Creative />
        </MediaLoadingProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path="/settings">
        <Settings />
      </ProtectedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
