//EXTERNAL
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
//COMPONENTS
import App from "./App";
//ASSETS
//FUNCTIONS
import reportWebVitals from "./reportWebVitals";
//HELPERS
import disable_react_dev_tools from "./helpers/disable_react_dev_tools";

if (process.env.NODE_ENV === "production") {
  disable_react_dev_tools();
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
