//EXTERNAL
import React, { useState, useEffect } from "react";
//COMPONENTS
import { PCSelect } from "@picnicmedia/masanatemples123";
//ASSETS
//FUNCTIONS
//CONTEXT
import { useCreativeState, useCreativeDispatch } from "../contexts/creative.context";
//TYPES
interface Option {
  label: string;
  value: string;
  valueTo: string;
  valueFrom: string;
  method: string;
}
//QUERIES
//MUTATIONS

export default function AnimationDropdown({ activeSlide, animationId, elementId }: any) {
  const creative = useCreativeState();
  const creativeDispatch = useCreativeDispatch();
  const [options, setOptions] = useState<Option[]>([]);
  const [chosenOption, setChosenOption] = useState<Option>();

  const animationList = [
    {
      label: "Fade-In",
      value: "opacity",
      valueTo: "1",
      valueFrom: "0",
      method: "fromTo",
    },
    {
      label: "Fade-Out",
      value: "opacity",
      valueTo: "0",
      valueFrom: "1",
      method: "fromTo",
    },
    {
      label: "Appear",
      value: "display",
      valueTo: "block",
      valueFrom: "none",
      method: "fromTo",
    },
    {
      label: "Disappear",
      value: "display",
      valueTo: "none",
      valueFrom: "block",
      method: "fromTo",
    },
  ];

  useEffect(() => {
    let isLoaded = false;

    if (!isLoaded) {
      onLoad();
    }
    isLoaded = true;
  }, []);

  const onLoad = () => {
    try {
      setOptions(animationList);
    } catch (error) {
      alert(error);
    }
  };

  const handleCreate = () => {
    return;
  };

  const handleChange = (selectedOption: any) => {
    setChosenOption(selectedOption);
    // RESET THE VALUE
    if (selectedOption === null) {
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_NAME",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          name: "",
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_PROPERTY",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          property: "",
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_VALUETO",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          valueTo: "",
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_VALUEFROM",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          valueFrom: "",
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_METHOD",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          method: "",
        },
      });
    }
    // SET THE VALUE TO INPUT
    if (selectedOption !== null) {
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_NAME",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          name: selectedOption.label,
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_PROPERTY",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          property: selectedOption.value,
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_VALUETO",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          valueTo: selectedOption.valueTo,
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_VALUEFROM",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          valueFrom: selectedOption.valueFrom,
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_METHOD",
        payload: {
          id: activeSlide.slideNumber,
          elementId,
          animationId,
          method: selectedOption.method,
        },
      });
    }
  };

  return (
    <>
      <PCSelect handleCreate={handleCreate} handleChange={handleChange} options={[{ options: [...options] }]} placeholder={"Select an animation"} selectedOption={chosenOption} label={"Select animation"} />
    </>
  );
}
