// EXTERNAL
import react from "react";
// COMPONENTS
import * as ComponentLibrary from "@picnicmedia/masanatemples123";

export default function FullPageLoader({ status, type, message, progress }: any) {
  // const handleClose = () => {
  //
  // Fire call to cancel upload or autofill process
  //
  //   dispatch({ type: "UPDATE_ISCANCELLED", payload: { isCancelled: true } });
  //   dispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "" } });
  // };
  //
  // Comment: 01/12/21 -> can eventually use 'type' to conditionally add the close button (needs work on how to stop asset uploads)
  //
  return (
    <>
      {status ? (
        <ComponentLibrary.PCDialogueBox open={true} width={"width-13"} height={"height-7"} closeIconHidden={true}>
          <div className="flex flex-column justify-center align-center">
            <ComponentLibrary.PCHeader text={message} />
            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
            <ComponentLibrary.PCSubHeader text={progress + "%"} />
          </div>
        </ComponentLibrary.PCDialogueBox>
      ) : null}
    </>
  );
}
