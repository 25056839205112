export const delete_creative = async (variables, deleteCreative, mediaLoadingDispatch, notificationDispatch) => {
  mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "delete", message: "Deleting creative", progress: Math.floor(Math.random() * (80 - 61 + 1) + 61) } });
  try {
    await deleteCreative({ variables: variables });
    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
    notificationDispatch({
      type: "ADD",
      payload: {
        header: "Success",
        message: "Creative successfully deleted",
        type: "success",
        autoDismiss: true,
      },
    });
  } catch (error) {
    console.log(error);
    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
    return notificationDispatch({
      type: "ADD",
      payload: {
        header: "Error",
        message: "Something went wrong.",
        type: "error",
        autoDismiss: false,
      },
    });
  }
};
