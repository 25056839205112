// HELPERS
import { asset_edit } from "../libs/node";
import create_readable_id from "./create_readable_id";
import get_file_width_height from "./get_file_width_height";
import get_video_specs from "./get_video_specs";
import generate_jpg_image from "./generate_jpg_image";
import { url_to_base64 } from "./url_to_base64";
// LIBS
import { s3_signed_url, s3_media_upload } from "../libs/amplify";
// ASSETS
import studio_config from "../configs/studio.json";

export default async function add_asset({ file, slideNumber, creativeDispatch, formatType, aspectRatio, mimeType, name, teamId, creativeId, notificationDispatch, mediaLoadingDispatch }) {
  //
  // Renaming files to remove all special characters and spaces, then join together. image-thing-1231231.jpg ->  imagething1231231.jpg
  // Also renaming files to 30 characters for legibility
  //
  mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (30 - 11 + 1) + 11) } });
  let fileName = name
    .split(".")[0]
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
    .substring(0, 30);
  let fileId = create_readable_id(4);
  //
  // Variables holding specific state for video assets
  // Setting defaults that match the values in Creative Context
  //
  let duration = 5;
  let hasAudio = false;
  try {
    /////////////////////////////////////////////
    // ERROR HANDLING
    /////////////////////////////////////////////
    //
    // If no format exists, exit the process
    //
    if (!formatType) {
      throw new Error("No format chosen");
    }
    //
    // If no file exists, exit the upload process
    //
    if (!file) {
      throw new Error("No file found");
    }
    //
    // If unsupported file format
    //
    if (!studio_config.formats[formatType].media.acceptedMimeTypes.includes(mimeType)) {
      if (mimeType.includes("image")) {
        throw new Error("Unsupported image format detected. Please use PNG or JPG files.");
      }
      if (mimeType.includes("video")) {
        throw new Error("Unsupported video format detected. Please use MP4 files.");
      }
    }
    //
    // If file size = 0 ) (usually when trying to upload an un-synced file), exit the upload process
    //
    if (file.size === 0) {
      throw new Error("File has missing data. Please check file before uploading.");
    }
    // If file size (in bytes) too large (40MB)
    if (mimeType.includes("image") && file.size > 40000000) {
      throw new Error("Image is too large. Please ensure image is less than 40MB");
    }

    if (mimeType.includes("video") && file.size > 40000000) {
      throw new Error("Video is too large. Please ensure video is less than 40MB");
    }

    //
    // Get video duration and audio status
    //
    if (mimeType.includes("video")) {
      let objectURL = URL.createObjectURL(file);
      let videosSpecs = await get_video_specs({ objectURL });
      duration = Number(videosSpecs.duration);
      hasAudio = videosSpecs.hasAudio;
      //
      // only update the originalVideoDuration property if asset is a video
      //
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ORIGINALVIDEODURATION",
        payload: {
          id: slideNumber,
          originalVideoDuration: duration,
        },
      });
    }

    /////////////////////////////////////////////
    // CREATE & UPLOAD ORIGINAL FILES
    /////////////////////////////////////////////
    let originalS3Upload = await s3_media_upload({
      fileKey: `${fileName}-${fileId}-original.${mimeType.split("/")[1]}`,
      mimeType,
      body: file,
      teamId,
      creativeId,
    });

    /////////////////////////////////////////////
    // COMPRESS ORIGINAL FILE IF VIDEO
    /////////////////////////////////////////////
    if (mimeType.includes("video")) {
      await asset_edit({
        method: "compress",
        fileId,
        mimeType,
        fileName,
        originalFileKey: "public/" + originalS3Upload.key,
        duration,
      });
    }
    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (50 - 31 + 1) + 31) } });
    //
    // Create image that is can be used in previews for image and video, and poster for video
    // plainImages will always be image/jpg's
    //
    let plainImage = await generate_jpg_image(file, mimeType);
    let plainImageS3Upload = await s3_media_upload({
      fileKey: `${fileName}-${fileId}-plainImage.jpeg`,
      mimeType: "image/jpeg",
      body: plainImage,
      teamId,
      creativeId,
    });

    //////////////////////////////////////
    // SINGLE UPLOAD PROCESS
    // -original -> -cropped
    // -plainImage -> -poster
    //////////////////////////////////////
    let dimensions = await get_file_width_height(file, mimeType);
    let editedFiles = await asset_edit({
      method: "upload",
      uploadType: "asset",
      originalFileKey: "public/" + originalS3Upload.key,
      plainImageFileKey: "public/" + plainImageS3Upload.key,
      teamId,
      creativeId,
      fileName,
      fileId,
      mimeType,
      outputWidth: studio_config.formats[formatType].media.aspectRatios[aspectRatio].width,
      outputHeight: studio_config.formats[formatType].media.aspectRatios[aspectRatio].height,
      inputWidth: dimensions.width,
      inputHeight: dimensions.height,
      // sprite
      keyframeSplitIntervals: Math.round(duration * 2.5) + 1,
    });
    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (90 - 71 + 1) + 71) } });
    //
    // Setting cropped and poster URLs
    //
    let originalUrl = await s3_signed_url({ teamId, creativeId, fileName: originalS3Upload.fileName });
    let croppedUrl = await s3_signed_url({ teamId, creativeId, fileName: await editedFiles.cropped.fileName });
    let plainImageUrl = await s3_signed_url({ teamId, creativeId, fileName: plainImageS3Upload.fileName });
    let posterUrl = await s3_signed_url({ teamId, creativeId, fileName: await editedFiles.poster.fileName });

    if (mimeType.includes("video")) {
      //
      // Creating Sprite Sheet for video
      // Needs -original asset to work
      //
      let spriteSheetUrl = await s3_signed_url({ teamId, creativeId, fileName: await editedFiles.spriteSheet.fileName });
      //
      // -spriteSheet saved here
      // this only needs to be done for video assets
      //
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_SPRITESHEET",
        payload: {
          id: slideNumber,
          spriteSheet: spriteSheetUrl,
        },
      });
    }

    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (99 - 98 + 1) + 98) } });
    /////////////////////////////////////////////
    // UPDATE CREATIVE CONTEXT
    /////////////////////////////////////////////
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_WIDTH",
      payload: {
        id: slideNumber,
        width: studio_config.formats[formatType].media.aspectRatios[aspectRatio].width,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_HEIGHT",
      id: slideNumber,
      payload: { height: studio_config.formats[formatType].media.aspectRatios[aspectRatio].height },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_X",
      payload: {
        id: slideNumber,
        x: 0,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_Y",
      payload: {
        id: slideNumber,
        y: 0,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ZOOM",
      payload: {
        id: slideNumber,
        zoom: 1,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_X",
      payload: {
        id: slideNumber,
        x: 0,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_Y",
      payload: {
        id: slideNumber,
        y: 0,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_MIMETYPE",
      payload: {
        id: slideNumber,
        mimeType,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ID",
      payload: {
        id: slideNumber,
        fileId,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_FILENAME",
      payload: {
        id: slideNumber,
        fileName,
      },
    });
    //
    // -original file saved here
    //
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_BODYORIGINAL",
      payload: {
        id: slideNumber,
        bodyOriginal: originalUrl,
      },
    });
    //
    // -cropped file saved here
    //
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_BODYCROPPED",
      payload: {
        id: slideNumber,
        bodyCropped: croppedUrl,
      },
    });
    //
    // -plainImage file saved here
    //
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_PLAINIMAGE",
      payload: {
        id: slideNumber,
        plainImage: plainImageUrl,
      },
    });
    //
    // -poster file saved here
    // the -poster variant is saved as a base_64 encoded string to allow dom_screenshot helper function to generate format-preview-original.png
    //
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_POSTER",
      payload: {
        id: slideNumber,
        poster: await url_to_base64(posterUrl),
      },
    });
    //
    // Duration and slide media audio get set regardless of whether the MIMETYPE is video or not
    //
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_DURATION",
      payload: {
        id: slideNumber,
        duration: Number(duration),
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_AUDIO",
      payload: {
        id: slideNumber,
        audio: hasAudio,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_AUDIOEXISTS",
      id: slideNumber,
      payload: {
        id: slideNumber,
        audioExists: hasAudio,
      },
    });
  } catch (error) {
    return notificationDispatch({
      type: "ADD",
      payload: {
        header: "Error uploading",
        message: error.message,
        type: "error",
        autoDismiss: false,
      },
    });
  }
}
