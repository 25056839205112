/**
 *
 * @param {*} file
 * @param {*} mimeType - string
 * @param {*} currentTime
 * @returns - {width: number, height: number}
 */
export default async function generate_jpg_image(file, mimeType, currentTime) {
  const canvas = document.createElement("canvas");
  const assetSrc = typeof file === "string" ? file : URL.createObjectURL(file);
  const videoCurrentTime = currentTime ? currentTime : 0.01;
  let dataUrl;
  if (mimeType.includes("image")) {
    const image = await create_image(assetSrc);
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0);
    dataUrl = canvas.toDataURL("image/jpeg", 0.6);
    // ctx.canvas.toBlob(blob => blob, "image/jpeg", 0.75);
  }

  if (mimeType.includes("video")) {
    const videoDataUrl = await create_video(assetSrc, videoCurrentTime);
    dataUrl = videoDataUrl;
  }
  URL.revokeObjectURL(assetSrc);

  const response = await fetch(dataUrl);
  let blob = await response.blob();
  return blob;
}

const create_image = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const create_video = (url, videoCurrentTime) => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.crossOrigin = "anonymous";
    videoPlayer.muted = true;
    videoPlayer.setAttribute("src", url);
    videoPlayer.load();
    videoPlayer.addEventListener("error", error => {
      reject("error when loading video file", error);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = videoCurrentTime;
      }, 100);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        return resolve(canvas.toDataURL("image/jpeg", 0.6));
      });
    });
  });
};
