// LIBS
import { asset_edit } from "../libs/node";
import { s3_signed_url } from "../libs/amplify";
// HELPERS
import { url_to_base64 } from "./url_to_base64";

export default async function edit_asset({ method, slideNumber, teamId, creativeId, creativeDispatch, fileId, inputWidth, inputHeight, outputWidth, cropX, cropY, fileName, mimeType, videoSplitFrame, notificationDispatch, mediaLoadingDispatch }) {
  try {
    const croppedFiles = await asset_edit({
      method,
      cropType: "asset",
      teamId,
      creativeId,
      fileName,
      fileId,
      inputTag: "original",
      outputTag: "cropped",
      mimeType,
      inputWidth,
      inputHeight,
      outputWidth,
      cropX,
      cropY,
      videoSplitFrame,
    });
    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (75 - 65 + 1) + 65) } });

    //
    // if the slide is a video split frame then leave croppedUrl as an empty string, otherwise update to s3 signed url
    //
    const croppedUrl = videoSplitFrame ? "" : await s3_signed_url({ teamId, creativeId, fileName: await croppedFiles.cropped.fileName });
    const posterUrl = await s3_signed_url({ teamId, creativeId, fileName: await croppedFiles.poster.fileName });

    mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (99 - 98 + 1) + 99) } });
    /////////////////////////////////////////////
    // UPDATE CREATIVE CONTEXT
    /////////////////////////////////////////////
    //
    // -cropped file saved here
    //
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_BODYCROPPED",
      payload: {
        id: slideNumber,
        bodyCropped: croppedUrl,
      },
    });
    //
    // -poster file saved here
    // the -poster variant is saved as a base_64 encoded string to allow dom_screenshot helper function to generate format-preview-original.png
    //
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_POSTER",
      payload: {
        id: slideNumber,
        poster: await url_to_base64(posterUrl),
      },
    });
  } catch (error) {
    return notificationDispatch({
      type: "ADD",
      payload: {
        header: "Error uploading",
        message: "There was an problem editing the file. Please try again.",
        type: "error",
        autoDismiss: true,
      },
    });
  }
}
