// LIBS
import { s3_media_upload, s3_signed_url } from "../libs/amplify";
// CONFIGS
import studio_config from "../configs/studio.json";

export default async function add_element({ file, slideNumber, elementId, teamId, creativeId, creativeDispatch, formatType, mimeType, name, notificationDispatch }) {
  let fileName = name
    .split(".")[0]
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
    .substring(0, 30);

  try {
    /////////////////////////////////////////////
    // ERROR HANDLING
    /////////////////////////////////////////////
    //
    // If no format exists, exit the process
    //
    if (!formatType) {
      throw new Error("No format chosen");
    }
    //
    // If no file exists, exit the upload process
    //
    if (!file) {
      throw new Error("No file found");
    }
    //
    // If unsupported mimetype
    //
    if (!mimeType.includes("image")) {
      throw new Error("Unsupported file type detected. Please use PNG or JPG files.");
    }
    //
    // If unsupported file format
    //
    if (!studio_config.formats[formatType].brandLogo.acceptedMimeTypes.includes(mimeType)) {
      throw new Error("Unsupported image format detected. Please use PNG or JPG files.");
    }

    let fileId = elementId;

    /////////////////////////////////////////////
    // CREATE & UPLOAD ORIGINAL FILES
    /////////////////////////////////////////////
    let originalS3Upload = await s3_media_upload({
      fileKey: `${fileName}-${fileId}-original.${mimeType.split("/")[1]}`,
      mimeType,
      body: file,
      teamId,
      creativeId,
    });

    /////////////////////////////////////////////
    // UPDATE CONTEXT
    /////////////////////////////////////////////
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_MIMETYPE",
      payload: {
        id: slideNumber,
        elementId,
        mimeType,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_FILENAME",
      payload: {
        id: slideNumber,
        elementId,
        fileName,
      },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_BODYORIGINAL",
      payload: {
        id: slideNumber,
        elementId,
        bodyOriginal: await s3_signed_url({ teamId, creativeId, fileName: originalS3Upload.fileName }),
      },
    });
  } catch (error) {
    return notificationDispatch({
      type: "ADD",
      payload: {
        header: "Error uploading",
        message: error.message,
        type: "error",
        autoDismiss: true,
      },
    });
  }
}
