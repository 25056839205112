//EXTERNAL
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
//COMPONENTS
import { PCSubmitButton, PCTextInput, PCContainerAuth, PCSpacer, PCHeader } from "@picnicmedia/masanatemples123";
//CONTEXT
import { useUserState, useUserDispatch } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";
// LIBS
import { logger } from "../libs/logger";

export default function Verify() {
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const notificationDispatch = useNotificationDispatch();
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationCodeValidation, setConfirmationCodeValidation] = useState(false);
  const history = useHistory();

  const handleConfirmationCodeSignUp = async (e: any) => {
    e.preventDefault();
    try {
      await Auth.confirmSignUp(user.email, confirmationCode);
      history.push("/signin");
    } catch (error) {
      logger({ error, user });
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error confirming sign up",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  return (
    <>
      <div className="app-verify flex flex-column w-100 justify-center items-center">
        <div className="verify-form flex width-14 justify-center items-center">
          <PCContainerAuth>
            <PCHeader text={"Email confirmation code"} />
            <PCTextInput
              label={"Email"}
              changeHandler={(e: any) => {
                const { value } = e.target;
                userDispatch({ type: "UPDATE_USER_EMAIL", payload: { email: value } });
              }}
              id={"app-verify-email"}
              type={"text"}
              name={"app-verify-email"}
              placeholder={"hello@picnic-media.com"}
              value={user.email}
            />
            <PCSpacer type={"padding"} position={"top"} size={2} />
            <PCTextInput
              label={"Confirmation Code"}
              changeHandler={(e: any) => {
                const { value } = e.target;
                setConfirmationCode(value);
                setConfirmationCodeValidation(true);
              }}
              id={"app-verify-confirmationcode"}
              type={"text"}
              name={"app-verify-confirmationcode"}
              placeholder={"000000"}
              value={confirmationCode}
            />
            <PCSpacer type={"padding"} position={"top"} size={3} />
            <PCSubmitButton isDisabled={!confirmationCodeValidation} clickHandler={handleConfirmationCodeSignUp} label={"Submit"} isLoading={false} loadingText={"Loading.."} />
          </PCContainerAuth>
        </div>
      </div>
    </>
  );
}
