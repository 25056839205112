// Tracks upload status of files being added to S3
//

//EXTERNAL
import { createContext, useContext, useReducer } from "react";
import { createPortal } from "react-dom";
//COMPONENTS
import FullPageLoader from "../components/FullPageLoader";
// initialize two empty react contexts
const MediaLoadingStateContext = createContext();
const MediaLoadingDispatchContext = createContext();

const initialState = {
  status: false,
  type: "",
  message: "",
  progress: 0,
  // isCancelled: false,
};

function mediaLoadingReducer(state, action) {
  // console.log({ state, action });
  switch (action.type) {
    case "UPDATE_LOADING_STATE": {
      return { ...state, status: action.payload.status, type: action.payload.type, message: action.payload.message, progress: action.payload.progress };
    }
    case "UPDATE_PROGRESS": {
      return { ...state, progress: action.payload.progress };
    }
    //
    // commented out 01/12/21
    // case "UPDATE_ISCANCELLED": {
    //   return { ...state, isCancelled: action.payload.isCancelled };
    // }
    default:
      return state;
  }
}

function MediaLoadingProvider({ children }) {
  const [state, dispatch] = useReducer(mediaLoadingReducer, initialState);

  return (
    <MediaLoadingStateContext.Provider value={state}>
      <MediaLoadingDispatchContext.Provider value={dispatch}>
        {createPortal(<FullPageLoader status={state.status} progress={state.progress} type={state.type} message={state.message} />, document.body)}
        {children}
      </MediaLoadingDispatchContext.Provider>
    </MediaLoadingStateContext.Provider>
  );
}

function useMediaLoadingState() {
  const context = useContext(MediaLoadingStateContext);
  if (context === undefined) {
    throw new Error("useMediaLoadingState must be used within a MediaLoadingProvider");
  }
  return context;
}

function useMediaLoadingDispatch() {
  const context = useContext(MediaLoadingDispatchContext);
  if (context === undefined) {
    throw new Error("useMediaLoadingDispatch must be used within a MediaLoadingProvider");
  }
  return context;
}

export { MediaLoadingProvider, useMediaLoadingState, useMediaLoadingDispatch };
