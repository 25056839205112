// LIBS
import { s3_signed_url } from "../libs/amplify";

//
// This file checks whether the S3 signed urls used for images & videos are valid, as these urls timeout after 15 minutes
// If the urls aren't validated/refreshed, the creative images & videos in studio will display as broken links
//

function check_url_expired(url) {
  try {
    // sometimes if element/asset has only just been uploaded it won't contain expiry date (but will expire)
    // if so, check_url_expired returns true to get a new url
    if (url.includes("Amz-Date=") && url.includes("Z&X-Amz-Expires")) {
      let dateTimeISO = url.split("Amz-Date=")[1].split("Z&X-Amz-Expires")[0];
      // formatting enables conversion to Date object
      let formattedDate = dateTimeISO.split("");
      formattedDate.splice(4, 0, "-");
      formattedDate.splice(7, 0, "-");
      formattedDate.splice(13, 0, ":");
      formattedDate.splice(16, 0, ":");
      let formattedDateObj = new Date(formattedDate.join(""));
      let expiryTime = parseInt(url.split("Amz-Expires=")[1].split("&X-Amz-Security-Token")[0]) / 60;
      let expiryDate = formattedDateObj;
      // if expires within 10 mins - get new url
      expiryDate.setMinutes(formattedDateObj.getMinutes() + (expiryTime - 10));
      return expiryDate < new Date();
    } else return true;
  } catch (error) {
    throw new Error(error);
  }
}

export default async function validate_all_s3_urls(creative, teamId, creativeDispatch) {
  // resource for each url in creative context
  const mediaResources = [
    // check for uploaded brand logo - otherwise this function will add an invalid url to creative context, and break placeholder logo
    creative.metadata.brandLogo.bodyOriginal
      ? [
          { dispatchParams: ["bodyOriginal"], dispatchType: "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYORIGINAL", fileName: creative.metadata.brandLogo.fileName, fileId: creative.metadata.brandLogo.id, url: creative.metadata.brandLogo.bodyOriginal, mimeType: creative.metadata.brandLogo.mimeType, slideNumber: undefined, tag: "original", elementId: undefined },
          { dispatchParams: ["bodyCropped"], dispatchType: "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYCROPPED", fileName: creative.metadata.brandLogo.fileName, fileId: creative.metadata.brandLogo.id, url: creative.metadata.brandLogo.bodyCropped, mimeType: creative.metadata.brandLogo.mimeType, slideNumber: undefined, tag: "cropped", elementId: undefined },
        ]
      : [],
    creative.metadata.button.logo.bodyOriginal
      ? [
          { dispatchParams: ["bodyOriginal"], dispatchType: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_BODYORIGINAL", fileName: creative.metadata.button.logo.fileName, fileId: creative.metadata.button.logo.id, url: creative.metadata.button.logo.bodyOriginal, mimeType: creative.metadata.button.logo.mimeType, slideNumber: undefined, tag: "original", elementId: undefined },
          { dispatchParams: ["bodyCropped"], dispatchType: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_BODYCROPPED", fileName: creative.metadata.button.logo.fileName, fileId: creative.metadata.button.logo.id, url: creative.metadata.button.logo.bodyCropped, mimeType: creative.metadata.button.logo.mimeType, slideNumber: undefined, tag: "cropped", elementId: undefined },
        ]
      : [],
    creative.metadata.slides.map((slide) =>
      // check to see if uploaded asset - otherwise this function will add an invalid url to creative context, and break placeholder asset
      slide.media.bodyOriginal
        ? [
            { dispatchParams: ["bodyOriginal", "id"], dispatchType: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_BODYORIGINAL", fileName: slide.media.fileName, fileId: slide.media.id, url: slide.media.bodyOriginal, mimeType: slide.media.mimeType, slideNumber: slide.slideNumber, tag: "original", elementId: undefined },
            { dispatchParams: ["bodyCropped", "id"], dispatchType: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_BODYCROPPED", fileName: slide.media.fileName, fileId: slide.media.id, url: slide.media.bodyCropped, mimeType: slide.media.mimeType, slideNumber: slide.slideNumber, tag: "cropped", elementId: undefined },
            { dispatchParams: ["plainImage", "id"], dispatchType: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_PLAINIMAGE", fileName: slide.media.fileName, fileId: slide.media.id, url: slide.media.plainImage, mimeType: "image/jpeg", slideNumber: slide.slideNumber, tag: "plainImage", elementId: undefined },
            { dispatchParams: ["spriteSheet", "id"], dispatchType: "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_SPRITESHEET", fileName: slide.media.fileName, fileId: slide.media.id, url: slide.media.spriteSheet, mimeType: "image/jpg", slideNumber: slide.slideNumber, tag: "spriteSheet", elementId: undefined },
            // check for elements to prevent mapping undefined - true = element object/s, false = empty arr
            slide.elements.length ? slide.elements.map((element) => ({ dispatchParams: ["bodyOriginal", "id", "elementId"], dispatchType: "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_BODYORIGINAL", fileName: element.fileName, fileId: element.id, url: element.bodyOriginal, mimeType: element.mimeType, slideNumber: slide.slideNumber, tag: "original", elementId: element.id })) : [],
          ]
        : [],
    ),
  ].flat(Infinity);

  // for each url, check if it is expiring within 10 minutes
  for await (const resource of mediaResources) {
    if (check_url_expired(resource.url)) {
      let options = {
        type: resource.dispatchType,
        payload: {},
      };
      // if url expired - set up options to pass to creative dispatch
      for await (const param of resource.dispatchParams) {
        // Handle slideNumber as IDs
        if (param === "id") Object.assign(options.payload, { id: resource.slideNumber });
        // Handle element IDs
        if (param === "elementId") Object.assign(options.payload, { elementId: resource.elementId });
        // Handle get new signed URL for asset
        if (param === "bodyOriginal" || param === "bodyCropped" || param === "plainImage" || param === "spriteSheet") Object.assign(options.payload, { [param]: await s3_signed_url({ teamId: teamId, creativeId: creative.creativeId, fileName: `${resource.fileName}-${resource.fileId}-${resource.tag}.${resource.mimeType.split("/")[1]}` }) });
      }
      // updates creative context
      creativeDispatch(options);
    }
  }
}
