// LIBS
import { s3_media_upload, s3_signed_url } from "../libs/amplify";
import { url_to_base64 } from "../helpers/url_to_base64";

/**
 *
 * @param {*} GECData
 * @param {*} creativeDispatch
 * @param {*} creativeId
 * @param {*} setMediaLoading
 * @returns
 */
export default async function set_creative_context_data(GECData, creativeDispatch, creativeId) {
  const existingData = GECData.readOneCreativeId;
  if (!existingData) return;

  async function generate_new_image_type(type, originalFileUrl, fileName, fileId, teamId, creativeId) {
    let newJpgImage = await url_to_base64(originalFileUrl);
    let newJpgImageUrl = await s3_media_upload({
      fileKey: `${fileName}-${fileId}-${type}.jpeg`,
      mimeType: "image/jpeg",
      body: newJpgImage,
      teamId,
      creativeId,
    });
    return newJpgImageUrl;
  }

  try {
    //
    // SETTING CONTEXT DATA
    // Text data from GECData
    //
    const payload = {
      creativeId,
      elementIndex: 0,
      slideIndex: 0,
      advertiserName: existingData.advertiserName,
      advertiserId: existingData.advertiserId,
      adName: existingData.adName,
      adserver: {
        // If no data, set to "adform" as default adserver.name. All creatives made before August 2022 will have null adserver object data (due to migrating from a string to an object adserver property). Once creative is re-saved (or adform tags requested), the adserver object will be added to the creative DynamoDB item. - KT 01.08.22
        name: existingData.adserver?.name || "adform",
        tagId: existingData.adserver?.tagId || "",
        campaignId: existingData.adserver?.campaignId || "",
        tagFileNames: existingData.adserver?.tagFileNames || [],
      },
      authorEmail: existingData.authorEmail,
      authorId: existingData.authorId,
      teamId: existingData.teamId,
      draggable: {
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
      },
      writeAccess: existingData.writeAccess,
      metadata: {
        format: existingData.metadata.format,
        // (HTL - 26/06/22) - "picnic" aspectRatio doesn't exist on carousel formats & will error with undefined properties.
        aspectRatio: existingData.metadata.aspectRatio || "picnic",
        socialUrl: existingData.metadata.socialUrl,
        brandName: existingData.metadata.brandName,
        content: existingData.metadata.content,
        brandLogo: {
          bodyOriginal: await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${existingData.metadata.brandLogo.fileName}-${existingData.metadata.brandLogo.id}-original.${existingData.metadata.brandLogo.mimeType.split("/")[1]}` }),
          bodyCropped: await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${existingData.metadata.brandLogo.fileName}-${existingData.metadata.brandLogo.id}-cropped.${existingData.metadata.brandLogo.mimeType.split("/")[1]}` }),
          mimeType: existingData.metadata.brandLogo.mimeType,
          id: existingData.metadata.brandLogo.id,
          fileName: existingData.metadata.brandLogo.fileName,
          crop: {
            x: existingData.metadata.brandLogo.crop.x,
            y: existingData.metadata.brandLogo.crop.y,
          },
          zoom: existingData.metadata.brandLogo.zoom,
          croppedAreaPixels: {
            width: existingData.metadata.brandLogo.croppedAreaPixels.width,
            height: existingData.metadata.brandLogo.croppedAreaPixels.height,
            x: existingData.metadata.brandLogo.croppedAreaPixels.x,
            y: existingData.metadata.brandLogo.croppedAreaPixels.y,
          },
        },
        // Checks here for button logo properties - as this was introduced when Clips was added to Studio, not all creatives will have this data - KT 22.08.22
        button: {
          text: existingData.metadata.button.text,
          font: existingData.metadata.button.font,
          clickout: existingData.metadata.button.clickout,
          backgroundColor: existingData.metadata.button.backgroundColor,
          textColor: existingData.metadata.button.textColor,
          hidden: existingData.metadata.button.hidden,
          logo: {
            bodyOriginal: existingData.metadata.button.logo?.fileName ? await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${existingData.metadata.button.logo.fileName}-${existingData.metadata.button.logo.id}-original.${existingData.metadata.button.logo.mimeType.split("/")[1]}` }) : "",
            bodyCropped: existingData.metadata.button.logo?.fileName ? await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${existingData.metadata.button.logo.fileName}-${existingData.metadata.button.logo.id}-cropped.${existingData.metadata.button.logo.mimeType.split("/")[1]}` }) : "",
            mimeType: existingData.metadata.button.logo?.mimeType ? existingData.metadata.button.logo.mimeType : "",
            id: existingData.metadata.button.logo?.id ? existingData.metadata.button.logo.id : "",
            fileName: existingData.metadata.button.logo?.fileName ? existingData.metadata.button.logo.fileName : "",
            dimensions: {
              width: existingData.metadata.button.logo?.dimensions?.width ? existingData.metadata.button.logo.dimensions.width : 0,
              height: existingData.metadata.button.logo?.dimensions?.height ? existingData.metadata.button.logo.dimensions.height : 0,
            },
            crop: {
              x: existingData.metadata.button.logo?.crop.x ? existingData.metadata.button.logo.crop.x : 0,
              y: existingData.metadata.button.logo?.crop.y ? existingData.metadata.button.logo.crop.y : 0,
            },
            zoom: existingData.metadata.button.logo?.zoom ? existingData.metadata.button.logo.zoom : 0,
            croppedAreaPixels: {
              width: existingData.metadata.button.logo?.croppedAreaPixels.width ? existingData.metadata.button.logo.croppedAreaPixels.width : 0,
              height: existingData.metadata.button.logo?.croppedAreaPixels.height ? existingData.metadata.button.logo.croppedAreaPixels.height : 0,
              x: existingData.metadata.button.logo?.croppedAreaPixels.x ? existingData.metadata.button.logo.croppedAreaPixels.x : 0,
              y: existingData.metadata.button.logo?.croppedAreaPixels.y ? existingData.metadata.button.logo.croppedAreaPixels.y : 0,
            },
          },
        },
        icons: {
          backgroundColor: existingData.metadata.icons.backgroundColor || "#FFFFFF",
          audioIconPosition: existingData.metadata.icons.audioIconPosition || "Left",
          audioOn: existingData.metadata.icons.audioOn,
        },
        slides: await Promise.all(
          existingData.metadata.slides.map(async (slide) => ({
            id: slide.id,
            slideNumber: slide.slideNumber,
            duration: slide.duration,
            content: slide.content,
            control: {
              isToggleContainerOpen: slide.slideNumber === 1 ? true : false,
            },
            button: {
              text: slide.button.text,
              font: slide.button.font,
              clickout: slide.button.clickout,
              backgroundColor: slide.button.backgroundColor,
              textColor: slide.button.textColor,
              hidden: slide.button.hidden,
            },
            media: {
              mimeType: slide.media.mimeType,
              audio: slide.media.audio || false,
              audioExists: slide.media.audioExists || false,
              id: slide.media.id,
              fileName: slide.media.fileName,
              crop: { x: slide.media.crop.x, y: slide.media.crop.y },
              zoom: slide.media.zoom,
              bodyOriginal: await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${slide.media.fileName}-${slide.media.id}-original.${slide.media.mimeType.split("/")[1]}` }),
              bodyCropped: await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${slide.media.fileName}-${slide.media.id}-cropped.${slide.media.mimeType.split("/")[1]}` }),
              poster: await url_to_base64((await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${slide.media.fileName}-${slide.media.id}-poster.jpeg` })) || (await generate_new_image_type("poster", croppedUrl, slide.media.fileName, slide.media.id, existingData.teamId, creativeId))),
              plainImage: (await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${slide.media.fileName}-${slide.media.id}-plainImage.jpeg` })) || (await generate_new_image_type("plainImage", originalUrl, slide.media.fileName, slide.media.id, existingData.teamId, creativeId)),
              croppedAreaPixels: {
                width: slide.media.croppedAreaPixels.width,
                height: slide.media.croppedAreaPixels.height,
                x: slide.media.croppedAreaPixels.x,
                y: slide.media.croppedAreaPixels.y,
              },
              videoStartRef: slide.media.videoStartRef || 0,
              originalVideoDuration: slide.media.mimeType.includes("video") ? slide.media.originalVideoDuration || slide.duration : 0,
              videoSplitFrame: slide.media.videoSplitFrame || false,
              spriteSheet: slide.media.mimeType.includes("video") ? await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${slide.media.fileName}-${slide.media.id}-spriteSheet.jpg` }) : "",
            },
            elements: await Promise.all(
              slide.elements.map(async (element) => ({
                id: element.id,
                mimeType: element.mimeType,
                fileName: element.fileName,
                clickout: element.clickout,
                bodyOriginal: await s3_signed_url({ teamId: existingData.teamId, creativeId, fileName: `${element?.fileName}-${element?.id}-original.${element?.mimeType.split("/")[1]}` }),
                position: { x: element.position.x, y: element.position.y },
                dimensions: {
                  width: element.dimensions.width,
                  height: element.dimensions.height,
                },
                animations: element.animations.map((animation) => ({
                  id: animation.id,
                  name: animation.name,
                  valueTo: animation.valueTo,
                  valueFrom: animation.valueFrom,
                  method: animation.method,
                  duration: animation.duration,
                  ease: animation.ease,
                  delay: animation.delay,
                  property: animation.property,
                })),
              })),
            ),
          })),
        ),
      },
    };

    creativeDispatch({
      type: "SET_CREATIVE_DATA",
      payload: await payload,
    });

    return true;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}
