//EXTERNAL
import React, { useState } from "react";
import { Auth, Analytics } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { PCSubmitButton, PCInput, PCPicnicLogo, PCContainerAuth, PCHyperlink } from "@picnicmedia/masanatemples123";
//LIBS
import { logger } from "../libs/logger";
import { analytics_record } from "../libs/amplify";
//ASSETS
import config from "../configs/config";
import picnicLogoBlack from "../assets/picnic-logo-black.png";
//FUNCTIONS
import { format_date_day_from_string, format_date_month_from_string, format_date_year_from_string, format_time_hour_from_string, format_time_minute_from_string, create_new_time_isostring } from "../helpers/format_time";
//CONTEXT
import { useUserState, useUserDispatch } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";
//TYPES
interface AWSError {
  name: string;
  message: string;
  code: string;
}

export default function SignIn() {
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const notificationDispatch = useNotificationDispatch();
  const history = useHistory();
  const [password, setPassword] = useState("");

  const handleSignIn = async (e: any) => {
    try {
      let signInResponse = await Auth.signIn({
        username: user.email,
        password: password,
      });
      if (signInResponse.challengeName) {
        if (signInResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
          history.push("/reset-password");
        }
      }
      if (signInResponse.attributes.sub) {
        userDispatch({ type: "UPDATE_USER_ISAUTHENTICATED", payload: { isAuthenticated: true } });
      }

      // (HTL - 24/05/22) - aws-amplify@4.x.x types for Analytics.record have changed. We need to re-think how we do analytics and what will work for the publisher team anyway, so commenting out for now.
      //
      // Only apply analytics events to production environment
      //
      if (config.analytics.DISABLED === false) {
        let eventTimeString = create_new_time_isostring();
        analytics_record({
          name: "signIn",
          attributes: {
            eventType: "click",
            userId: `${user.id}`,
            userEmail: `${user.email}`,
            teamId: `${user.teamId}`,
            teamName: `${user.teamName}`,
            day: `${format_date_day_from_string(eventTimeString)}`,
            month: `${format_date_month_from_string(eventTimeString)}`,
            year: `${format_date_year_from_string(eventTimeString)}`,
            hour: `${format_time_hour_from_string(eventTimeString)}`,
            minute: `${format_time_minute_from_string(eventTimeString)}`,
          },
        });
      }
      history.push("/advertisers");
    } catch (error) {
      logger({ error, user });
      if (error.name === "PasswordResetRequiredException") {
        history.push("/reset-password");
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Error signing in",
            message: "You need to set a new password.",
            type: "info",
            autoDismiss: true,
          },
        });
      } else if (error.name === "UserNotConfirmedException") {
        history.push("/verify");
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Error signing in",
            message: "You need to verify your email address.",
            type: "info",
            autoDismiss: true,
          },
        });
      } else if (error.name === "UserNotFoundException") {
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Error signing in",
            message: "User does not exist!",
            type: "error",
            autoDismiss: true,
          },
        });
      } else if (error.name === "NotAuthorizedException") {
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Error signing in",
            message: "Did you forget your password?",
            type: "error",
            autoDismiss: true,
          },
        });
      } else {
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Error signing in",
            message: "Please try signing in again.",
            type: "error",
            autoDismiss: true,
          },
        });
      }
    }
  };

  return (
    <>
      <div className="app-signin flex flex-column justify-center items-center w-100">
        <div className="signin-form flex justify-center items-center w-30 w-20-s w-40-m w-40-l">
          <PCContainerAuth>
            <div className="w-70 self-center padding-top-1 padding-bottom-1">
              <PCPicnicLogo imgUrl={picnicLogoBlack} />
            </div>
            <PCInput
              label={"Email"}
              type={"text"}
              value={user.email}
              handleChange={(e: any) => {
                const { value } = e.target;
                userDispatch({ type: "UPDATE_USER_EMAIL", payload: { email: value } });
              }}
              placeholder={"pablo.picasso@picnic-studio.com"}
              readOnly={false}
            />

            <PCInput
              label={"Password"}
              type={"password"}
              value={password}
              handleChange={(e: any) => {
                const { value } = e.target;
                setPassword(value);
              }}
              placeholder={"••••••••"}
              readOnly={false}
              linkMessage={"forgot your password?"}
              linkDestination={"/reset-password"}
              tooltipMessage={"At least 8 characters, including numbers, symbols, upper and lower case characters."}
            />

            <PCSubmitButton label={"Sign in"} clickHandler={handleSignIn} isLoading={false} loadingText={"Loading.."} isDisabled={false} />
            <div className="margin-around-3 tc">
              <PCHyperlink linkDestination={"/signup"} label={"Don't have an account?"} />
            </div>
          </PCContainerAuth>
        </div>
      </div>
    </>
  );
}
