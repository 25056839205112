//
// ALL FORM & INPUT VALIDATION EXPORTED FROM THIS FILE
//
// ASSETS
import studio_config from "../configs/studio.json";

// function which returns true if form content is valid
export function validateForm(content) {
  return content.length > 0;
}

export function validate_ad_creator_form(context) {
  const format = context.metadata.format;
  try {
    //
    // CHECK ADVERTISER
    //
    if (context.advertiserName.length < 1) return { status: false, message: "Advertiser is not selected" };
    //
    // CHECK ADNAME
    //
    if (context.adName.length < 1) return { status: false, message: "Ad name is not completed" };
    // CHECK IF INVALID CHARACTERS ARE BEING USED
    if (!/^[A-Za-z0-9-_ ]*$/.test(context.adName)) return { status: false, message: "Ad name is not allowed invalid characters" };
    //
    // CHECK BRANDNAME
    //
    if (context.metadata.brandName.length < 1) return { status: false, message: "Brand Name is not completed" };
    //
    // CHECK BRAND LOGO
    //
    if (context.metadata.brandLogo.mimeType === "") return { status: false, message: "No Brand Logo uploaded" };
    //
    // CHECK BUTTON LOGO
    //
    if (format === "clips" && context.metadata.button.logo.mimeType === "") return { status: false, message: "No Footer Logo uploaded" };
    //
    // CLICKOUT
    //
    if (!context.metadata.button.hidden && context.metadata.button.clickout.slice(0, 4) !== "http") return { status: false, message: "Clickout link must be a valid URL" };
    //
    // CHECK SLIDES
    // MIN
    if (context.metadata.slides.length < studio_config.formats[format].minSlides) return { status: false, message: "Too few frames" };
    // MAX
    if (context.metadata.slides.length > studio_config.formats[format].maxSlides) return { status: false, message: "Too many frames" };
    // CHECK CAROUSEL TEXT
    if (format === "carousel" && context.metadata.slides.some((slide) => !slide.content)) return { status: false, message: "Each slide must have frame text" };

    return { status: true, message: "" };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}

export function validate_autofill_input(url) {
  //
  // CHECK PLATFORM IS ACCEPTED
  //
  if (!studio_config.socialMedia.platforms.some((platform) => url.includes(platform))) {
    throw new Error("Platform not supported");
  }
  //
  // CHECK IF UTMS EXIST
  //
  if (url.includes("?")) url.split("?")[0];

  return url;
}

export function validate_adform_url(adformTaggingInput, isMandatory) {
  if (adformTaggingInput === "") return { value: adformTaggingInput, valid: !isMandatory, errorMessage: isMandatory ? "Input is required" : "" };
  if (adformTaggingInput.slice(0, 8) !== "https://") return { value: adformTaggingInput, valid: false, errorMessage: "Error: input must be a valid url that starts with 'https://'." };
  // The below must be the final check - as it can still return true (warning rather than an error message) - KT 11.05.22
  if (adformTaggingInput.includes(`"`) || adformTaggingInput.includes(`'`)) return { value: adformTaggingInput, valid: true, errorMessage: `Warning: url contains " or ' characters. Only continue if you are sure url is correct.` };
  return { value: adformTaggingInput, valid: true, errorMessage: `` };
}
