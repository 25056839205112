//EXTERNAL
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { PCSubmitButton, PCInput, PCContainerAuth, PCHyperlink } from "@picnicmedia/masanatemples123";
//COMPONENTS
//ASSETS
//LIBS
import { logger } from "../libs/logger";
//CONTEXT
import { useUserState, useUserDispatch } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";

export default function ResetPassword() {
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const notificationDispatch = useNotificationDispatch();
  const [codeSent, codeHasBeenSent] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  const handleSendCode = async (e: any) => {
    e.preventDefault();
    try {
      await Auth.forgotPassword(user.email);
      codeHasBeenSent(true);
    } catch (error) {
      logger({ error, user });
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error signing up",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  const handleCodeSubmit = async (e: any) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with password",
          message: "Your password and confirmation password don't match",
          type: "error",
          autoDismiss: false,
        },
      });
    }
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/g)) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error with password",
          message: "Please enter a valid password",
          type: "error",
          autoDismiss: false,
        },
      });
    }
    try {
      await Auth.forgotPasswordSubmit(user.email, code, password);
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Details changed",
          message: "Success updating password",
          type: "success",
          autoDismiss: true,
        },
      });
      codeHasBeenSent(false);
      setConfirmed(true);
    } catch (error) {
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "error updating password",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  return (
    <>
      <div className="app-forgotpassword flex flex-column justify-center items-center w-100 picnic-font">
        <div className="signin-form flex justify-center items-center w-20-s w-40-m w-40-l">
          <PCContainerAuth>
            {codeSent ? (
              <>
                <PCInput
                  label={"CONFIRMATION CODE"}
                  type={"text"}
                  value={code}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    setCode(value);
                  }}
                  placeholder={"confirmation code - please check your inbox"}
                  readOnly={false}
                  autoComplete="one-time-code"
                />

                <PCInput
                  label={"New Password"}
                  type={"password"}
                  value={password}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    setPassword(value);
                  }}
                  placeholder={"••••••••"}
                  readOnly={false}
                  tooltipMessage={"At least 8 characters, including numbers, symbols, upper and lower case characters."}
                />

                <PCInput
                  label={"Confirm Password"}
                  type={"password"}
                  value={confirmPassword}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    setConfirmPassword(value);
                  }}
                  placeholder={"••••••••"}
                  readOnly={false}
                  linkMessage={""}
                />

                <PCSubmitButton label={"Submit new password"} clickHandler={handleCodeSubmit} isLoading={false} loadingText={"Loading.."} isDisabled={false} />
                <div className="margin-around-3 tc">
                  <PCHyperlink linkDestination={"/signin"} label={"REMEMBER YOUR DETAILS?"} />
                </div>
              </>
            ) : !confirmed ? (
              <>
                <PCInput
                  label={"Email"}
                  type={"text"}
                  value={user.email}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    userDispatch({
                      type: "UPDATE_USER_EMAIL",
                      payload: { email: value },
                    });
                  }}
                  placeholder={"pablo.picasso@picnic-studio.com"}
                  readOnly={false}
                  linkMessage={""}
                />

                <PCSubmitButton label={"Submit"} clickHandler={handleSendCode} isLoading={false} loadingText={"Loading.."} isDisabled={false} />
                <div className="margin-around-3 tc">
                  <PCHyperlink linkDestination={"/signin"} label={"REMEMBER YOUR DETAILS?"} />
                </div>
              </>
            ) : (
              <>
                <p className="color-text-2 tc text-size-3">Your password has been reset.</p>
                <div className="margin-around-3 tc">
                  <PCHyperlink linkDestination={"/signin"} label={"Sign in with your new credentials"} />
                </div>
              </>
            )}
          </PCContainerAuth>
        </div>
      </div>
    </>
  );
}
