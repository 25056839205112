import { createContext, useContext, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import create_readable_id from "../helpers/create_readable_id";

const CreativeStateContext = createContext();
const CreativeDispatchContext = createContext();

const newSlide = {
  slideNumber: 1,
  duration: 5,
  content: "",
  control: {
    isToggleContainerOpen: false,
  },
  button: {
    text: "",
    font: "",
    clickout: "",
    backgroundColor: "#000000",
    textColor: "#FFFFFF",
    hidden: false,
  },
  media: {
    mimeType: "",
    audio: false,
    audioExists: false,
    id: "",
    fileName: "",
    // body: "",
    bodyOriginal: "",
    bodyCropped: "",
    plainImage: "",
    poster: "",
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
    videoStartRef: 0,
    originalVideoDuration: 0,
    videoSplitFrame: false,
  },
  elements: [],
};

const newElement = {
  id: "",
  mimeType: "",
  fileName: "",
  bodyOriginal: "",
  clickout: "",
  position: { x: 130, y: 260 },
  dimensions: { width: 80, height: 80 },
  animations: [],
};

const newAnimation = {
  id: "",
  name: "",
  valueTo: "",
  valueFrom: "",
  method: "",
  duration: 0,
  ease: "",
  delay: 0,
  property: "",
};

const initialState = {
  creativeId: "",
  elementIndex: 0,
  slideIndex: 0,
  advertiserName: "",
  advertiserId: "",
  adName: "",
  draggable: {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
  },
  control: {
    backgroundFileUploadInProgress: false,
    fileUploadInProgress: false,
    autofillInProgress: false,
  },
  writeAccess: [],
  adserver: {
    name: "adform",
    campaignId: "",
    tagId: "",
    tagFileNames: [],
  },
  metadata: {
    format: "",
    aspectRatio: "picnic",
    socialUrl: "",
    brandName: "",
    content: "",
    brandLogo: {
      mimeType: "",
      id: "",
      fileName: "",
      bodyOriginal: "",
      bodyCropped: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      },
    },
    button: {
      text: "Find Out More",
      font: "",
      clickout: "",
      backgroundColor: "#000000",
      textColor: "#FFFFFF",
      hidden: false,
      logo: {
        mimeType: "",
        id: "",
        fileName: "",
        bodyOriginal: "",
        bodyCropped: "",
        dimensions: { width: 0, height: 0 },
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedAreaPixels: {
          width: 0,
          height: 0,
          x: 0,
          y: 0,
        },
      },
    },
    icons: {
      backgroundColor: "#DCDCDC",
      audioIconPosition: "Left",
      audioOn: true,
    },
    slides: [
      {
        id: uuidv4(),
        slideNumber: 1,
        duration: 5,
        content: "",
        control: {
          isToggleContainerOpen: true,
        },
        button: {
          text: "",
          font: "",
          clickout: "",
          backgroundColor: "#000000",
          textColor: "#FFFFFF",
          hidden: false,
        },
        media: {
          mimeType: "",
          audio: false,
          audioExists: false,
          id: "",
          fileName: "",
          bodyOriginal: "",
          bodyCropped: "",
          plainImage: "",
          poster: "",
          spriteSheet: "",
          crop: { x: 0, y: 0 },
          zoom: 1,
          croppedAreaPixels: {
            width: 0,
            height: 0,
            x: 0,
            y: 0,
          },
          videoStartRef: 0,
          originalVideoDuration: 0,
          videoSplitFrame: false,
        },
        elements: [],
      },
    ],
  },
};

function creativeReducer(state, action) {
  // OUTLINES EVERY ACTION THAT CAN BE TAKEN ON THE STATE OBJECT
  // console.log({ action });
  // console.log({ state });

  switch (action.type) {
    case "SET_CREATIVE_DATA": {
      return action.payload;
    }
    case "RESET_CREATIVE_DATA": {
      return initialState;
    }
    case "RESET_CREATIVE_METADATA": {
      return {
        ...state,
        metadata: initialState.metadata,
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_SLIDENUMBERS": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: state.metadata.slides.map((slide, i) => ({
            ...slide,
            slideNumber: action.payload.newSlideOrder.indexOf(slide.id) + 1,
          })),
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_SLIDENUMBER": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.id === action.id
                ? {
                    ...slide,
                    slideNumber: action.payload.slideNumber,
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "ADD_NEW_SLIDE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: state.metadata.slides.concat({
            ...newSlide,
            slideNumber: action.payload.slideNumber,
            id: uuidv4(),
          }),
        },
      };
    }
    case "REMOVE_SLIDE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: state.metadata.slides.filter((slide) => slide.id !== action.payload.id),
        },
      };
    }
    case "UPDATE_CREATIVE_CREATIVEID": {
      return { ...state, creativeId: action.payload.creativeId };
    }
    case "UPDATE_CREATIVE_SLIDEINDEX": {
      return { ...state, slideIndex: action.payload.slideIndex };
    }
    case "UPDATE_CREATIVE_ELEMENTINDEX": {
      return { ...state, elementIndex: action.payload.elementIndex };
    }
    case "UPDATE_CREATIVE_ADVERTISERNAME": {
      return { ...state, advertiserName: action.payload.advertiserName };
    }
    case "UPDATE_CREATIVE_ADVERTISERID": {
      return { ...state, advertiserId: action.payload.advertiserId };
    }
    case "UPDATE_CREATIVE_ADNAME": {
      return { ...state, adName: action.payload.adName };
    }
    case "UPDATE_CREATIVE_DRAGGABLE_DRAGGEDFROM": {
      return {
        ...state,
        draggable: {
          ...state.draggable,
          draggedFrom: action.payload.draggedFrom,
        },
      };
    }
    case "UPDATE_CREATIVE_DRAGGABLE_DRAGGEDTO": {
      return {
        ...state,
        draggable: {
          ...state.draggable,
          draggedTo: action.payload.draggedTo,
        },
      };
    }
    case "UPDATE_CREATIVE_DRAGGABLE_ISDRAGGING": {
      return {
        ...state,
        draggable: {
          ...state.draggable,
          isDragging: action.payload.isDragging,
        },
      };
    }
    case "UPDATE_CREATIVE_CONTROL_BACKGROUNDFILEUPLOADINPROGRESS": {
      return {
        ...state,
        control: {
          ...state.control,
          backgroundFileUploadInProgress: action.payload.backgroundFileUploadInProgress,
        },
      };
    }
    case "UPDATE_CREATIVE_ADSERVER_NAME": {
      return {
        ...state,
        adserver: {
          ...state.adserver,
          name: action.payload.name,
        },
      };
    }
    case "UPDATE_CREATIVE_ADSERVER_CAMPAIGNID": {
      return {
        ...state,
        adserver: {
          ...state.adserver,
          campaignId: action.payload.campaignId,
        },
      };
    }
    case "UPDATE_CREATIVE_ADSERVER_TAGID": {
      return {
        ...state,
        adserver: {
          ...state.adserver,
          tagId: action.payload.tagId,
        },
      };
    }
    case "UPDATE_CREATIVE_ADSERVER_TAGFILENAMES": {
      return {
        ...state,
        adserver: {
          ...state.adserver,
          tagFileNames: action.payload.tagFileNames,
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_FORMAT": {
      return {
        ...state,
        metadata: { ...state.metadata, format: action.payload.format },
      };
    }
    case "UPDATE_CREATIVE_METADATA_ASPECTRATIO": {
      return {
        ...state,
        metadata: { ...state.metadata, aspectRatio: action.payload.aspectRatio },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SOCIALURL": {
      return {
        ...state,
        metadata: { ...state.metadata, socialUrl: action.payload.socialUrl },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDNAME": {
      return {
        ...state,
        metadata: { ...state.metadata, brandName: action.payload.brandName },
      };
    }
    case "UPDATE_CREATIVE_METADATA_CONTENT": {
      return {
        ...state,
        metadata: { ...state.metadata, content: encodeURI(action.payload.content) },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_MIMETYPE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: { ...state.metadata.brandLogo, mimeType: action.payload.mimeType },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_ID": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: { ...state.metadata.brandLogo, id: action.payload.id },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_FILENAME": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: { ...state.metadata.brandLogo, fileName: action.payload.fileName },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYORIGINAL": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            bodyOriginal: action.payload.bodyOriginal,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYCROPPED": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            bodyCropped: action.payload.bodyCropped,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROP_X": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            crop: {
              ...state.metadata.brandLogo.crop,
              x: Number(Number.parseFloat(action.payload.x)),
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROP_Y": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            crop: {
              ...state.metadata.brandLogo.crop,
              y: Number(Number.parseFloat(action.payload.y)),
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_ZOOM": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            zoom: Number(Number.parseFloat(action.payload.zoom).toFixed(2)),
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_WIDTH": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            croppedAreaPixels: {
              ...state.metadata.brandLogo.croppedAreaPixels,
              width: Math.round(action.payload.width),
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_HEIGHT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            croppedAreaPixels: {
              ...state.metadata.brandLogo.croppedAreaPixels,
              height: Math.round(action.payload.height),
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_X": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            croppedAreaPixels: {
              ...state.metadata.brandLogo.croppedAreaPixels,
              x: Number(Number.parseFloat(action.payload.x)),
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_Y": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          brandLogo: {
            ...state.metadata.brandLogo,
            croppedAreaPixels: {
              ...state.metadata.brandLogo.croppedAreaPixels,
              y: Number(Number.parseFloat(action.payload.y)),
            },
          },
        },
      };
    }

    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_MIMETYPE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: { ...state.metadata.button.logo, mimeType: action.payload.mimeType },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_ID": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: { ...state.metadata.button.logo, id: action.payload.id },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_FILENAME": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: { ...state.metadata.button.logo, fileName: action.payload.fileName },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_BODYORIGINAL": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              bodyOriginal: action.payload.bodyOriginal,
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_BODYCROPPED": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              bodyCropped: action.payload.bodyCropped,
            },
          },
        },
      };
    }

    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_DIMENSIONS_HEIGHT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              dimensions: {
                ...state.metadata.button.logo.dimensions,
                height: action.payload.height,
              },
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_DIMENSIONS_WIDTH": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              dimensions: {
                ...state.metadata.button.logo.dimensions,
                width: action.payload.width,
              },
            },
          },
        },
      };
    }

    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROP_X": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              crop: {
                ...state.metadata.button.logo.crop,
                x: Number(Number.parseFloat(action.payload.x)),
              },
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROP_Y": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              crop: {
                ...state.metadata.button.logo.crop,
                y: Number(Number.parseFloat(action.payload.y)),
              },
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_ZOOM": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              zoom: Number(Number.parseFloat(action.payload.zoom).toFixed(2)),
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_WIDTH": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              croppedAreaPixels: {
                ...state.metadata.button.logo.croppedAreaPixels,
                width: Math.round(action.payload.width),
              },
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_HEIGHT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              croppedAreaPixels: {
                ...state.metadata.button.logo.croppedAreaPixels,
                height: Math.round(action.payload.height),
              },
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_X": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              croppedAreaPixels: {
                ...state.metadata.button.logo.croppedAreaPixels,
                x: Number(Number.parseFloat(action.payload.x)),
              },
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_Y": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            logo: {
              ...state.metadata.button.logo,
              croppedAreaPixels: {
                ...state.metadata.button.logo.croppedAreaPixels,
                y: Number(Number.parseFloat(action.payload.y)),
              },
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_TEXT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            text: action.payload.text,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_FONT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            font: action.payload.font,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_CLICKOUT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            clickout: action.payload.clickout,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_BACKGROUNDCOLOR": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            backgroundColor: action.payload.backgroundColor,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_TEXTCOLOR": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            textColor: action.payload.textColor,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_BUTTON_HIDDEN": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          button: {
            ...state.metadata.button,
            hidden: action.payload.hidden,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_ICONS_BACKGROUNDCOLOR": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          icons: {
            ...state.metadata.icons,
            backgroundColor: action.payload.backgroundColor,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_ICONS_AUDIOICONPOSITION": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          icons: {
            ...state.metadata.icons,
            audioIconPosition: action.payload.audioIconPosition,
          },
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_ICONS_AUDIOON": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          icons: {
            ...state.metadata.icons,
            audioOn: action.payload.audioOn,
          },
        },
      };
    }
    //FOR CASES WITHIN ARRAYS, ACTION OBJECT WILL HAVE TO INCLUDE A BODY AND AN ID, SO THE MAPPING CAN BE DONE CORRECTLY
    case "UPDATE_CREATIVE_METADATA_SLIDE_DURATION": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    duration: Number(Number.parseFloat(action.payload.duration).toFixed(2)),
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_CONTENT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [...state.metadata.slides.map((slide) => (slide.slideNumber === action.payload.id ? { ...slide, content: encodeURI(action.payload.content) } : slide))],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_CONTROL_ISOPEN": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.id === action.payload.id
                ? {
                    ...slide,
                    control: {
                      ...slide.control,
                      isToggleContainerOpen: action.payload.isToggleContainerOpen,
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_BUTTON_BACKGROUNDCOLOR": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    button: {
                      ...slide.button,
                      backgroundColor: action.payload.backgroundColor,
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_BUTTON_TEXTCOLOR": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    button: { ...slide.button, textColor: action.payload.textColor },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_BUTTON_HIDDEN": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    button: { ...slide.button, hidden: action.payload.hidden },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_BUTTON_FONT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    button: { ...slide.button, font: action.payload.font },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_BUTTON_TEXT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    button: { ...slide.button, text: action.payload.text },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_BUTTON_CLICKOUT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    button: { ...slide.button, clickout: action.payload.clickout },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_MIMETYPE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, mimeType: action.payload.mimeType },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_AUDIO": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, audio: action.payload.audio },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_AUDIOEXISTS": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, audioExists: action.payload.audioExists },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ID": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [...state.metadata.slides.map((slide) => (slide.slideNumber === action.payload.id ? { ...slide, media: { ...slide.media, id: action.payload.fileId } } : slide))],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_FILENAME": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, fileName: action.payload.fileName },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_BODYORIGINAL": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, bodyOriginal: action.payload.bodyOriginal },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_BODYCROPPED": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, bodyCropped: action.payload.bodyCropped },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_PLAINIMAGE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, plainImage: action.payload.plainImage },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_POSTER": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, poster: action.payload.poster },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_SPRITESHEET": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: { ...slide.media, spriteSheet: action.payload.spriteSheet },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_X": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      crop: {
                        ...slide.media.crop,
                        x: Number(Number.parseFloat(action.payload.x)),
                      },
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROP_Y": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      crop: {
                        ...slide.media.crop,
                        y: Number(Number.parseFloat(action.payload.y)),
                      },
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ZOOM": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      zoom: Number(Number.parseFloat(action.payload.zoom).toFixed(2)),
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_WIDTH": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      croppedAreaPixels: {
                        ...slide.media.croppedAreaPixels,
                        width: Math.round(action.payload.width),
                      },
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_HEIGHT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      croppedAreaPixels: {
                        ...slide.media.croppedAreaPixels,
                        height: Math.round(action.payload.height),
                      },
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_X": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      croppedAreaPixels: {
                        ...slide.media.croppedAreaPixels,
                        x: Number(Number.parseFloat(action.payload.x)),
                      },
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_CROPPEDAREAPIXELS_Y": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      croppedAreaPixels: {
                        ...slide.media.croppedAreaPixels,
                        y: Number(Number.parseFloat(action.payload.y)),
                      },
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_VIDEOSTARTREF": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      videoStartRef: Number(action.payload.videoStartRef),
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_ORIGINALVIDEODURATION": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      originalVideoDuration: Number(action.payload.originalVideoDuration),
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_MEDIA_VIDEOSPLITFRAME": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    media: {
                      ...slide.media,
                      videoSplitFrame: action.payload.videoSplitFrame,
                    },
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "ADD_NEW_ELEMENT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.concat({
                        ...newElement,
                        id: create_readable_id(6),
                      }),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "REMOVE_CREATIVE_METADATA_SLIDE_ELEMENT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [...slide.elements.filter((element) => element.id !== action.payload.elementId)],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    //ELEMENTS WITHIN SLIDES ARRAY WILL HAVE A FOURTH PROPERTY OF ELEMENTID
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_MIMETYPE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [...slide.elements.map((element) => (element.id === action.payload.elementId ? { ...element, mimeType: action.payload.mimeType } : element))],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_FILENAME": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [...slide.elements.map((element) => (element.id === action.payload.elementId ? { ...element, fileName: action.payload.fileName } : element))],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_BODYORIGINAL": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [...slide.elements.map((element) => (element.id === action.payload.elementId ? { ...element, bodyOriginal: action.payload.bodyOriginal } : element))],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_CLICKOUT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [...slide.elements.map((element) => (element.id === action.payload.elementId ? { ...element, clickout: action.payload.clickout } : element))],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_POSITION_X": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              position: {
                                ...element.position,
                                x: Number(Number.parseFloat(action.payload.x)),
                              },
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_POSITION_Y": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              position: {
                                ...element.position,
                                y: Number(Number.parseFloat(action.payload.y)),
                              },
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_DIMENSIONS_WIDTH": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              dimensions: {
                                ...element.dimensions,
                                width: Math.round(action.payload.width),
                              },
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_DIMENSIONS_HEIGHT": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              dimensions: {
                                ...element.dimensions,
                                height: Math.round(action.payload.height),
                              },
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "ADD_NEW_ANIMATION": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [
                                ...element.animations.concat({
                                  ...newAnimation,
                                  id: create_readable_id(6),
                                }),
                              ],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "REMOVE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATION": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payloads.elementId
                          ? {
                              ...element,
                              animations: [...element.animations.filter((animation) => animation.id !== action.payload.animationId)],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    // Animation reducers will need: slideId, elementId & animationId
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_NAME": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [...element.animations.map((animation) => (animation.id === action.payload.animationId ? { ...animation, name: action.payload.name } : animation))],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_VALUE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [...element.animations.map((animation) => (animation.id === action.payload.animationId ? { ...animation, value: action.payload.value } : animation))],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_DURATION": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [
                                ...element.animations.map((animation) =>
                                  animation.id === action.payload.animationId
                                    ? {
                                        ...animation,
                                        duration: Number(Number.parseFloat(action.payload.duration).toFixed(2)),
                                      }
                                    : animation,
                                ),
                              ],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_EASE": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [...element.animations.map((animation) => (animation.id === action.payload.animationId ? { ...animation, ease: action.payload.ease } : animation))],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_LABEL": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [...element.animations.map((animation) => (animation.id === action.payload.animationId ? { ...animation, label: action.payload.label } : animation))],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_DELAY": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [
                                ...element.animations.map((animation) =>
                                  animation.id === action.payload.animationId
                                    ? {
                                        ...animation,
                                        delay: Number(Number.parseFloat(action.payload.delay).toFixed(2)),
                                      }
                                    : animation,
                                ),
                              ],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_PROPERTY": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [...element.animations.map((animation) => (animation.id === action.payload.animationId ? { ...animation, property: action.payload.property } : animation))],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_VALUETO": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [
                                ...element.animations.map((animation) =>
                                  animation.id === action.payload.animationId
                                    ? {
                                        ...animation,
                                        valueTo: action.payload.valueTo,
                                      }
                                    : animation,
                                ),
                              ],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_VALUEFROM": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [
                                ...element.animations.map((animation) =>
                                  animation.id === action.payload.animationId
                                    ? {
                                        ...animation,
                                        valueFrom: action.payload.valueFrom,
                                      }
                                    : animation,
                                ),
                              ],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }
    case "UPDATE_CREATIVE_METADATA_SLIDE_ELEMENT_ANIMATIONS_METHOD": {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          slides: [
            ...state.metadata.slides.map((slide) =>
              slide.slideNumber === action.payload.id
                ? {
                    ...slide,
                    elements: [
                      ...slide.elements.map((element) =>
                        element.id === action.payload.elementId
                          ? {
                              ...element,
                              animations: [...element.animations.map((animation) => (animation.id === action.payload.animationId ? { ...animation, method: action.payload.method } : animation))],
                            }
                          : element,
                      ),
                    ],
                  }
                : slide,
            ),
          ],
        },
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CreativeProvider({ children }) {
  const [state, dispatch] = useReducer(creativeReducer, initialState);
  return (
    <CreativeStateContext.Provider value={state}>
      <CreativeDispatchContext.Provider value={dispatch}>{children}</CreativeDispatchContext.Provider>
    </CreativeStateContext.Provider>
  );
}

function useCreativeState() {
  const context = useContext(CreativeStateContext);
  if (context === undefined) {
    throw new Error("useCreativeState must be used within a CreativeProvider");
  }
  return context;
}

function useCreativeDispatch() {
  const context = useContext(CreativeDispatchContext);
  if (context === undefined) {
    throw new Error("useCreativeDispatch must be used within a CreativeProvider");
  }
  return context;
}

export { CreativeProvider, useCreativeState, useCreativeDispatch, creativeReducer, CreativeStateContext, CreativeDispatchContext };
