// Source implementation: https://github.com/aws-amplify/amplify-js/issues/4151#issuecomment-1019509199
import { AnalyticsProvider, AWSKinesisFirehoseProvider } from "aws-amplify";

export interface AWSKinesisTrackerParameters {
  streamName: string;
  partitionKey?: string;
}

/**
 * AWSKinesisTrackerProvider is a decorator around the built-in
 * AWSKinesisProvider that repackages events written by the amplify analytics
 * trackers.
 *
 * The kinesis provider expects an object with a property named 'event'.
 * This event should contain the streamName, optionally the
 * partitionKey, and the payload in a field called data. On the backend,
 * for example in a Studio notebook, is what you can query. You could
 * create a table in zeppelin/flink to read this data like so:
 *
 * CREATE TABLE `pviewsjsonv` (
 *    `name` STRING,
 *    `attributes` ROW<url STRING, clientTimestamp STRING, userAgent STRING>
 *    )
 * WITH (
 *    'connector' = 'kinesis',
 *    'stream' = 'mvpKinesis-dev',
 *    'aws.region' = 'us-east-2',
 *    'scan.stream.initpos' = 'LATEST',
 *    'format' = 'json'
 * );
 *
 * NOTE: this assumes you've added clientTimestamp and userAgent via a
 * custom attributes function on the tracker.
 *
 * To use, you need to add the tracker as a pluggable, set the region in
 * configuration, and the setup autotracking. For example:
 *
 * Analytics.addPluggable(
 *   new AWSKinesisTrackerProvider({streamName: 'myStream'}));
 *
 * Analytics.configure({
 *   AWSKinesis: {
 *     region: awsExports.aws_project_region
 *   },
 *   // note the stream name becomes part of the provider name. This is to
 *   // avoid collisions if you want to write tracker events to different
 *   // streams.
 *   'AWSKinesisTracker-myStream': {
 *      region: awsExports.aws_project_region
 *   }
 * });
 *
 * Analytics.autoTrack('pageView', {
 *   enable: true,
 *   type: 'SPA',
 *   provider: 'AWSKinesisTracker-myStream',
 *   attributes: getAdditionalTrackerData //this is a function that returns an object with additional event attributes
 * });
 */
export class AWSKinesisTrackerProvider implements AnalyticsProvider {
  private awsKinesisFirehoseProvider;
  private streamName;
  private partitionKey;

  constructor(trackerParams: AWSKinesisTrackerParameters, config?: any) {
    this.partitionKey = trackerParams.partitionKey;
    this.streamName = trackerParams.streamName;
    this.awsKinesisFirehoseProvider = new AWSKinesisFirehoseProvider(config);
    // this._awsKinesisProvider = new AWSKinesisProvider(config);
  }

  configure(config: object): object {
    return this.awsKinesisFirehoseProvider.configure(config);
  }

  record(params: any): Promise<boolean> {
    let event = {
      data: params.event,
      streamName: this.streamName,
    };
    if (this.partitionKey) {
      Object.assign(event, { partitionKey: this.partitionKey });
    }
    return this.awsKinesisFirehoseProvider.record({ event });
  }

  /**
   * get the category of the plugin
   */
  public getCategory(): string {
    return "Analytics";
  }

  /**
   * get provider name of the plugin
   */
  public getProviderName(): string {
    return `AWSKinesisTracker-${this.streamName}`;
  }
}
