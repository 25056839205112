// prettier-ignore

export default function disable_react_dev_tools() {
	// Checks if react devtools are present - stop it running on mobile web
	if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
		// Stops all react devtool functions running
		for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
			// eslint-disable-next-line
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => {} : null
		}
	}
}
