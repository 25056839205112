//EXTERNAL
import React, { useState, useEffect } from "react";
//COMPONENTS
import { PCSelect } from "@picnicmedia/masanatemples123";
//ASSETS
//FUNCTIONS
import create_readable_id from "../helpers/create_readable_id";
//CONTEXT
import { useCreativeState, useCreativeDispatch } from "../contexts/creative.context";
//TYPES
interface Option {
  label?: string;
  name?: string;
  id?: string;
  value?: number | string;
}
//QUERIES
//MUTATIONS

export default function AdvertiserDropdown({ advertiserList }: any) {
  const creative = useCreativeState();
  const creativeDispatch = useCreativeDispatch();
  const [options, setOptions] = useState<Option[]>([]);
  const [chosenOption, setChosenOption] = useState<Option>();

  const createOption = (option: Option) => {
    return {
      label: option.name,
      value: option.name,
      id: option.id || create_readable_id(),
    };
  };

  useEffect(() => {
    let isLoaded = false;

    if (!isLoaded) {
      onLoad();
    }
    isLoaded = true;
  }, []);
  const onLoad = () => {
    try {
      const defaultOptions = advertiserList.map((x: any) => {
        if (x.advertiserName === creative.advertiserName) {
          let newOption = createOption({
            name: x.advertiserName,
            id: x.advertiserId,
          });
          setChosenOption(newOption);
          return newOption;
        }
        return createOption({ name: x.advertiserName, id: x.advertiserId });
      });

      // filtering through the options and removing duplicates
      const noDuplicates = defaultOptions.filter(
        (value: any, index: any, self: any) =>
          // self refers to the entire array
          index === self.findIndex((t: any) => t.value === value.value),
      );

      // 1. loops through defaultOptions array
      // 2. then loops through self array and checks each items id with the id of the current value from defaultOptions array
      // 3. if they match it checks if their index numbers also match
      // 4. if this returns true it adds this value to the new array as it is the first instance of a duplicate advertiser name. Other duplicates of the same advertiser name are filtered out because they have matching id's but their index numbers are different

      setOptions(noDuplicates.sort((a: any, b: any) => a.value.localeCompare(b.value)));
    } catch (error) {
      alert(error);
    }
  };

  const handleCreate = (input: any) => {
    // turn all advertisers to lower case and remove white space
    const lowerCaseAdvertisers = options.map((option: any) => {
      return option.label.toLowerCase().replace(/\s/g, "");
    });
    // turn input to lower case and remove white space
    const lowerCaseInput = input.toLowerCase().replace(/\s/g, "");
    // returns true if input is already in advertiser list
    if (lowerCaseAdvertisers.includes(lowerCaseInput)) {
      // returns the corresponding advertiser object
      const result: any = options.filter((option: any) => option.label.toLowerCase().replace(/\s/g, "").includes(lowerCaseInput));
      // if () return true
      setChosenOption(result);
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADVERTISERNAME",
        payload: { advertiserName: result.value },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADVERTISERID",
        payload: { advertiserId: result.id },
      });
    } else {
      // if no match is found we create a new advertiser
      let newOption = createOption({ name: input });
      setChosenOption(newOption);
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADVERTISERNAME",
        payload: { advertiserName: newOption.value },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADVERTISERID",
        payload: { advertiserId: newOption.id },
      });
    }
  };

  const handleChange = (selectedOption: any) => {
    setChosenOption(selectedOption);
    if (selectedOption === null) {
      creativeDispatch({ type: "UPDATE_CREATIVE_ADVERTISERNAME", payload: { advertiserName: "" } });
      creativeDispatch({ type: "UPDATE_CREATIVE_ADVERTISERID", payload: { advertiserId: "" } });
      return;
    }
    creativeDispatch({
      type: "UPDATE_CREATIVE_ADVERTISERNAME",
      payload: { advertiserName: selectedOption.value },
    });
    creativeDispatch({
      type: "UPDATE_CREATIVE_ADVERTISERID",
      payload: { advertiserId: selectedOption.id },
    });
  };

  return (
    <>
      <PCSelect
        handleCreate={handleCreate}
        handleChange={handleChange}
        options={[
          {
            label: "Select an advertiser or type to create",
            options: [...options],
          },
        ]}
        placeholder={"Select an advertiser"}
        selectedOption={chosenOption}
        label={"Advertiser"}
        // classes={"margin-bottom-4 margin-top-2 text-size-3 outline-0"}
      />
    </>
  );
}
