// EXTERNAL
import axios from "axios";
// CONFIGS
import config from "../configs/config";

export const post_slack_message = async ({ zipFilesArray, advertiserName, adName, teamName, email }) => {
  try {
    let inputText;
    if (zipFilesArray) {
      //
      // ZIPs checks whether there are two links (i.e. one for each adserver) or just one, and sets message accordingly
      //
      const zipText = encodeURIComponent(zipFilesArray.map((x) => `<${x.url}|*${x.adserver}* ZIP>`).join(" | "));
      inputText = `advertiser *${advertiserName}* ad *${adName.replace(/[&#+$~%*<>{}]/g, "")}* email *${email}* ZIPs: ${zipText}`;
    }
    if (!zipFilesArray) inputText = `Team *${teamName}* requested Adform tags - advertiser *${advertiserName}* - ad *${adName.replace(/[&#+$~%*<>{}]/g, "")}* - email *${email}*`;
    const inputChannel = config.slack.TAGS_CHANNEL;
    const response = await axios.get(`${config.slack.URL}/postMessage?inputText=${inputText}&inputChannel=${inputChannel}`);
    return response;
  } catch (error) {
    return error;
  }
};
