// EXTERNAL
import { Storage, Analytics } from "aws-amplify";
// ASSETS
import config from "../configs/config";

/**
 *
 * Uploads images or video to AWS S3
 * @param {*} param0
 * @fileKey filename-fileid-mimetype
 * @returns {key: S3 Object Key}
 */
export async function s3_media_upload({ fileKey, mimeType, body, teamId, creativeId }) {
  try {
    //
    //NAME-ID-TAG.MIMETYPE -> file-001-thumbnail.png
    //
    const uploadedFile = await Storage.put(`${teamId}/${creativeId}/${fileKey}`, body, {
      contentType: mimeType,
      customPrefix: "public",
    });
    //
    // Format response to include s3 bucket and region of file object
    //
    return {
      url: `https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/` + (await uploadedFile.key),
      key: await uploadedFile.key,
      fileName: fileKey,
    };
  } catch (error) {
    throw new Error(error);
  }
}

/**
 *
 * @param {*} param0
 * @returns
 */
export async function s3_signed_url({ teamId, creativeId, fileName }) {
  try {
    return await Storage.get(`${teamId}/${creativeId}/${fileName}`);
  } catch (error) {
    throw new Error(error);
  }
}

export async function s3_blob_download({ teamId, creativeId, fileName }) {
  try {
    const fileObject = await Storage.get(`${teamId}/${creativeId}/${fileName}`, {
      download: true,
    });
    return fileObject.Body;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}

export async function analytics_record({ name, attributes }) {
  try {
    Analytics.record(
      {
        name,
        attributes,
      },
      `AWSKinesisTracker-${config.analytics.FIREHOSESTREAMNAME}`, //"AWSKinesisFirehose", // (HTL - 05-09-22 - commenting out to use Pinpoint instead of Kinesis)
    );
  } catch (error) {
    throw new Error(error);
  }
}

export function analytics_autotrack_pageView({ attributes }) {
  try {
    Analytics.autoTrack("pageView", {
      // REQUIRED, turn on/off the auto tracking
      enable: !config.analytics.DISABLED, //Set to the reverse of DISABLED property in config
      attributes,
      type: "SPA",
      // OPTIONAL, the service provider, by default is the Amazon Pinpoint, we're applying our custom Plugin.
      provider: `AWSKinesisTracker-${config.analytics.FIREHOSESTREAMNAME}`,
    });
  } catch (error) {
    throw new Error(error);
  }
}

export function analytics_autotrack_session({ attributes }) {
  try {
    Analytics.autoTrack("session", {
      // REQUIRED, turn on/off the auto tracking
      enable: !config.analytics.DISABLED, //Set to the reverse of DISABLED property in config
      attributes,
      // OPTIONAL, the service provider, by default is the Amazon Pinpoint, we're applying our custom Plugin.
      provider: `AWSKinesisTracker-${config.analytics.FIREHOSESTREAMNAME}`,
    });
  } catch (error) {
    throw new Error(error);
  }
}
