import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  teamId: "",
  teamName: "",
  email: "",
  type: "",
  filter: "user",
  isAuthenticated: false,
};

const UserStateContext = createContext();
const UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "UPDATE_USER_ID": {
      return { ...state, id: action.payload.id };
    }
    case "UPDATE_USER_FIRSTNAME": {
      return { ...state, firstName: action.payload.firstName };
    }
    case "UPDATE_USER_LASTNAME": {
      return { ...state, lastName: action.payload.lastName };
    }
    case "UPDATE_USER_TEAMID": {
      return { ...state, teamId: action.payload.teamId };
    }
    case "UPDATE_USER_TEAMNAME": {
      return { ...state, teamName: action.payload.teamName };
    }
    case "UPDATE_USER_EMAIL": {
      return { ...state, email: action.payload.email };
    }
    case "UPDATE_USER_TYPE": {
      return { ...state, type: action.payload.type };
    }
    case "UPDATE_USER_FILTER": {
      return { ...state, filter: action.payload.filter };
    }
    case "UPDATE_USER_ISAUTHENTICATED": {
      return { ...state, isAuthenticated: action.payload.isAuthenticated };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, initialState);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, userReducer, UserStateContext, UserDispatchContext };
