// EXTERNAL
import { withRouter } from "react-router-dom";
import { Amplify, Analytics, AWSKinesisFirehoseProvider } from "aws-amplify";
import { ApolloProvider } from "@apollo/client";
// COMPONENTS
import Authorization from "./containers/Authorization";
import Layout from "./containers/Layout";
import Routes from "./routing/Routes";
// ASSETS
// CONFIGS
import awsconfig from "./configs/aws-exports";
import client from "./configs/apollo";
// HELPERS
import { AWSKinesisTrackerProvider } from "./helpers/kinesis_plugin";
// CONTEXT
import { UserProvider } from "./contexts/user.context";
import { NotificationProvider } from "./contexts/notification.context";
import { CreativeProvider } from "./contexts/creative.context";
// TYPES
Amplify.configure(awsconfig);
Analytics.addPluggable(new AWSKinesisFirehoseProvider({ region: awsconfig.Analytics.AWSKinesisFirehose.region }));
// (HTL - 07-05-22) - Adding plugin to support Firehose streaming for session and pageView events.
Analytics.addPluggable(new AWSKinesisTrackerProvider({ streamName: awsconfig.Analytics.AWSKinesisFirehose.streamName }));
Analytics.configure({
  AWSKinesis: {
    region: awsconfig.Analytics.AWSKinesisFirehose.region,
  },
  [`AWSKinesisTracker-${awsconfig.Analytics.AWSKinesisFirehose.streamName}`]: {
    region: awsconfig.Analytics.AWSKinesisFirehose.region,
  },
});

function App() {
  return (
    <>
      <div className="App">
        <ApolloProvider client={client}>
          <NotificationProvider>
            <UserProvider>
              <Authorization>
                <CreativeProvider>
                  <Layout>
                    <Routes />
                  </Layout>
                </CreativeProvider>
              </Authorization>
            </UserProvider>
          </NotificationProvider>
        </ApolloProvider>
      </div>
    </>
  );
}

export default withRouter(App);
