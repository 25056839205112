// EXTERNAL
import { v4 as uuidv4 } from "uuid";
// LIBS
import { duplicate_creative_files } from "../libs/node";
// FUNCTIONS
import create_dynamodb_creative_data from "./create_dynamodb_creative_data";

export const duplicate_creative = async (data, existingCreativeId, user, mediaLoadingDispatch, notificationDispatch, saveNewCreative) => {
  mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "duplicate", message: "Duplicating creative", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
  try {
    const duplicateCreativeId = uuidv4();
    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "duplicate", message: "Duplicating creative", progress: Math.floor(Math.random() * (30 - 11 + 1) + 11) } });
    // Creates a payload object based on existing creative, with new creative id & user (if different from existing). It does not copy the campaignId/adformTag (so these attributes won't exist on the new dynamoDb item).
    // If the user requests adform tags, then these attributes will be added. This ensures old creatives that don't have the adform attributes don't error on saveExistingCreative (due to trying update attributes that don't exist). KT 10.05.22
    const payload = create_dynamodb_creative_data(data.readOneCreativeId, user, duplicateCreativeId, true);
    // Saves new creative context data in dynamoDB. KT 24.03.22.
    await saveNewCreative({ variables: { input: payload } });
    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "duplicate", message: "Duplicating creative", progress: Math.floor(Math.random() * (80 - 61 + 1) + 61) } });
    // duplicates files in S3. KT 24.03.22.
    await duplicate_creative_files({ existingCreativeId, teamId: user.teamId, duplicateCreativeId });
    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
    notificationDispatch({
      type: "ADD",
      payload: {
        header: "Success",
        message: "Creative duplicated successfully",
        type: "success",
        autoDismiss: true,
      },
    });
  } catch (error) {
    console.log(error);
    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
    return notificationDispatch({
      type: "ADD",
      payload: {
        header: "Error",
        message: "Something went wrong.",
        type: "error",
        autoDismiss: false,
      },
    });
  }
};
