/**
 *
 * @param {*} file
 * @param {*} mimeType - string
 * @returns - {width: number, height: number}
 */
export default async function get_file_width_height(file, mimeType) {
  const canvas = document.createElement("canvas");
  const assetSrc = typeof file === "string" ? file : URL.createObjectURL(file);
  if (mimeType.includes("image")) {
    const image = await create_image(assetSrc);
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
  }

  if (mimeType.includes("video")) {
    const video = await create_video(assetSrc);
    canvas.width = video.width;
    canvas.height = video.height;
  }

  URL.revokeObjectURL(assetSrc);

  return {
    width: canvas.width,
    height: canvas.height,
  };
}

const create_image = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const create_video = url => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", url);
    videoPlayer.load();
    videoPlayer.addEventListener("error", error => {
      reject("error when loading video file", error);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = 0.01;
      }, 100);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        return resolve({
          width: canvas.width,
          height: canvas.height,
        });
      });
    });
  });
};
