//EXTERNAL
import { Route, Redirect } from "react-router-dom";
//COMPONENTS
//ASSETS
//FUNCTIONS
//CONTEXT
import { useUserState } from "../contexts/user.context";

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function PublicRoute({ children, ...rest }) {
  const user = useUserState();
  const redirect = querystring("redirect");
  return <Route {...rest}>{!user.isAuthenticated ? children : <Redirect to={redirect === "" || redirect === null || redirect === "/" ? "/advertisers" : redirect} />}</Route>;
}
