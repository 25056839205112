export async function url_to_base64(s3SignedUrl) {
  try {
    const image = await create_image(s3SignedUrl);
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, 0, 0);
    //
    // As Base64 string
    //
    return canvas.toDataURL("image/jpeg");
  } catch (error) {
    console.log("Error creating dataUrl", error);
  }
}

const create_image = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });
