// Merge Images
// Takes an array of image srcs, and X-Y and optional Widths & Heights, returns base64 string.
// External
import mergeImages from "merge-images";

export async function merge_images(imageArray) {
  try {
    return await mergeImages(imageArray);
  } catch (error) {
    console.log(error);
  }
}
