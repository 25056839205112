// DRAGGABLE FRAMES
// Functions for format draggable frames
let payloadArray = [];
let draggedTo = null;

export const handleDragStart = (e, creativeContext, creativeDispatch) => {
  e.stopPropagation();
  e.target.style.opacity = 0.4;

  const initialPosition = Number(e.currentTarget.dataset.position);
  creativeDispatch({
    type: "UPDATE_CREATIVE_DRAGGABLE_DRAGGEDFROM",
    payload: { draggedFrom: initialPosition },
  });
  creativeDispatch({
    type: "UPDATE_CREATIVE_DRAGGABLE_ISDRAGGING",
    payload: { isDragging: true },
  });
};

export const handleDragOver = (e, creativeContext, creativeDispatch) => {
  e.stopPropagation();
  e.preventDefault();

  let newList = creativeContext.metadata.slides.map((slide) => ({
    id: slide.id,
    index: slide.slideNumber - 1,
  }));

  const draggedFrom = creativeContext.draggable.draggedFrom - 1; //null
  draggedTo = Number(e.currentTarget.dataset.position - 1);
  const itemDragged = newList[draggedFrom];

  const remainingItems = newList.filter((item) => item.index !== draggedFrom);

  let newOrderArray = [...remainingItems.slice(0, draggedTo), itemDragged, ...remainingItems.slice(draggedTo)];

  // CHECK HERE THAT ITEM EXISTS (FUNCTION WILL BE FIRED IF USER DRAGS ANY MEDIA OVER THIS DRAGGABLE SECTION)
  let newOrderArrayItems = newOrderArray.map((item) => {
    if (!item) return undefined;
    return item.id;
  });
  if (newOrderArrayItems.includes(undefined)) {
    return;
  }
  if (!newOrderArrayItems.includes(undefined)) {
    payloadArray = newOrderArrayItems;
  }
};

export const handleDragEnd = (e, creativeContext, creativeDispatch) => {
  e.stopPropagation();
  e.preventDefault();
  e.target.style.opacity = 1;

  if (draggedTo !== creativeContext.draggable.draggedTo) {
    //Return early to stop 'id of undefined' errors. Payload array should have length or 0 from handleDragOver fn.
    if (payloadArray.length === 0) return;
    creativeDispatch({
      type: "UPDATE_CREATIVE_METADATA_SLIDE_SLIDENUMBERS",
      payload: { newSlideOrder: payloadArray },
    });
  }

  creativeDispatch({
    type: "UPDATE_CREATIVE_DRAGGABLE_DRAGGEDFROM",
    payload: { draggedFrom: null },
  });
  creativeDispatch({
    type: "UPDATE_CREATIVE_DRAGGABLE_DRAGGEDTO",
    payload: { draggedTo: null },
  });
  creativeDispatch({
    type: "UPDATE_CREATIVE_DRAGGABLE_ISDRAGGING",
    payload: { isDragging: false },
  });
};

export const updateFrameData = (slide, creativeDispatch, creativeContext) => {
  let newOrder = [...creativeContext.metadata.slides];

  newOrder.map((frame) => {
    if (frame.slideNumber === slide.slideNumber) {
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_CONTROL_ISOPEN",
        payload: {
          id: frame.id,
          isToggleContainerOpen: !frame.control.isToggleContainerOpen,
        },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_SLIDEINDEX",
        payload: { slideIndex: frame.slideNumber - 1 },
      });
    }
    if (frame.slideNumber !== slide.slideNumber) {
      creativeDispatch({
        type: "UPDATE_CREATIVE_METADATA_SLIDE_CONTROL_ISOPEN",
        payload: {
          id: frame.id,
          isToggleContainerOpen: false,
        },
      });
    }
  });
};
