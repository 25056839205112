//EXTERNAL
import { useQuery, gql } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
//COMPONENTS
import * as ComponentLibrary from "@picnicmedia/masanatemples123";
//ASSETS
//FUNCTIONS
//CONTEXT
import { useUserState } from "../contexts/user.context";
import { useCreativeDispatch } from "../contexts/creative.context";
import { Link } from "react-router-dom";
import { logger } from "../libs/logger";
//TYPES
type Creative = {
  id: string;
  advertiserName: string;
  advertiserId: string;
  adName: string;
};
//QUERIES
const LIST_CREATIVES = gql`
  query ($teamId: String!) {
    readAllCreativesTeam(teamId: $teamId) {
      id
      advertiserName
      advertiserId
      adName
    }
  }
`;

export default function Advertisers() {
  const user = useUserState();
  const creativeDispatch = useCreativeDispatch();
  const { error, loading, data } = useQuery(LIST_CREATIVES, {
    variables: { teamId: user.teamId },
    onError: (error) => {
      logger({ error, user });
    },
  });

  return (
    <>
      <div className="picnic-font flex flex-column min-vh-100 background-color-neutral-2" style={{ width: "100%" }}>
        {data && data.readAllCreativesTeam.length > 0 ? (
          <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={5}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(185px, min-content))",
                gridGap: "4px",
                justifyContent: "center",
                padding: "initial",
              }}>
              {data.readAllCreativesTeam
                .filter((value: any, index: any, self: any) => index === self.findIndex((t: any) => t.advertiserId === value.advertiserId))
                .sort((a: any, b: any) => a.advertiserName.localeCompare(b.advertiserName))
                .map((creative: Creative) => {
                  return (
                    <div key={creative.id} style={{ width: "166px", height: "219px" }}>
                      <ComponentLibrary.PCCard destination={`advertiser/${creative.advertiserId}`}>
                        <>
                          <ComponentLibrary.PCCardHeader text={`Advertiser ID: ${creative.advertiserId}`} />
                          <ComponentLibrary.PCCardSubheader text={creative.advertiserName ? (creative.advertiserName.length > 15 ? `${creative.advertiserName.substring(0, 14)}...` : creative.advertiserName) : ""} />
                        </>
                      </ComponentLibrary.PCCard>
                    </div>
                  );
                })}
            </div>
          </ComponentLibrary.PCSpacer>
        ) : (
          <>
            <div className="min-vh-100 flex justify-center items-center">
              <Link
                to={{
                  pathname: `/creative/${uuidv4()}`,
                  search: "?new",
                }}>
                <ComponentLibrary.PCActionButton
                  text={"Add first creative"}
                  iconLeft={"plus"}
                  borderColor={"platform-border-primary-3"}
                  color={"platform-background-primary-3"}
                  textColor={"color-neutral-1"}
                  clickHandler={() =>
                    creativeDispatch({
                      type: "RESET_CREATIVE_DATA",
                    })
                  }
                />
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
}
