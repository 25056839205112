import { s3_media_upload } from "../libs/amplify";
import pixelVideoPath from "../assets/pixel-video.mp4";
import pixelVideoPosterPath from "../assets/pixel-video-poster.jpeg";

/**
 * Pixel Video asset and poster get saved to each creative onSave, this forces DV360 adserver to include rich-media reporting to creative.
 * @param {string} teamId - team id of current signed in user
 * @param {(string|undefined)} creativeId - id of the creative build
 */
export default async function add_pixel_video(teamId, creativeId) {
  try {
    const pixelVideo = await create_file(pixelVideoPath, "pixel-video-original.mp4", "video/mp4");
    const pixelVideoPoster = await create_file(pixelVideoPosterPath, "pixel-video-poster.mp4", "image/jpeg");
    await s3_media_upload({
      fileKey: "pixel-video-original.mp4",
      mimeType: "video/mp4",
      body: pixelVideo,
      teamId,
      creativeId,
    });
    await s3_media_upload({
      fileKey: "pixel-video-poster.jpeg",
      mimeType: "image/jpeg",
      body: pixelVideoPoster,
      teamId,
      creativeId,
    });
  } catch (error) {
    console.log(error);
  }
}

const create_file = async (path, name, mimeType) => {
  const response = await fetch(path);
  const data = await response.blob();
  let file = new File([data], name, {
    type: mimeType,
  });
  return file;
};
