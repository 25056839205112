//EXTERNAL
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
//COMPONENTS
//ASSETS
//FUNCTIONS
//CONTEXT
import { useUserState } from "../contexts/user.context";

export default function ProtectedRoute({ children, ...rest }) {
  const user = useUserState();
  const { pathname, search } = useLocation();

  return <Route {...rest}>{user.isAuthenticated ? children : <Redirect to={`/signin?redirect=${pathname}${search}`} />}</Route>;
}
