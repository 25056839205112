// FUNCTIONS
import { asset_edit } from "../libs/node";
import { s3_signed_url } from "../libs/amplify";

export default async function edit_button_logo({ method, teamId, creativeId, creativeDispatch, fileId, inputWidth, inputHeight, outputWidth, cropX, cropY, fileName, mimeType, notificationDispatch, mediaLoadingDispatch }) {
    try {
        mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (20 - 11 + 1) + 11) } });
        const croppedFile = await asset_edit({
            method,
            cropType: "logo",
            teamId,
            creativeId,
            fileName,
            fileId,
            mimeType,
            inputWidth,
            inputHeight,
            outputWidth,
            cropX,
            cropY,
        });
        const croppedUrl = await s3_signed_url({ teamId, creativeId, fileName: await croppedFile.cropped.fileName });
        mediaLoadingDispatch({ type: "UPDATE_PROGRESS", payload: { progress: Math.floor(Math.random() * (99 - 98 + 1) + 98) } });

        /////////////////////////////////////////////
        // UPDATE CREATIVE CONTEXT
        /////////////////////////////////////////////
        creativeDispatch({
            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_BODYCROPPED",
            payload: { bodyCropped: croppedUrl },
        });
    } catch (error) {
        return notificationDispatch({
            type: "ADD",
            payload: {
                header: "Error uploading",
                message: "There was an problem uploading the file. Please try again.",
                type: "error",
                autoDismiss: true,
            },
        });
    }
}
