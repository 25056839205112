//EXTERNAL
import React from "react";
//COMPONENTS
import AuthorizedLayout from "../layout/AuthorizedLayout";
import UnAuthorizedLayout from "../layout/UnAuthorizedLayout";
//ASSETS
//HOOKs
import has_ad_blocker from "../hooks/has_ad_blocker";
//CONTEXT
import { useUserState } from "../contexts/user.context";
import { useNotificationDispatch } from "../contexts/notification.context";
//TYPES
type Props = { children: React.ReactNode };

export default function Layout({ children }: Props) {
  const user = useUserState();
  const notificationDispatch = useNotificationDispatch();

  const adBlocker = () => {
    const hasAdBlocker = has_ad_blocker();
    if (hasAdBlocker) {
      return notificationDispatch({
        type: "ADD",
        payload: {
          header: "Ad Blocker",
          message: "Please disable your adblocker.",
          type: "info",
          autoDismiss: false,
        },
      });
    }
  };

  return (
    <>
      <div className="picnic-font text-size-2 platform-background-platform-background-3" style={{ minWidth: "1400px" }}>
        {user.isAuthenticated ? (
          <AuthorizedLayout>
            {/* Check whether user has ad blocker enabled */}
            {adBlocker()}
            {children}
          </AuthorizedLayout>
        ) : (
          <UnAuthorizedLayout>{children}</UnAuthorizedLayout>
        )}
      </div>
    </>
  );
}
