// function to correctly format time from a dateTimeString
export function format_time(dateTimeString) {
  // 2022-09-07T15:30:41.240Z => 07/09/2022 15:30 PM
  let time = dateTimeString.split("T")[1].split("Z")[0].slice(0, 5);
  let formattedTime = Number(time.slice(0, 2) >= 12) ? time + " PM" : time + " AM";
  let date = dateTimeString.split("T")[0].split("-").reverse().join("/");

  return String(date + " " + formattedTime);
}

export function format_date_from_string(ISOString) {
  // 2022-09-07T15:31:04.609Z => 07/09/2022
  let date = ISOString.split("T")[0].split("-").reverse().join("/");

  return String(date);
}

export function format_date_day_from_string(ISOString) {
  // 2022-09-07T15:31:04.609Z => 07
  let date = ISOString.split("T")[0].split("-").reverse().join("/");

  return String(date.split("/")[0]);
}

export function format_date_month_from_string(ISOString) {
  // 2022-09-07T15:31:04.609Z => 09
  let date = ISOString.split("T")[0].split("-").reverse().join("/");

  return String(date.split("/")[1]);
}

export function format_date_year_from_string(ISOString) {
  // 2022-09-07T15:31:04.609Z => 2022
  let date = ISOString.split("T")[0].split("-").reverse().join("/");

  return String(date.split("/")[2]);
}

export function format_time_from_string(dateTimeString) {
  // 2022-09-07T15:31:43.571Z => 15:31
  let time = dateTimeString.split("T")[1].split("Z")[0].slice(0, 5);

  return String(time);
}

export function format_time_hour_from_string(dateTimeString) {
  // 2022-09-07T15:31:43.571Z => 15
  let time = dateTimeString.split("T")[1].split("Z")[0].slice(0, 5);

  return String(time.split(":")[0]);
}
export function format_time_minute_from_string(dateTimeString) {
  // 2022-09-07T15:31:43.571Z => 31
  let time = dateTimeString.split("T")[1].split("Z")[0].slice(0, 5);

  return String(time.split(":")[1]);
}

export function create_new_time_isostring() {
  const newDate = new Date();
  const newISOString = newDate.toISOString();
  return newISOString;
}
