// EXTERNAL
import axios from "axios";
// CONFIGS
import config from "../configs/config";
// HELPERS
import { create_new_time_isostring } from "../helpers/format_time";

export const logger = async ({ error, user }) => {
  try {
    //
    // Turn logging on/off in the config. Allows different settings across environments.
    //
    if (config.logging.ENABLED) {
      const message = `:warning: Error @ ${create_new_time_isostring()} %0A :red_circle: ${error.message} %0A :technologist: ${user.email} %0A :office: ${user.teamId} %0A :page_facing_up: ${window.location.pathname}`;
      await axios.get(`${config.slack.URL}/postMessage?inputText=${message}&inputChannel=${config.slack.LOGS_CHANNEL}&botToken=${config.slack.MUMFORD}`);
    }
    if (!config.logging.ENABLED) return;
  } catch (error) {
    console.log(error);
    return error;
  }
};
