//EXTERNAL
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
//COMPONENTS
import { PCSpacer, PCHeader, PCSidebar, PCNavIcon, PCTextArea, PCDialogueBox, PCLargeNavPopout, PCNavPopout, PCIconPicker, PCUserAvatar, PCActionButton } from "@picnicmedia/masanatemples123";
//ASSETS
import logo from "../assets/android-chrome-192x192.png";
import adcreator from "../assets/ad-creator.svg";
import help from "../assets/help.svg";
import library from "../assets/library.svg";
import reports from "../assets/reports.svg";
//FUNCTIONS
import { validateForm } from "../helpers/validate_form";
//CONTEXT
import { useUserDispatch, useUserState } from "../contexts/user.context";
import { useCreativeDispatch } from "../contexts/creative.context";
import { useNotificationDispatch } from "../contexts/notification.context";
//TYPES

export default function AuthenticatedLayout({ children }) {
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const creativeDispatch = useCreativeDispatch();
  const notificationDispatch = useNotificationDispatch();
  const history = useHistory();
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [adcreatorPopout, setAdcreatorPopout] = useState(false);
  const [libraryPopout, setLibraryPopout] = useState(false);
  const [reportsPopout, setReportsPopout] = useState(false);
  const [profilePopout, setProfilePopout] = useState(false);
  const [helpPopout, setHelpPopout] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);

  const handleLogout = async () => {
    await Auth.signOut();
    userDispatch({ type: "UPDATE_USER_ISAUTHENTICATED", payload: { isAuthenticated: false } });
    history.push("/");
  };

  const handleSlackRequest = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      let data = {
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        teamId: user.teamId,
        type: modalType === "feedback" ? "Feedback Received" : "Contact Us Received",
        content: content,
        url: window.location.href,
      };
      axios.post("https://1k8bwqh956.execute-api.eu-west-1.amazonaws.com/development/create-slack-feedback-request", data);
      setModalOpen(false);
      setContent("");
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Message submitted",
          message: "",
          type: "success",
          autoDismiss: true,
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Something went wrong. Please try again",
          message: error.message,
          type: "error",
          autoDismiss: false,
        },
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <PCSidebar>
          <PCNavIcon icon={logo} size={"24px"} linkTo={{ pathname: "/" }} />

          <PCNavIcon
            icon={adcreator}
            size={"24px"}
            name={"creative"}
            onMouseOver={() => setAdcreatorPopout(true)}
            onMouseOut={() => setAdcreatorPopout(false)}
            handleClick={() =>
              creativeDispatch({
                type: "RESET_CREATIVE_DATA",
              })
            }
            linkTo={{
              pathname: `/creative/${uuidv4()}`,
              search: "?new",
            }}
          />

          {adcreatorPopout && <PCNavPopout top={"76px"} height={"40px"} text={"Ad Creator"} />}

          <PCNavIcon icon={library} size={"24px"} name={"advertisers"} onMouseOver={() => setLibraryPopout(true)} onMouseOut={() => setLibraryPopout(false)} linkTo={{ pathname: "/advertisers" }} />
          {libraryPopout && <PCNavPopout top={"140px"} height={"40px"} text={"Library"} />}

          {/*  */}
          {/* COMMENTED OUT UNTIL WE HAVE A REPORTING PAGE */}
          {/*  */}
          {/* <PCNavIcon
            icon={reports}
            size={"24px"} 
            name={"reports"}
            onMouseOver={() => setReportsPopout(true)}
            onMouseOut={() => setReportsPopout(false)}
            linkTo={"/reports"}
          />
          {reportsPopout && (
            <PCNavPopout top={"204px"} height={"40px"} text={"Reports"} />
          )} */}

          <div className="bottom-0 absolute width-7">
            <PCNavIcon
              icon={help}
              size={"24px"}
              handleClick={(e) => {
                e.preventDefault();
                setHelpPopout(!helpPopout);
              }}
              linkTo={{ pathname: "/" }}
            />

            <div
              className="height-7 tracked-tight flex flex-row items-center justify-center pointer"
              onClick={() => {
                setProfilePopout(!profilePopout);
              }}>
              <div className="height-5 width-5">
                <PCUserAvatar firstname={user.firstName} lastname={user.lastName} />
              </div>
            </div>
          </div>

          {helpPopout && (
            <PCLargeNavPopout
              bottom={"75px"}
              height={"184px"}
              width={"237px"}
              onClickOut={() => {
                setHelpPopout(false);
              }}
              header={<div className="platform-font text-size-2 fw5 platform-color-typography-titles-3 padding-around-3">Support</div>}>
              <div
                className={`platform-font text-size-2 fw5 pointer ${hovered ? "platform-color-primary-3" : "platform-color-typography-descriptions-3"} padding-around-3`}
                onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => {
                  setHelpPopout(false);
                  setModalType("contact");
                  setModalOpen(true);
                }}>
                Contact Us
              </div>
              <div
                className={`platform-font text-size-2 fw5 pointer ${hovered1 ? "platform-color-primary-3" : "platform-color-typography-descriptions-3"} padding-around-3`}
                onMouseOver={() => setHovered1(true)}
                onMouseLeave={() => setHovered1(false)}
                onClick={() => {
                  setHelpPopout(false);
                  setModalType("feedback");
                  setModalOpen(true);
                }}>
                Send feedback
              </div>
              <Link
                className="no-underline black width-5 height-5"
                to={{
                  pathname: "https://wiki.picnic-media.com/c5b74d518d6c450d9f570a47cb0a601e",
                }}
                target="_blank"
                onClick={() => {
                  setHelpPopout(false);
                }}>
                <div
                  className={`platform-font text-size-2 fw5 pointer ${hovered2 ? "platform-color-primary-3" : "platform-color-typography-descriptions-3"} padding-around-3`}
                  onClick={() => {
                    setHelpPopout(false);
                  }}
                  onMouseOver={() => setHovered2(true)}
                  onMouseLeave={() => setHovered2(false)}>
                  Wiki & FAQs
                </div>
              </Link>
            </PCLargeNavPopout>
          )}

          {profilePopout && (
            <PCLargeNavPopout
              bottom={"8px"}
              height={"184px"}
              width={"237px"}
              onClickOut={() => {
                setProfilePopout(false);
              }}
              header={
                <>
                  <div className="width-5 height-5 margin-around-3">
                    <PCUserAvatar firstname={user.firstName} lastname={user.lastName} />
                  </div>
                  <div className="platform-font text-size-2 fw5 platform-color-typography-titles-3">{`${user.firstName} ${user.lastName}`}</div>
                </>
              }
              footer={
                <div className={`platform-font pointer ${hovered5 ? "platform-color-primary-3" : "platform-color-typography-descriptions-3"}`} onMouseOver={() => setHovered5(true)} onMouseLeave={() => setHovered5(false)} onClick={handleLogout}>
                  <div className="width-4 dib margin-right-2">
                    <PCIconPicker icon={"sign-out"} />
                  </div>
                  Log out
                </div>
              }>
              <Link
                className="no-underline black width-5 height-5"
                to={{ pathname: "/settings" }}
                onClick={() => {
                  setProfilePopout(false);
                }}>
                <div
                  className={`platform-font text-size-2 fw5 pointer ${hovered3 ? "platform-color-primary-3" : "platform-color-typography-descriptions-3"} padding-around-3`}
                  onClick={() => {
                    setProfilePopout(false);
                  }}
                  onMouseOver={() => setHovered3(true)}
                  onMouseLeave={() => setHovered3(false)}>
                  <div className="width-4 dib margin-right-2">
                    <PCIconPicker icon={"cog"} />
                  </div>
                  Account settings
                </div>
              </Link>
              <Link
                className="no-underline black width-5 height-5"
                to={{
                  pathname: "https://wiki.picnic-media.com/b7e330fddbd0421b8d381ebd8ceb5e75?v=310275a767524c3a9729735576e1c681",
                }}
                target="_blank"
                onClick={() => {
                  setProfilePopout(false);
                }}>
                <div
                  className={`platform-font text-size-2 fw5 pointer ${hovered4 ? "platform-color-primary-3" : "platform-color-typography-descriptions-3"} padding-around-3`}
                  onClick={() => {
                    setProfilePopout(false);
                  }}
                  onMouseOver={() => setHovered4(true)}
                  onMouseLeave={() => setHovered4(false)}>
                  <div className="width-4 dib margin-right-2">
                    <PCIconPicker icon={"image"} />
                  </div>
                  Creative specifications
                </div>
              </Link>
            </PCLargeNavPopout>
          )}

          {modalOpen ? (
            <PCDialogueBox open={modalOpen} onClose={() => setModalOpen(!modalOpen)} width={"width-13"} height={"height-12"}>
              <PCSpacer type={"padding"} position={"around"} size={3}>
                <PCHeader text={modalType === "feedback" ? "Feedback" : "Contact us"} />
                <PCSpacer type={"margin"} position={"top"} size={5} />
                <PCTextArea id={"content"} value={content} rows={12} placeholder={"Type message here"} changeHandler={(e) => setContent(e.target.value)} />
                <PCSpacer type={"margin"} position={"top"} size={4} />
                <PCActionButton text={"Send"} clickHandler={handleSlackRequest} isDisabled={!validateForm(content)} textColor={"color-neutral-1"} color={"platform-background-primary-3"} />
              </PCSpacer>
            </PCDialogueBox>
          ) : null}
        </PCSidebar>
        <div style={{ marginLeft: "64px" }}>{children}</div>
      </div>
    </>
  );
}
