//EXTERNAL
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";
//COMPONENTS
import * as ComponentLibrary from "@picnicmedia/masanatemples123";
import StoryFormat from "../components/formats/Story";
import PostFormat from "../components/formats/Post";
import CarouselFormat from "../components/formats/Carousel";
import SwipeFormat from "../components/formats/Swipe";
import ClipsFormat from "../components/formats/Clips";
import AdvertiserDropdown from "../components/AdvertiserDropdown";
//ASSETS
import exportPNGContainerStory from "../assets/export-as-png-container-story.png";
import exportPNGContainerPost from "../assets/export-as-png-container-post.png";
import placeholderBrandLogo from "../assets/placeholder-brandlogo.png";
import placeholderButtonLogo from "../assets/placeholder-buttonlogo.png";
import PlaceholderCreative from "../assets/placeholder-creative.png";
//HOOKS
import is_new_creative from "../hooks/is_new_creative";
import is_format_preview_sticky from "../hooks/is_format_preview_sticky";
//LIBS
import { merge_images } from "../libs/merge_images";
import { save_file_locally } from "../libs/file_saver";
import { post_slack_message } from "../libs/slack";
import { s3_media_upload, s3_blob_download, analytics_record } from "../libs/amplify";
import { file_cleanup, adform_creative_tags, zip_creative_files } from "../libs/node";
import { logger } from "../libs/logger";
//HELPERS
import set_creative_context_data from "../helpers/set_creative_context_data";
import { format_date_day_from_string, format_date_month_from_string, format_date_year_from_string, format_time_hour_from_string, format_time_minute_from_string, create_new_time_isostring } from "../helpers/format_time";
import { validate_ad_creator_form } from "../helpers/validate_form";
// import autofill from "../helpers/autofill";
import add_brandlogo from "../helpers/add_brandlogo";
import edit_brandlogo from "../helpers/edit_brandlogo";
import add_asset from "../helpers/add_asset";
import add_button_logo from "../helpers/add_button_logo";
import edit_button_logo from "../helpers/edit_button_logo";
import dom_to_image from "../helpers/dom_to_image";
import add_pixel_video from "../helpers/add_pixel_video";
import validate_all_s3_urls from "../helpers/validate_s3_url";
import create_dynamodb_creative_data from "../helpers/create_dynamodb_creative_data";
import { validate_adform_url } from "../helpers/validate_form";
//CONTEXT;
import { useUserState } from "../contexts/user.context";
import { useCreativeState, useCreativeDispatch } from "../contexts/creative.context";
import { useNotificationDispatch } from "../contexts/notification.context";
import { useMediaLoadingDispatch } from "../contexts/mediaLoading.context";
//CONFIGS
import config from "../configs/config";
import studio_config from "../configs/studio.json";
//TYPES
export interface StudioConfig {
  [key: string]: any;
}
//QUERIES
import * as queries from "../graphql/queries";
//MUTATIONS
import * as mutations from "../graphql/mutations";

export default function Creative() {
  const { creativeId } = useParams<Record<string, string | undefined>>();
  const user = useUserState();
  const creative = useCreativeState();
  const creativeDispatch = useCreativeDispatch();
  const notificationDispatch = useNotificationDispatch();
  const mediaLoadingDispatch = useMediaLoadingDispatch();
  const location = useLocation();
  const history = useHistory();
  const studioConfig: StudioConfig = studio_config;
  const isNewCreative = is_new_creative(location);
  const isFormatPreviewSticky = is_format_preview_sticky();
  const firstSlide = creative.metadata.slides[0];
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const [creativeLoading, setCreativeLoading] = useState(false);
  const [displayColorPickerCtaButton, setDisplayColorPickerCtaButton] = useState(false);
  const [displayColorPickerCtaText, setDisplayColorPickerCtaText] = useState(false);
  const [displayColorPickerIcons, setDisplayColorPickerIcons] = useState(false);
  const [brandLogoModalState, setBrandLogoModalState] = useState(false);
  const [buttonLogoModalState, setButtonLogoModalState] = useState(false);
  const [requestTagsModalState, setRequestTagsModalState] = useState(false);
  const emptyAdformTaggingInput = { impressionPixel: { value: "", valid: true, errorMessage: "" }, clickPixel: { value: "", valid: true, errorMessage: "" }, javascriptUrl: { value: "", valid: true, errorMessage: "" }, destinationUrl: { value: "", valid: false, errorMessage: "" } };
  const [adformTaggingInputs, setAdformTaggingInputs] = useState(emptyAdformTaggingInput);
  const [matchFrameToggle, setMatchFrameToggle] = useState(false);
  const [saveExistingCreative] = useMutation(mutations.SAVE_EXISTING_CREATIVE);
  const [saveNewCreative] = useMutation(mutations.SAVE_NEW_CREATIVE);
  const [updateCreativeAdform] = useMutation(mutations.UPDATE_CREATIVE_ADSERVER);

  const {
    error: LTAError,
    loading: LTALoading,
    data: LTAData,
  } = useQuery(queries.LIST_TEAM_ADVERTISERS, {
    variables: { teamId: user.teamId },
    onError: (error) => {
      logger({ error, user });
    },
  });
  const {
    error: GECError,
    loading: GECLoading,
    data: GECData,
  } = useQuery(queries.GET_EXISTING_CREATIVE, {
    variables: { id: creativeId },
    skip: isNewCreative,
    onCompleted: async (existingCreativeData) => {
      if (isNewCreative) return;
      try {
        setCreativeLoading(true);
        await set_creative_context_data(existingCreativeData, creativeDispatch, creativeId);
        setCreativeLoading(false);
      } catch (error) {
        setCreativeLoading(false);
        console.log(error);
      }
    },
    onError: (error) => {
      logger({ error, user });
    },
  });
  const { error: GTAError, loading: GTALoading, data: GTAData } = useQuery(queries.GET_TEAM_ADSERVERS_ADFORM_ADVERTISERID, { variables: { id: user.teamId } });

  useEffect(() => {
    creativeDispatch({ type: "UPDATE_CREATIVE_CREATIVEID", payload: { creativeId } });
  }, [creativeId]);
  //
  // EXPORT PNG BUTTON
  //
  const handleExportPng = async (e: any) => {
    e.currentTarget.blur();
    if (config.analytics.DISABLED === false) {
      let eventTimeString = create_new_time_isostring();
      analytics_record({
        name: "exportPng",
        attributes: {
          eventName: "click",
          userId: `${user.id}`,
          userEmail: `${user.email}`,
          teamId: `${user.teamId}`,
          teamName: `${user.teamName}`,
          advertiserId: `${creative.advertiserId}`,
          advertiserName: `${creative.advertiserName}`,
          creativeId: `${creativeId}`,
          adName: `${creative.adName}`,
          brandName: `${creative.brandName}`,
          format: `${creative.metadata.format}`,
          assetType: `${creative.metadata.slides.find((slide: any) => slide.media.mimeType.includes("video")) ? "video" : "image"}`,
          day: `${format_date_day_from_string(eventTimeString)}`,
          month: `${format_date_month_from_string(eventTimeString)}`,
          year: `${format_date_year_from_string(eventTimeString)}`,
          hour: `${format_time_hour_from_string(eventTimeString)}`,
          minute: `${format_time_minute_from_string(eventTimeString)}`,
        },
      });
    }

    try {
      let screenshot = await dom_to_image(document.getElementById("format-preview-container"));
      var imageUrl = URL.createObjectURL(screenshot);
      // Works out which container image to use
      let container = studioConfig.formats[creative.metadata.format].exportPng[creative.metadata.aspectRatio].container === "story" ? exportPNGContainerStory : exportPNGContainerPost;
      // Merge created screenshot with phone container image
      let mergedImage = await merge_images([
        { src: container, x: 0, y: 0 },
        {
          src: imageUrl,
          x: studioConfig.formats[creative.metadata.format].exportPng[creative.metadata.aspectRatio].xValue,
          y: studioConfig.formats[creative.metadata.format].exportPng[creative.metadata.aspectRatio].yValue,
          width: studioConfig.formats[creative.metadata.format].media.aspectRatios[creative.metadata.aspectRatio].width,
          height: studioConfig.formats[creative.metadata.format].media.aspectRatios[creative.metadata.aspectRatio].height,
        },
      ]);
      save_file_locally(mergedImage, `${creative.adName}-export.png`);
      // remove reference to imageURl
      URL.revokeObjectURL(imageUrl);
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Creative exported",
          message: "",
          type: "success",
          autoDismiss: true,
        },
      });
    } catch (error) {
      logger({ error, user });
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error exporting creative",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  //
  // PREVIEW BUTTON
  //
  const handlePreview = (e: any) => {
    e.currentTarget.blur();
    if (config.analytics.DISABLED === false) {
      let eventTimeString = create_new_time_isostring();
      analytics_record({
        name: "preview",
        attributes: {
          eventName: "click",
          userId: `${user.id}`,
          userEmail: `${user.email}`,
          teamId: `${user.teamId}`,
          teamName: `${user.teamName}`,
          advertiserId: `${creative.advertiserId}`,
          advertiserName: `${creative.advertiserName}`,
          creativeId: `${creativeId}`,
          adName: `${creative.adName}`,
          brandName: `${creative.brandName}`,
          format: `${creative.metadata.format}`,
          assetType: `${creative.metadata.slides.find((slide: any) => slide.media.mimeType.includes("video")) ? "video" : "image"}`,
          day: `${format_date_day_from_string(eventTimeString)}`,
          month: `${format_date_month_from_string(eventTimeString)}`,
          year: `${format_date_year_from_string(eventTimeString)}`,
          hour: `${format_time_hour_from_string(eventTimeString)}`,
          minute: `${format_time_minute_from_string(eventTimeString)}`,
        },
      });
    }
    const url = `${config.preview.URL}/${studioConfig.formats[creative.metadata.format].media.aspectRatios[creative.metadata.aspectRatio].adslotWidth}x${studioConfig.formats[creative.metadata.format].media.aspectRatios[creative.metadata.aspectRatio].adslotHeight}/${user.teamId}/${creativeId}`;
    window.open(url);
  };

  //
  // SAVE BUTTON
  //
  const handleSave = async (e: any) => {
    e.currentTarget.blur();
    // setFileUploadInProgress(true);
    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Saving creative", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
    if (config.analytics.DISABLED === false) {
      let eventTimeString = create_new_time_isostring();
      analytics_record({
        name: "save",
        attributes: {
          eventName: "click",
          userId: `${user.id}`,
          userEmail: `${user.email}`,
          teamId: `${user.teamId}`,
          teamName: `${user.teamName}`,
          advertiserId: `${creative.advertiserId}`,
          advertiserName: `${creative.advertiserName}`,
          creativeId: `${creativeId}`,
          adName: `${creative.adName}`,
          brandName: `${creative.brandName}`,
          format: `${creative.metadata.format}`,
          assetType: `${creative.metadata.slides.find((slide: any) => slide.media.mimeType.includes("video")) ? "video" : "image"}`,
          day: `${format_date_day_from_string(eventTimeString)}`,
          month: `${format_date_month_from_string(eventTimeString)}`,
          year: `${format_date_year_from_string(eventTimeString)}`,
          hour: `${format_time_hour_from_string(eventTimeString)}`,
          minute: `${format_time_minute_from_string(eventTimeString)}`,
        },
      });
    }
    try {
      //
      // Validate user inputs
      //
      let validation = validate_ad_creator_form(creative);
      if (validation.status === false) throw new Error(validation.message);

      // KT 22.03.22: this and the object required to duplicate a creative are v similar, so use one factory function.
      const payload = create_dynamodb_creative_data(creative, user, creativeId, false);
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Saving creative", progress: Math.floor(Math.random() * (30 - 11 + 1) + 11) } });
      let domScreenshot = await dom_to_image(document.getElementById("format-preview-container"));

      await s3_media_upload({
        fileKey: "format-preview-original.png",
        mimeType: "image/png",
        body: domScreenshot,
        teamId: user.teamId,
        creativeId,
      });

      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Saving creative", progress: Math.floor(Math.random() * (60 - 31 + 1) + 31) } });
      //
      // 05/05/21 -> Added pixelVideoAsset to save video on all creatives. This forces DV360 to use Rich Media events.
      //
      await add_pixel_video(user.teamId, creativeId);

      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Saving creative", progress: Math.floor(Math.random() * (80 - 61 + 1) + 61) } });

      if (!isNewCreative) {
        // If saving an existing creative:
        await saveExistingCreative({ variables: { input: payload } });
      }
      if (isNewCreative) {
        // If saving a new creative:
        await saveNewCreative({ variables: { input: payload } });
        // Set isNewCreative to false
        history.push({
          search: "",
        });
      }
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Saving creative", progress: Math.floor(Math.random() * (99 - 98 + 1) + 98) } });
      //
      // Creative File Cleanup
      // crucial that this takes place after the creative has been built by the save{*}Creative functions
      //
      await file_cleanup({ teamId: user.teamId, creativeId });
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Success",
          message: "Creative saved successfully",
          type: "success",
          autoDismiss: true,
        },
      });
      // setFileUploadInProgress(false);
    } catch (error) {
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
      logger({ error, user });
      // setFileUploadInProgress(false);
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error saving creative",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  const downloadAdformTags = async (adformTagFileName: string) => {
    try {
      // Get the Adform scriptTagFile as blob (as run into permissions issues/403 errors when using save_file_locally with s3 signed URL).
      let scriptTagFile = await s3_blob_download({ teamId: user.teamId, creativeId: creativeId, fileName: adformTagFileName });
      save_file_locally(scriptTagFile, adformTagFileName);
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Success",
          message: "Tags downloaded",
          type: "success",
          autoDismiss: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const requestAdformTag = async () => {
    try {
      // Check if creative has video assets as used in Adform banner name
      const hasVideo = creative.metadata.slides.filter((slide: any) => slide.media.mimeType.includes("video")).length > 0;
      if (!GTAData.readOneTeamId.adservers.adform.advertiserId) throw new Error("Advertiser id not found");
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "adform", message: "Requesting tags", progress: Math.floor(Math.random() * (30 - 11 + 1) + 11) } });
      // Run the adform creative tags lambda and return tags data (including file name in s3)
      const adformTagsData = await adform_creative_tags({
        creativeId,
        teamId: user.teamId,
        advertiserId: GTAData.readOneTeamId.adservers.adform.advertiserId,
        advertiserName: creative.advertiserName,
        format: creative.metadata.format,
        adName: creative.adName,
        hasVideo,
        clickout: creative.metadata.button.clickout,
        impressionPixel: adformTaggingInputs.impressionPixel.value,
        clickPixel: adformTaggingInputs.clickPixel.value,
        javascriptUrl: adformTaggingInputs.javascriptUrl.value,
        destinationUrl: adformTaggingInputs.destinationUrl.value,
      });
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "adform", message: "Requesting tags", progress: Math.floor(Math.random() * (80 - 61 + 1) + 61) } });
      let { campaignId, adformTagId } = adformTagsData.adformCreativeTagsIds;
      // convert campaign id to string as currently a number
      campaignId = campaignId.toString();
      // Update DynamoDB creative table & creative context. Currently the tagsFileName is set as an array (with a single item), to support multiple tags per creative in future.
      // Updating adserver.name ensures that if any tags are requested for creatives that still have the old adserver string property, its Creative dynamoDB item is updated with the new adserver object - KT 02.08.22
      await updateCreativeAdform({ variables: { name: "adform", id: creativeId, campaignId, adformTagId, tagFileNames: [adformTagsData.adformCreativeTagsFileName] } });
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADSERVER_NAME",
        payload: { name: "adform" },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADSERVER_CAMPAIGNID",
        payload: { campaignId },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADSERVER_TAGID",
        payload: { tagId: adformTagId },
      });
      creativeDispatch({
        type: "UPDATE_CREATIVE_ADSERVER_TAGFILENAMES",
        payload: { tagFileNames: [adformTagsData.adformCreativeTagsFileName] },
      });
      // Auto-download Adform tags
      await downloadAdformTags(adformTagsData.adformCreativeTagsFileName);
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
      setRequestTagsModalState(!requestTagsModalState);
    } catch (error) {
      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error requesting tags",
          message: error.response ? error.response.data.payload : error.message,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  //
  // REQUEST TAG BUTTON
  //
  const handleRequestTag = async (e: any) => {
    e.currentTarget.blur();
    if (config.analytics.DISABLED === false) {
      let eventTimeString = create_new_time_isostring();
      analytics_record({
        name: "request",
        attributes: {
          eventName: "click",
          userId: `${user.id}`,
          userEmail: `${user.email}`,
          teamId: `${user.teamId}`,
          teamName: `${user.teamName}`,
          advertiserId: `${creative.advertiserId}`,
          advertiserName: `${creative.advertiserName}`,
          creativeId: `${creativeId}`,
          adName: `${creative.adName}`,
          brandName: `${creative.brandName}`,
          format: `${creative.metadata.format}`,
          assetType: `${creative.metadata.slides.find((slide: any) => slide.media.mimeType.includes("video")) ? "video" : "image"}`,
          day: `${format_date_day_from_string(eventTimeString)}`,
          month: `${format_date_month_from_string(eventTimeString)}`,
          year: `${format_date_year_from_string(eventTimeString)}`,
          hour: `${format_time_hour_from_string(eventTimeString)}`,
          minute: `${format_time_minute_from_string(eventTimeString)}`,
        },
      });
    }
    try {
      const zipFilesArray = await zip_creative_files({ creativeId, teamId: user.teamId });
      // Request Tags path for picnic users - checks for stag/prod & dev Picnic teamIds
      if (config.managedServiceTeamIds.includes(user.teamId)) {
        await post_slack_message({ zipFilesArray, advertiserName: creative.advertiserName, adName: creative.adName, teamName: user.teamName, email: user.email });
        notificationDispatch({
          type: "ADD",
          payload: {
            header: "Tags requested",
            message: "",
            type: "success",
            autoDismiss: true,
          },
        });
      }
      if (!config.managedServiceTeamIds.includes(user.teamId)) {
        // Request Tags path for client users
        // Note: currently ALL prod clients will use the adform path. Need to check with Partnerships whether this is correct,
        // or whether some teamIds should be added to the 'post_slack_message' path. - KT 18.05.22
        await post_slack_message({ zipFilesArray: null, advertiserName: creative.advertiserName, adName: creative.adName, teamName: user.teamName, email: user.email });
        if (config.environment === "production" || config.environment === "development") {
          // If tags already exist, download existing tags, if not - open the Adform modal.
          creative.adserver.tagId ? downloadAdformTags(creative.adserver.tagFileNames[0]) : setRequestTagsModalState(!requestTagsModalState);
        }
      }
    } catch (error) {
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      logger({ error, user });
      notificationDispatch({
        type: "ADD",
        payload: {
          header: "Error requesting tags",
          message: errorMessage,
          type: "error",
          autoDismiss: false,
        },
      });
    }
  };

  //
  // RENDER SPECIFIC FORMAT FRAMES SECTION
  //
  const renderChosenFormatFramesSection = () => {
    switch (creative.metadata.format) {
      case "story": {
        return <StoryFormat />;
      }
      case "post": {
        return <PostFormat />;
      }
      case "carousel": {
        return <CarouselFormat />;
      }
      case "swipe": {
        return <SwipeFormat />;
      }
      case "clips": {
        return <ClipsFormat />;
      }
      default: {
        return <></>;
      }
    }
  };

  //
  // RENDER SPECIFIC FORMAT PREVIEW
  //
  const renderChosenFormatPreview = () => {
    switch (creative.metadata.format) {
      case "story": {
        return <ComponentLibrary.PCStoryPreview context={creative} studioConfig={studio_config} aspectRatio={creative.metadata.aspectRatio} />;
      }
      case "post": {
        return <ComponentLibrary.PCPostPreview context={creative} studioConfig={studio_config} aspectRatio={creative.metadata.aspectRatio} />;
      }
      case "carousel": {
        return <ComponentLibrary.PCCarouselPreview context={creative} studioConfig={studio_config} />;
      }
      case "swipe": {
        return <ComponentLibrary.PCSwipePreview context={{ ...creative, matchFrameToggle }} studioConfig={studio_config} />;
      }
      case "clips": {
        return <ComponentLibrary.PCClipsPreview context={creative} studioConfig={studio_config} />;
      }
      default: {
        return <></>;
      }
    }
  };

  //
  // VALIDATE ALL S3 URLS EVERY TEN MINS
  //
  useEffect(() => {
    const interval = setInterval(() => {
      validate_all_s3_urls(creative, user.teamId, creativeDispatch);
    }, 600000);
    return () => clearInterval(interval);
  }, [creative]);

  return (
    <>
      {LTALoading || GECLoading || creativeLoading ? (
        <div className="w-100 h-100 justify-center background-color-neutral-2">
          <ComponentLibrary.PCSpinner />
        </div>
      ) : (
        <div className="background-color-neutral-2">
          <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={5}>
            <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={6}>
              <ComponentLibrary.PCSpacer type={"padding"} position={"right"} size={6}>
                <div className="flex flex-column items-center">
                  {/*  */}
                  {/* TOP BAR */}
                  {/*  */}
                  <div className="flex w-100">
                    <div className="pointer">
                      <Link
                        to={{
                          pathname: LTAData.readAllCreativesTeam.filter((item: any) => item.advertiserId === creative.advertiserId).length > 0 ? `/advertiser/${creative.advertiserId}` : "/advertisers",
                        }}
                        style={{ color: "inherit", textDecoration: "none" }}>
                        <ComponentLibrary.PCIconPicker icon={"arrow-left"} />
                      </Link>
                    </div>
                    <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                    <ComponentLibrary.PCHeader text={"Ad Creator"} />
                    <ComponentLibrary.PCActionButton isDisabled={isNewCreative} text={"Export PNG"} clickHandler={handleExportPng} iconLeft={"image"} iconColor={"color-text-5"} textColor={"color-text-5"} color={""} />
                    <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                    <ComponentLibrary.PCActionButton isDisabled={isNewCreative} text={"Preview"} iconLeft={"eye"} iconColor={"color-text-5"} textColor={"color-text-5"} color={""} clickHandler={handlePreview} />
                    <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                    <ComponentLibrary.PCActionButton text={"Save"} iconLeft={"check"} isLoading={fileUploadInProgress} iconColor={"color-text-5"} textColor={"color-text-5"} color={""} clickHandler={handleSave} isDisabled={!creative.advertiserName} />
                    <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                    <ComponentLibrary.PCActionButton isDisabled={isNewCreative} text={"Request Tags"} color={"background-color-primary-3"} textColor={"white"} iconRight={"arrow-right"} clickHandler={handleRequestTag} />
                  </div>
                </div>
                <ComponentLibrary.PCSpacer type={"padding"} position={"top"} size={5} />
                <div className="flex flex-column justify-center w-100">
                  <div className="flex flex-row w-100">
                    {/*  */}
                    {/* LEFT COLUMN */}
                    {/*  */}
                    <div id="creative-left-column" className="w-50">
                      {/*  */}
                      {/* BASIC DETAILS */}
                      {/*  */}
                      <ComponentLibrary.PCContainerMain color={"background-color-neutral-1"}>
                        <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                          <ComponentLibrary.PCHeader text={"New ad"} />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <ComponentLibrary.PCDivider />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <AdvertiserDropdown advertiserList={LTAData.readAllCreativesTeam} />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <div className="w-50">
                            <ComponentLibrary.PCInput
                              label={"Ad name"}
                              placeholder={"Ad name"}
                              type={"text"}
                              value={creative.adName}
                              handleChange={(e: any) => {
                                const { value } = e.target;
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_ADNAME",
                                  payload: { adName: value },
                                });
                              }}
                            />
                          </div>
                        </ComponentLibrary.PCSpacer>
                      </ComponentLibrary.PCContainerMain>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />

                      {/*  */}
                      {/* FORMAT */}
                      {/*  */}
                      <ComponentLibrary.PCContainerMain>
                        <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                          <ComponentLibrary.PCHeader text={"Ad format"} />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <ComponentLibrary.PCFormatMenu
                            handleChange={(formatChoice: string) => {
                              const containsImage = creative.metadata.slides.find((slide: any) => slide.media.mimeType.includes("image"));
                              const containsSplits = creative.metadata.slides.find((slide: any) => slide.media.videoSplitFrame === true);
                              if ((formatChoice === "clips" && containsImage) || (formatChoice === "clips" && containsSplits)) {
                                return notificationDispatch({
                                  type: "ADD",
                                  payload: {
                                    header: "Error",
                                    message: `${containsImage ? "Clips accepts video formats only. Please delete any images to continue." : "Clips accepts separate video files only. Please delete any video splits to continue."}`,
                                    type: "error",
                                    autoDismiss: false,
                                  },
                                });
                              }
                              creativeDispatch({
                                type: "UPDATE_CREATIVE_METADATA_FORMAT",
                                payload: { format: formatChoice },
                              });
                              creativeDispatch({
                                type: "UPDATE_CREATIVE_METADATA_ASPECTRATIO",
                                payload: { aspectRatio: Object.keys(studioConfig.formats[formatChoice].media.aspectRatios)[0] },
                              });
                            }}
                            selected={creative.metadata.format}
                            activeFormats={["post", "story", "carousel", "swipe", "clips"]}
                            newIcons={["clips"]}
                          />
                        </ComponentLibrary.PCSpacer>
                      </ComponentLibrary.PCContainerMain>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />

                      {/*  */}
                      {/* AUTOFILL */}
                      {/* (HTL - 03/05/22 - commented out before major reliability and testing upgrades added to autofill.) */}
                      {/*  */}
                      {/* <ComponentLibrary.PCContainerMain>
                        <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                          <div className="flex">
                            <ComponentLibrary.PCHeader text={"Autofill option"} />
                            <ComponentLibrary.PCCaption text={"optional"} fontWeight={"5"} />
                          </div>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={2} />
                          <div className="flex">
                            <ComponentLibrary.PCCaption text={"Works only for Facebook and Instagram post URLs"} fontWeight={"4"} />
                          </div>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <ComponentLibrary.PCDivider width={"w-100"} />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <ComponentLibrary.PCInput
                            label={"Pull content straight from the URL"}
                            // autofillInProgress ? "Loading.." :
                            placeholder={"Paste URL"}
                            type={"text"}
                            handleChange={async (e: any) => {
                              // setAutofillInProgress(true);
                              mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "autofill", message: "Gathering assets from social link", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                              try {
                                const { value } = e.target;
                                await autofill({
                                  url: value,
                                  format: creative.metadata.format,
                                  aspectRatio: creative.metadata.aspectRatio,
                                  creativeDispatch,
                                  notificationDispatch,
                                  creativeId,
                                  teamId: user.teamId,
                                  mediaLoadingDispatch,
                                });
                                // setAutofillInProgress(false);
                                mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                              } catch (error) {
                                // setAutofillInProgress(false);
                                mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                let errorMessage = "";
                                if (error instanceof Error) {
                                  errorMessage = error.message;
                                }
                                return notificationDispatch({
                                  type: "ADD",
                                  payload: {
                                    header: "Error with autofill",
                                    message: errorMessage,
                                    type: "error",
                                    autoDismiss: false,
                                  },
                                });
                              }
                            }}
                          />
                        </ComponentLibrary.PCSpacer>
                      </ComponentLibrary.PCContainerMain>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} /> */}

                      {/*  */}
                      {/* BRAND */}
                      {/*  */}
                      <ComponentLibrary.PCContainerMain>
                        <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                          <ComponentLibrary.PCHeader text={"Brand"} />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          {creative.metadata.brandLogo.bodyCropped === "" ? (
                            <ComponentLibrary.PCDragAndDrop
                              label={"Brand logo"}
                              clickHandler={(e: any) => {
                                e.target.value = null;
                              }}
                              changeHandler={async (e: any) => {
                                try {
                                  [...e.target.files].forEach(async (file: any, i: any) => {
                                    e.preventDefault();
                                    if (i > 0) return;
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "logo", message: "Uploading logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                    await add_brandlogo({
                                      file,
                                      formatType: creative.metadata.format,
                                      teamId: user.teamId,
                                      creativeId,
                                      creativeDispatch,
                                      mimeType: file.type,
                                      name: file.name,
                                      notificationDispatch,
                                      mediaLoadingDispatch,
                                    });
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                  });
                                } catch (error) {
                                  mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                  logger({ error, user });
                                  return notificationDispatch({
                                    type: "ADD",
                                    payload: {
                                      header: "Error",
                                      message: "Something went wrong.",
                                      type: "error",
                                      autoDismiss: false,
                                    },
                                  });
                                }
                              }}
                              dropHandler={(e: any) => {
                                try {
                                  [...e.dataTransfer.files].forEach(async (file: any, i: any) => {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "logo", message: "Uploading logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                    e.preventDefault();
                                    if (i > 0) return;
                                    await add_brandlogo({
                                      file,
                                      formatType: creative.metadata.format,
                                      teamId: user.teamId,
                                      creativeId,
                                      creativeDispatch,
                                      mimeType: file.type,
                                      name: file.name,
                                      notificationDispatch,
                                      mediaLoadingDispatch,
                                    });
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                  });
                                } catch (error) {
                                  mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                  logger({ error, user });
                                  return notificationDispatch({
                                    type: "ADD",
                                    payload: {
                                      header: "Error",
                                      message: "Something went wrong.",
                                      type: "error",
                                      autoDismiss: false,
                                    },
                                  });
                                }
                              }}
                              buttonText={"Browse"}
                              mediaAssetTitle={"Brand logo"}
                              mediaAllowed={[
                                {
                                  title: "Images",
                                  types: ["jpg", "png"],
                                  maxSize: "2MB",
                                },
                              ]}
                              multipleAllowed={false}
                            />
                          ) : (
                            <div className="flex">
                              <ComponentLibrary.PCPreviewImage height={"128px"} width={"128px"} backgroundImage={creative.metadata.brandLogo.bodyCropped} />
                              <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                              <div className="flex flex-column justify-center">
                                <div className="flex">
                                  <ComponentLibrary.PCActionButton
                                    text={"Crop image"}
                                    clickHandler={(e: any) => {
                                      e.currentTarget.blur();
                                      setBrandLogoModalState(!brandLogoModalState);
                                    }}
                                    textColor={"color-text-5"}
                                    color={"platform-background-platform-background-4"}
                                  />
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                                  <ComponentLibrary.PCDivider vertical={true} />
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                                  <div className="upload-button-wrapper dim relative overflow-hidden dib-ns">
                                    <input
                                      type="file"
                                      className="o-0 absolute top-0 left-0 h-100"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      name="brand_logo_upload"
                                      id="brand_logo_upload"
                                      onChange={(e: any) => {
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Uploading logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                        try {
                                          add_brandlogo({
                                            file: e.target.files[0],
                                            formatType: creative.metadata.format,
                                            teamId: user.teamId,
                                            creativeId,
                                            creativeDispatch,
                                            mimeType: e.target.files[0].type,
                                            name: e.target.files[0].name,
                                            notificationDispatch,
                                            mediaLoadingDispatch,
                                          });
                                          mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                        } catch (error) {
                                          mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                          logger({ error, user });
                                          return notificationDispatch({
                                            type: "ADD",
                                            payload: {
                                              header: "Error",
                                              message: "Something went wrong.",
                                              type: "error",
                                              autoDismiss: false,
                                            },
                                          });
                                        }
                                      }}
                                      onClick={(e: any) => {
                                        e.target.value = null;
                                      }}
                                    />
                                    <ComponentLibrary.PCActionButton isDisabled={false} text={"Replace"} textColor={"color-text-5"} color={"platform-background-platform-background-4"} />
                                  </div>
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                                  <ComponentLibrary.PCActionButton
                                    text={<ComponentLibrary.PCIconPicker icon={"trash"} />}
                                    textColor={"color-text-5"}
                                    color={"platform-background-platform-background-4"}
                                    isDisabled={!creative.metadata.brandLogo.bodyCropped}
                                    clickHandler={(e: any) => {
                                      e.currentTarget.blur();
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_WIDTH",
                                        payload: { width: studioConfig.formats[creative.metadata.format].brandLogo.width },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_HEIGHT",
                                        payload: { height: studioConfig.formats[creative.metadata.format].brandLogo.height },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_ZOOM",
                                        payload: { zoom: 1 },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_X",
                                        payload: { x: 0 },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_Y",
                                        payload: { y: 0 },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_MIMETYPE",
                                        payload: { mimeType: "" },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_ID",
                                        payload: { id: "" },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_FILENAME",
                                        payload: { fileName: "" },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYORIGINAL",
                                        payload: { bodyOriginal: "" },
                                      });
                                      creativeDispatch({
                                        type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_BODYCROPPED",
                                        payload: { bodyCropped: "" },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                          <ComponentLibrary.PCDivider width={"w-100"} />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <div className="w-50">
                            <ComponentLibrary.PCInput
                              value={creative.metadata.brandName}
                              label={"Brand name"}
                              placeholder={"Brand name"}
                              type={"text"}
                              subHeader={"25 characters max"}
                              maxChar={25}
                              characterCounter={true}
                              isError={false}
                              errorMessage={""}
                              handleChange={(e: any) => {
                                const { value } = e.target;
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDNAME",
                                  payload: { brandName: value },
                                });
                              }}
                            />
                          </div>
                        </ComponentLibrary.PCSpacer>
                      </ComponentLibrary.PCContainerMain>
                      <div className="relative">
                        <ComponentLibrary.PCDialogueBox open={brandLogoModalState} width={"width-13"} height={"height-13"} onClose={() => setBrandLogoModalState(!brandLogoModalState)}>
                          <>
                            <ComponentLibrary.PCCropper
                              media={creative.metadata.brandLogo.bodyOriginal || placeholderBrandLogo}
                              // Checks if format is defined - this ensures the component renders even if the format is undefined (e.g. if new creative).
                              cropShape={creative.metadata.format ? studioConfig.formats[creative.metadata.format].brandLogo.cropShape : "round"}
                              mimeType={creative.metadata.brandLogo.mimeType}
                              crop={creative.metadata.brandLogo.crop}
                              zoom={creative.metadata.brandLogo.zoom}
                              aspect={1 / 1}
                              onCropChange={(crop: any) => {
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROP_X",
                                  payload: { x: crop.x },
                                });
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROP_Y",
                                  payload: { y: crop.y },
                                });
                              }}
                              onZoomChange={(zoom: any) => {
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_ZOOM",
                                  payload: { zoom },
                                });
                              }}
                              onCropComplete={(croppedArea: any, croppedAreaPixels: any) => {
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_WIDTH",
                                  payload: { width: croppedAreaPixels.width },
                                });
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_HEIGHT",
                                  payload: { height: croppedAreaPixels.height },
                                });
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_X",
                                  payload: { x: croppedAreaPixels.x },
                                });
                                creativeDispatch({
                                  type: "UPDATE_CREATIVE_METADATA_BRANDLOGO_CROPPEDAREAPIXELS_Y",
                                  payload: { y: croppedAreaPixels.y },
                                });
                              }}
                              buttonClickHandler={async () => {
                                try {
                                  mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "crop", message: "Cropping logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                  setBrandLogoModalState(false);
                                  await edit_brandlogo({
                                    method: "crop",
                                    teamId: user.teamId,
                                    creativeId: creative.creativeId,
                                    fileName: creative.metadata.brandLogo.fileName,
                                    fileId: creative.metadata.brandLogo.id,
                                    mimeType: creative.metadata.brandLogo.mimeType,
                                    inputWidth: creative.metadata.brandLogo.croppedAreaPixels.width,
                                    inputHeight: creative.metadata.brandLogo.croppedAreaPixels.height,
                                    outputWidth: studioConfig.formats[creative.metadata.format].brandLogo.width * 5,
                                    cropX: creative.metadata.brandLogo.croppedAreaPixels.x,
                                    cropY: creative.metadata.brandLogo.croppedAreaPixels.y,
                                    notificationDispatch,
                                    creativeDispatch,
                                    mediaLoadingDispatch,
                                  });
                                  mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                } catch (error) {
                                  setBrandLogoModalState(false);
                                  mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                  logger({ error, user });
                                  return notificationDispatch({
                                    type: "ADD",
                                    payload: {
                                      header: "Error",
                                      message: "Something went wrong.",
                                      type: "error",
                                      autoDismiss: false,
                                    },
                                  });
                                }
                              }}
                            />
                          </>
                        </ComponentLibrary.PCDialogueBox>
                      </div>
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />

                      {/*  */}
                      {/* CONTENT */}
                      {/*  */}
                      {creative.metadata.format === "swipe" || creative.metadata.format === "post" || creative.metadata.format === "carousel" ? (
                        <>
                          <ComponentLibrary.PCContainerMain>
                            <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                              <ComponentLibrary.PCHeader text={"Content"} />
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              <ComponentLibrary.PCDivider />
                              {creative.metadata.format === "swipe" ? (
                                <>
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                                  <div className="flex">
                                    <ComponentLibrary.PCToggle label={"Show match frame"} toggle={matchFrameToggle} handleToggle={() => setMatchFrameToggle(!matchFrameToggle)} />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              <ComponentLibrary.PCMaxlineTextArea
                                header={studioConfig.formats[creative.metadata.format].inputs.content.header}
                                // header={"Description for matches"}
                                subheader={"3 lines max"}
                                rows={3}
                                placeholder={"Post description"}
                                name="post-description"
                                text={decodeURI(creative.metadata.content)}
                                changeHandler={(e: any) => {
                                  const { value } = e.target;
                                  creativeDispatch({
                                    type: "UPDATE_CREATIVE_METADATA_CONTENT",
                                    payload: { content: value },
                                  });
                                }}
                              />
                            </ComponentLibrary.PCSpacer>
                          </ComponentLibrary.PCContainerMain>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                        </>
                      ) : (
                        <></>
                      )}

                      {/*  */}
                      {/* CTA */}
                      {/*  */}
                      <ComponentLibrary.PCContainerMain>
                        <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                          <ComponentLibrary.PCHeader text={"Call to action"} />
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                          <ComponentLibrary.PCDivider />
                          {creative.metadata.format === "carousel" || creative.metadata.format === "story" ? (
                            <>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              <div className="flex">
                                <ComponentLibrary.PCToggle
                                  label={"CTA button"}
                                  //
                                  // Toggle component handles this as 'On' = true, so we need to send down the reverse of button.hidden to this component
                                  //
                                  toggle={!creative.metadata.button.hidden}
                                  handleToggle={() =>
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_HIDDEN",
                                      payload: { hidden: !creative.metadata.button.hidden },
                                    })
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {/* To show the CTA inputs we run 2 seperate checks here */}
                          {/* 1. check if the cta button toggle is true AND the format is story OR carousel */}
                          {/* OR */}
                          {/* 2. just check if the format is either post, swipe, clips, or not selected yet */}

                          {((!creative.metadata.button.hidden && (creative.metadata.format === "story" || creative.metadata.format === "carousel")) || creative.metadata.format === "post" || creative.metadata.format === "swipe" || creative.metadata.format === "clips" || creative.metadata.format === "") && (
                            <>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              <div className="w-50">
                                <ComponentLibrary.PCInput
                                  value={creative.metadata.button.text}
                                  label={"Button text"}
                                  placeholder={"Button text"}
                                  maxChar={35}
                                  characterCounter={true}
                                  type={"text"}
                                  handleChange={(e: any) => {
                                    const { value } = e.target;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_TEXT",
                                      payload: { text: value },
                                    });
                                  }}
                                  subHeader={"35 characters max"}
                                />
                              </div>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              {(creative.metadata.format === "swipe" || creative.metadata.format === "post" || creative.metadata.format === "story" || creative.metadata.format === "clips") && (
                                <>
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                                  <div className="flex flex-wrap">
                                    <div className="relative">
                                      <ComponentLibrary.PCColorPicker
                                        text={"Pick colour"}
                                        label={"CTA button colour"}
                                        color={creative.metadata.button.backgroundColor}
                                        displayColorPicker={displayColorPickerCtaButton}
                                        handleClick={() => setDisplayColorPickerCtaButton(!displayColorPickerCtaButton)}
                                        handleChangeType={(e: any) => {
                                          const value = e.target.value;
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_BACKGROUNDCOLOR",
                                            payload: { backgroundColor: value },
                                          });
                                        }}
                                        handleChange={(e: any) => {
                                          const value = e.hex;
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_BACKGROUNDCOLOR",
                                            payload: { backgroundColor: value },
                                          });
                                        }}
                                        onClickOut={() => {
                                          setDisplayColorPickerCtaButton(false);
                                        }}
                                      />
                                    </div>
                                    <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                                    <div className="relative">
                                      <ComponentLibrary.PCColorPicker
                                        text={"Pick colour"}
                                        label={"CTA text colour"}
                                        color={creative.metadata.button.textColor}
                                        displayColorPicker={displayColorPickerCtaText}
                                        handleClick={() => setDisplayColorPickerCtaText(!displayColorPickerCtaText)}
                                        handleChangeType={(e: any) => {
                                          const value = e.target.value;
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_TEXTCOLOR",
                                            payload: { textColor: value },
                                          });
                                        }}
                                        handleChange={(e: any) => {
                                          const value = e.hex;
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_TEXTCOLOR",
                                            payload: { textColor: value },
                                          });
                                        }}
                                        onClickOut={() => {
                                          setDisplayColorPickerCtaText(false);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          <div className="relative">
                            {(creative.metadata.format === "story" || creative.metadata.format === "post") && (
                              <>
                                <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                                <ComponentLibrary.PCDivider width={"w-100"} />
                                <ComponentLibrary.PCSpacer type={"margin"} position={"bottom"} size={4} />
                                <ComponentLibrary.PCColorPicker
                                  text={"Pick colour"}
                                  label={"Icons colour"}
                                  tooltipMessage={creative.metadata.format === "story" ? "Progress bar colour" : "Navigation icons/active dots colour"}
                                  color={creative.metadata.icons.backgroundColor}
                                  displayColorPicker={displayColorPickerIcons}
                                  handleClick={() => setDisplayColorPickerIcons(!displayColorPickerIcons)}
                                  handleChangeType={(e: any) => {
                                    const value = e.target.value;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_ICONS_BACKGROUNDCOLOR",
                                      payload: { backgroundColor: value },
                                    });
                                  }}
                                  handleChange={(e: any) => {
                                    const value = e.hex;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_ICONS_BACKGROUNDCOLOR",
                                      payload: { backgroundColor: value },
                                    });
                                  }}
                                  onClickOut={() => {
                                    setDisplayColorPickerIcons(false);
                                  }}
                                />
                              </>
                            )}
                            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                            <ComponentLibrary.PCDivider width={"w-100"} />
                            <ComponentLibrary.PCSpacer type={"margin"} position={"bottom"} size={3} />
                            {creative.metadata.slides
                              // Shows Audio toggle if media contains video (with sound), and if format supports toggling audio
                              .map((slide: any) => slide.media.mimeType)
                              .toString()
                              .includes("video") &&
                              studioConfig.formats[creative.metadata.format].inputs.audio.audioOnToggle &&
                              creative.metadata.slides.map((slide: any) => slide.media.audioExists).includes(true) && (
                                <>
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                                  <div className="relative">
                                    <ComponentLibrary.PCToggle
                                      label={"Audio"}
                                      toggle={creative.metadata.icons.audioOn}
                                      handleToggle={() => {
                                        creative.metadata.slides.forEach((slide: any) => {
                                          if (slide.media.audioExists === true) {
                                            slide.media.audio = !creative.metadata.icons.audioOn;
                                          }
                                        });
                                        creativeDispatch({
                                          type: "UPDATE_CREATIVE_METADATA_ICONS_AUDIOON",
                                          payload: { audioOn: !creative.metadata.icons.audioOn },
                                        });
                                        /*
                                      
                                      */
                                      }}
                                    />
                                    <ComponentLibrary.PCSpacer type={"padding"} position={"bottom"} size={6} />
                                    {!creative.metadata.icons.audioOn && (
                                      <>
                                        <ComponentLibrary.PCSpacer type={"padding"} position={"top"} size={3} />
                                        <ComponentLibrary.PCDivider width={"w-100"} />
                                        <ComponentLibrary.PCSpacer type={"padding"} position={"top"} size={4} />
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                          </div>
                          {/* Shows audio icon position buttons if media contains audio, and the format supports editing the audio icon position */}
                          {creative.metadata.slides.map((slide: any) => slide.media.audio).includes(true) && studioConfig.formats[creative.metadata.format].inputs.audio.audioIconPosition && (
                            <>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              <div className="relative">
                                <ComponentLibrary.PCRadioButtons
                                  label={"Audio icon position"}
                                  checked={creative.metadata.icons.audioIconPosition}
                                  handleChecked={(e: any) => {
                                    const value = e.target.value;
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_ICONS_AUDIOICONPOSITION",
                                      payload: { audioIconPosition: value },
                                    });
                                  }}
                                  values={["Left", "Right"]}
                                />
                              </div>
                              <ComponentLibrary.PCSpacer type={"padding"} position={"bottom"} size={6} />
                              <ComponentLibrary.PCSpacer type={"padding"} position={"top"} size={3} />
                              <ComponentLibrary.PCDivider width={"w-100"} />
                              <ComponentLibrary.PCSpacer type={"padding"} position={"top"} size={4} />
                            </>
                          )}
                          {/* End - hides audio position buttons when no audio */}
                          <ComponentLibrary.PCInput
                            value={creative.metadata.button.clickout}
                            label={`${!config.managedServiceTeamIds.includes(user.teamId) ? "Preview link click out URL" : "Click out URL"}`}
                            placeholder={"Paste URL"}
                            type={"text"}
                            handleChange={(e: any) => {
                              const { value } = e.target;
                              creativeDispatch({
                                type: "UPDATE_CREATIVE_METADATA_BUTTON_CLICKOUT",
                                payload: { clickout: value.trim() },
                              });
                            }}
                          />
                        </ComponentLibrary.PCSpacer>
                      </ComponentLibrary.PCContainerMain>

                      {/* BUTTON LOGO */}
                      {creative.metadata.format === "clips" ? (
                        <>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                          <ComponentLibrary.PCContainerMain>
                            <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                              <ComponentLibrary.PCHeader text={"Footer Logo"} />
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              {creative.metadata.button.logo.bodyCropped === "" ? (
                                <ComponentLibrary.PCDragAndDrop
                                  label={"Footer Logo"}
                                  clickHandler={(e: any) => {
                                    e.target.value = null;
                                  }}
                                  changeHandler={async (e: any) => {
                                    try {
                                      [...e.target.files].forEach(async (file: any, i: any) => {
                                        e.preventDefault();
                                        if (i > 0) return;
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "logo", message: "Uploading footer logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                        await add_button_logo({
                                          file,
                                          formatType: creative.metadata.format,
                                          teamId: user.teamId,
                                          creativeId,
                                          creativeDispatch,
                                          mimeType: file.type,
                                          name: file.name,
                                          notificationDispatch,
                                          mediaLoadingDispatch,
                                        });
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                      });
                                    } catch (error) {
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                      logger({ error, user });
                                      return notificationDispatch({
                                        type: "ADD",
                                        payload: {
                                          header: "Error",
                                          message: "Something went wrong.",
                                          type: "error",
                                          autoDismiss: false,
                                        },
                                      });
                                    }
                                  }}
                                  dropHandler={(e: any) => {
                                    try {
                                      [...e.dataTransfer.files].forEach(async (file: any, i: any) => {
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "logo", message: "Uploading logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                        e.preventDefault();
                                        if (i > 0) return;
                                        await add_button_logo({
                                          file,
                                          formatType: creative.metadata.format,
                                          teamId: user.teamId,
                                          creativeId,
                                          creativeDispatch,
                                          mimeType: file.type,
                                          name: file.name,
                                          notificationDispatch,
                                          mediaLoadingDispatch,
                                        });
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                      });
                                    } catch (error) {
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                      logger({ error, user });
                                      return notificationDispatch({
                                        type: "ADD",
                                        payload: {
                                          header: "Error",
                                          message: "Something went wrong.",
                                          type: "error",
                                          autoDismiss: false,
                                        },
                                      });
                                    }
                                  }}
                                  buttonText={"Browse"}
                                  mediaAssetTitle={"Footer logo"}
                                  mediaAllowed={[
                                    {
                                      title: "Images",
                                      types: ["jpg", "png"],
                                      maxSize: "2MB",
                                    },
                                  ]}
                                  multipleAllowed={false}
                                />
                              ) : (
                                <div className="flex">
                                  <div>
                                    <ComponentLibrary.PCPreviewImage height={"auto"} width={"auto"} maxWidth={"250px"} maxHeight={"200px"} backgroundImage={creative.metadata.button.logo.bodyCropped} />
                                  </div>
                                  <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={5} />
                                  <div className="flex flex-column justify-center">
                                    <div className="flex">
                                      <ComponentLibrary.PCActionButton
                                        text={"Crop image"}
                                        clickHandler={(e: any) => {
                                          e.currentTarget.blur();
                                          setButtonLogoModalState(!buttonLogoModalState);
                                        }}
                                        textColor={"color-text-5"}
                                        color={"platform-background-platform-background-4"}
                                      />
                                      <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                                      <ComponentLibrary.PCDivider vertical={true} />
                                      <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                                      <div className="upload-button-wrapper dim relative overflow-hidden dib-ns">
                                        <input
                                          type="file"
                                          className="o-0 absolute top-0 left-0 h-100"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          name="button_logo_upload"
                                          id="button_logo_upload"
                                          onChange={(e: any) => {
                                            mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Uploading logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                            try {
                                              add_button_logo({
                                                file: e.target.files[0],
                                                formatType: creative.metadata.format,
                                                teamId: user.teamId,
                                                creativeId,
                                                creativeDispatch,
                                                mimeType: e.target.files[0].type,
                                                name: e.target.files[0].name,
                                                notificationDispatch,
                                                mediaLoadingDispatch,
                                              });
                                              mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                            } catch (error) {
                                              mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                              logger({ error, user });
                                              return notificationDispatch({
                                                type: "ADD",
                                                payload: {
                                                  header: "Error",
                                                  message: "Something went wrong.",
                                                  type: "error",
                                                  autoDismiss: false,
                                                },
                                              });
                                            }
                                          }}
                                          onClick={(e: any) => {
                                            e.target.value = null;
                                          }}
                                        />
                                        <ComponentLibrary.PCActionButton isDisabled={false} text={"Replace"} textColor={"color-text-5"} color={"platform-background-platform-background-4"} />
                                      </div>
                                      <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />
                                      <ComponentLibrary.PCActionButton
                                        text={<ComponentLibrary.PCIconPicker icon={"trash"} />}
                                        textColor={"color-text-5"}
                                        color={"platform-background-platform-background-4"}
                                        isDisabled={!creative.metadata.button.logo.bodyCropped}
                                        clickHandler={(e: any) => {
                                          e.currentTarget.blur();
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_WIDTH",
                                            payload: { width: studioConfig.formats[creative.metadata.format].buttonLogo.width },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_HEIGHT",
                                            payload: { height: studioConfig.formats[creative.metadata.format].buttonLogo.height },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_ZOOM",
                                            payload: { zoom: 1 },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_X",
                                            payload: { x: 0 },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_Y",
                                            payload: { y: 0 },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_MIMETYPE",
                                            payload: { mimeType: "" },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_ID",
                                            payload: { id: "" },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_FILENAME",
                                            payload: { fileName: "" },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_BODYORIGINAL",
                                            payload: { bodyOriginal: "" },
                                          });
                                          creativeDispatch({
                                            type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_BODYCROPPED",
                                            payload: { bodyCropped: "" },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                            </ComponentLibrary.PCSpacer>
                          </ComponentLibrary.PCContainerMain>
                          {/* Footer Logo Crop Modal */}
                          <div className="relative">
                            <ComponentLibrary.PCDialogueBox open={buttonLogoModalState} width={"width-13"} height={"height-13"} onClose={() => setButtonLogoModalState(!buttonLogoModalState)}>
                              <>
                                <ComponentLibrary.PCCropper
                                  media={creative.metadata.button.logo.bodyOriginal || placeholderButtonLogo}
                                  cropShape={studioConfig.formats[creative.metadata.format].buttonLogo.cropShape}
                                  mimeType={creative.metadata.button.logo.mimeType}
                                  crop={creative.metadata.button.logo.crop}
                                  zoom={creative.metadata.button.logo.zoom}
                                  aspect={creative.metadata.button.logo.dimensions.width / creative.metadata.button.logo.dimensions.height}
                                  onCropChange={(crop: any) => {
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROP_X",
                                      payload: { x: crop.x },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROP_Y",
                                      payload: { y: crop.y },
                                    });
                                  }}
                                  onZoomChange={(zoom: any) => {
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_ZOOM",
                                      payload: { zoom },
                                    });
                                  }}
                                  onCropComplete={(croppedArea: any, croppedAreaPixels: any) => {
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_WIDTH",
                                      payload: { width: croppedAreaPixels.width },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_HEIGHT",
                                      payload: { height: croppedAreaPixels.height },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_X",
                                      payload: { x: croppedAreaPixels.x },
                                    });
                                    creativeDispatch({
                                      type: "UPDATE_CREATIVE_METADATA_BUTTON_LOGO_CROPPEDAREAPIXELS_Y",
                                      payload: { y: croppedAreaPixels.y },
                                    });
                                  }}
                                  buttonClickHandler={async () => {
                                    try {
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "crop", message: "Cropping logo", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                      setButtonLogoModalState(false);
                                      await edit_button_logo({
                                        method: "crop",
                                        teamId: user.teamId,
                                        creativeId: creative.creativeId,
                                        fileName: creative.metadata.button.logo.fileName,
                                        fileId: creative.metadata.button.logo.id,
                                        mimeType: creative.metadata.button.logo.mimeType,
                                        inputWidth: creative.metadata.button.logo.croppedAreaPixels.width,
                                        inputHeight: creative.metadata.button.logo.croppedAreaPixels.height,
                                        outputWidth: studioConfig.formats[creative.metadata.format].buttonLogo.width * 5,
                                        cropX: creative.metadata.button.logo.croppedAreaPixels.x,
                                        cropY: creative.metadata.button.logo.croppedAreaPixels.y,
                                        notificationDispatch,
                                        creativeDispatch,
                                        mediaLoadingDispatch,
                                      });
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    } catch (error) {
                                      setButtonLogoModalState(false);
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                      logger({ error, user });
                                      return notificationDispatch({
                                        type: "ADD",
                                        payload: {
                                          header: "Error",
                                          message: "Something went wrong.",
                                          type: "error",
                                          autoDismiss: false,
                                        },
                                      });
                                    }
                                  }}
                                />
                              </>
                            </ComponentLibrary.PCDialogueBox>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/*  */}
                      {/* FRAMES */}
                      {/*  */}
                      {creative.metadata.format && (
                        <>
                          <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                          <ComponentLibrary.PCContainerMain>
                            <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={4}>
                              <div className="flex nowrap">
                                <ComponentLibrary.PCHeader text={"Frames"} />
                                <ComponentLibrary.PCCaption text={studioConfig.formats[creative.metadata.format].inputs.frames.frameCaption} fontWeight={"5"} />
                              </div>
                              <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                              <ComponentLibrary.PCDragAndDrop
                                label={"Frame media"}
                                changeHandler={(e: any) => {
                                  try {
                                    [...e.target.files].forEach(async (file: any, i: any) => {
                                      e.preventDefault();
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Uploading assets", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                      let fileNumber = creative.metadata.slides.length + i;
                                      if (firstSlide.media.bodyOriginal) {
                                        fileNumber++;
                                      }
                                      if (fileNumber > studioConfig.formats[creative.metadata.format].maxSlides) {
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                        return notificationDispatch({
                                          type: "ADD",
                                          payload: {
                                            header: "Error",
                                            message: "Max Slides Already Reached",
                                            type: "error",
                                            autoDismiss: false,
                                          },
                                        });
                                      }
                                      if (fileNumber > 10) {
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                        return;
                                      }
                                      if (fileNumber !== 1) {
                                        creativeDispatch({
                                          type: "ADD_NEW_SLIDE",
                                          payload: { slideNumber: fileNumber },
                                        });
                                      }
                                      await add_asset({
                                        file,
                                        slideNumber: fileNumber,
                                        creativeDispatch,
                                        formatType: creative.metadata.format,
                                        aspectRatio: creative.metadata.aspectRatio,
                                        mimeType: file.type,
                                        name: file.name,
                                        teamId: user.teamId,
                                        creativeId: creative.creativeId,
                                        notificationDispatch,
                                        mediaLoadingDispatch,
                                      });
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    });
                                  } catch (error) {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    logger({ error, user });
                                    return notificationDispatch({
                                      type: "ADD",
                                      payload: {
                                        header: "Error",
                                        message: "Something went wrong.",
                                        type: "error",
                                        autoDismiss: false,
                                      },
                                    });
                                  }
                                }}
                                dropHandler={(e: any) => {
                                  try {
                                    [...e.dataTransfer.files].forEach(async (file: any, i: any) => {
                                      e.preventDefault();
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: true, type: "upload", message: "Uploading assets", progress: Math.floor(Math.random() * (10 - 3 + 1) + 3) } });
                                      let fileNumber = creative.metadata.slides.length + i;
                                      if (firstSlide.media.bodyOriginal) {
                                        fileNumber++;
                                      }
                                      if (fileNumber > studioConfig.formats[creative.metadata.format].maxSlides) {
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                        return notificationDispatch({
                                          type: "ADD",
                                          payload: {
                                            header: "Error",
                                            message: "Max Slides Already Reached",
                                            type: "error",
                                            autoDismiss: false,
                                          },
                                        });
                                      }
                                      if (fileNumber > 10) {
                                        mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                        return;
                                      }
                                      if (fileNumber !== 1) {
                                        creativeDispatch({
                                          type: "ADD_NEW_SLIDE",
                                          payload: { slideNumber: fileNumber },
                                        });
                                      }
                                      await add_asset({
                                        file,
                                        slideNumber: fileNumber,
                                        creativeDispatch,
                                        formatType: creative.metadata.format,
                                        aspectRatio: creative.metadata.aspectRatio,
                                        mimeType: file.type,
                                        name: file.name,
                                        teamId: user.teamId,
                                        creativeId: creative.creativeId,
                                        notificationDispatch,
                                        mediaLoadingDispatch,
                                      });
                                      mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    });
                                  } catch (error) {
                                    mediaLoadingDispatch({ type: "UPDATE_LOADING_STATE", payload: { status: false, type: "", message: "", progress: 0 } });
                                    logger({ error, user });
                                    return notificationDispatch({
                                      type: "ADD",
                                      payload: {
                                        header: "Error",
                                        message: "Something went wrong.",
                                        type: "error",
                                        autoDismiss: false,
                                      },
                                    });
                                  }
                                }}
                                clickHandler={(e: any) => {
                                  e.target.value = null;
                                }}
                                buttonText={"Browse"}
                                mediaAssetTitle={"Media"}
                                mediaAllowed={
                                  creative.metadata.format === "post" || creative.metadata.format === "story"
                                    ? [
                                        { title: "Images", types: ["jpg", "png"], maxSize: "40MB" },
                                        { title: "Videos", types: ["mp4"], maxSize: "40MB" },
                                      ]
                                    : creative.metadata.format === "carousel" || creative.metadata.format === "swipe"
                                    ? [{ title: "Images", types: ["jpg", "png"], maxSize: "40MB" }]
                                    : creative.metadata.format === "clips"
                                    ? [{ title: "Videos", types: ["mp4"], maxSize: "40MB" }]
                                    : []
                                }
                                multipleAllowed={true}
                              />
                              <ComponentLibrary.PCSpacer type="margin" position="top" size={5} />
                              <ComponentLibrary.PCDivider />
                              <ComponentLibrary.PCSpacer type="margin" position="top" size={5} />
                              <div className="flex">
                                <p className="text-size-3 color-text-5 platform-font ma0 fw5">{"Frame ratio"}</p>
                                <div className="margin-left-2 color-text-5">
                                  <ComponentLibrary.PCIconPicker icon={"info-circle"} />
                                </div>
                              </div>
                              <ComponentLibrary.PCSpacer type="margin" position="top" size={4} />
                              <ComponentLibrary.PCAspectRatioBar
                                aspectRatio={creative.metadata.aspectRatio}
                                options={studioConfig.formats[creative.metadata.format].media.aspectRatios}
                                handleClick={(aspectRatio: string) => {
                                  creativeDispatch({
                                    type: "UPDATE_CREATIVE_METADATA_ASPECTRATIO",
                                    payload: { aspectRatio },
                                  });
                                }}
                              />
                            </ComponentLibrary.PCSpacer>
                          </ComponentLibrary.PCContainerMain>
                        </>
                      )}
                      {renderChosenFormatFramesSection()}
                    </div>
                    <ComponentLibrary.PCSpacer type={"margin"} position={"left"} size={3} />

                    {/*  */}
                    {/* RIGHT COLUMN */}
                    {/*  */}
                    <div id="creative-right-column" className="w-50">
                      <ComponentLibrary.PCContainerMain color={"background-color-neutral-2"} extraClasses={"h-100 items-center"}>
                        <div className="flex flex-column items-center h-100" id="right-col-inner">
                          <div style={isFormatPreviewSticky ? { position: "sticky", top: 50 } : {}}>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"around"} size={3}>
                              <div className="tc">
                                <ComponentLibrary.PCLabel text={"PREVIEW"} letterSpacing={"1px"} />
                              </div>
                            </ComponentLibrary.PCSpacer>
                            {/* RENDER FORMAT PREVIEW */}
                            <div id="format-preview-container">
                              <ComponentLibrary.PCSpacer type={"padding"} position={"around"} size={2}>
                                {renderChosenFormatPreview()}
                              </ComponentLibrary.PCSpacer>
                            </div>
                            <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                            {!creative.metadata.format ? (
                              <div className="flex flex-column justify-center tc">
                                <img style={{ width: 320 }} src={PlaceholderCreative} />
                                <ComponentLibrary.PCSpacer type={"margin"} position={"around"} size={5}>
                                  <ComponentLibrary.PCLabel text={"Select ad format to see preview"} />
                                </ComponentLibrary.PCSpacer>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </ComponentLibrary.PCContainerMain>
                    </div>
                  </div>
                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />

                  {/*  */}
                  {/* BOTTOM BAR */}
                  {/*  */}
                  <div className="flex flex-column items-center">
                    <div className="w-100">
                      <ComponentLibrary.PCDivider />
                      <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={5} />
                      <div className="flex">
                        <div className="w-100">
                          <ComponentLibrary.PCCaption text={"Manage ad"} fontWeight={"4"} />
                        </div>
                        <ComponentLibrary.PCActionButton isDisabled={isNewCreative} text={"Export PNG"} iconLeft={"image"} iconColor={"color-text-5"} textColor={"color-text-5"} color={""} clickHandler={handleExportPng} />
                        <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                        <ComponentLibrary.PCActionButton isDisabled={isNewCreative} text={"Preview"} iconLeft={"eye"} iconColor={"color-text-5"} textColor={"color-text-5"} color={""} clickHandler={handlePreview} />
                        <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                        <ComponentLibrary.PCActionButton text={"Save"} iconLeft={"check"} isLoading={fileUploadInProgress} iconColor={"color-text-5"} textColor={"color-text-5"} color={""} clickHandler={handleSave} isDisabled={!creative.advertiserName} />
                        <ComponentLibrary.PCSpacer type={"padding"} position={"left"} size={3} />
                        <ComponentLibrary.PCActionButton isDisabled={isNewCreative} text={"Request Tags"} color={"background-color-primary-3"} textColor={"white"} iconRight={"arrow-right"} clickHandler={handleRequestTag} />
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  {/* Adform Tagging modal */}
                  {/*  */}
                  <div className="relative">
                    <ComponentLibrary.PCDialogueBox
                      open={requestTagsModalState}
                      width={"width-13"}
                      height={"height-13"}
                      onClose={() => {
                        setAdformTaggingInputs(emptyAdformTaggingInput);
                        setRequestTagsModalState(!requestTagsModalState);
                      }}>
                      <>
                        <ComponentLibrary.PCHeader text="Tagging"></ComponentLibrary.PCHeader>
                        <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                        {/* Destination URL */}
                        <ComponentLibrary.PCInput
                          label={"Click Tracking Tag (Destination URL) *"}
                          type={"text"}
                          handleChange={async (e: any) => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, destinationUrl: { value: e.target.value, valid: false, errorMessage: "" } });
                          }}
                          handleBlur={() => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, destinationUrl: validate_adform_url(adformTaggingInputs.destinationUrl.value, true) });
                          }}
                          isError={adformTaggingInputs.destinationUrl.errorMessage === "" ? false : true}
                          errorMessage={adformTaggingInputs.destinationUrl.errorMessage}
                        />
                        <ComponentLibrary.PCLabel text="* required input."></ComponentLibrary.PCLabel>
                        <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                        {/* Impression Tracking Pixel */}
                        <ComponentLibrary.PCInput
                          label={"Impression Tracking Pixel"}
                          type={"text"}
                          handleChange={async (e: any) => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, impressionPixel: { value: e.target.value, valid: false, errorMessage: "" } });
                          }}
                          handleBlur={() => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, impressionPixel: validate_adform_url(adformTaggingInputs.impressionPixel.value, false) });
                          }}
                          isError={adformTaggingInputs.impressionPixel.errorMessage === "" ? false : true}
                          errorMessage={adformTaggingInputs.impressionPixel.errorMessage}
                        />
                        <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                        {/* Click Pixel */}
                        <ComponentLibrary.PCInput
                          label={"Click Pixel"}
                          type={"text"}
                          handleChange={async (e: any) => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, clickPixel: { value: e.target.value, valid: false, errorMessage: "" } });
                          }}
                          handleBlur={() => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, clickPixel: validate_adform_url(adformTaggingInputs.clickPixel.value, false) });
                          }}
                          isError={adformTaggingInputs.clickPixel.errorMessage === "" ? false : true}
                          errorMessage={adformTaggingInputs.clickPixel.errorMessage}
                        />
                        <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                        {/* JavaScript URL */}
                        <ComponentLibrary.PCInput
                          label={"JavaScript URL"}
                          type={"text"}
                          handleChange={async (e: any) => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, javascriptUrl: { value: e.target.value, valid: false, errorMessage: "" } });
                          }}
                          handleBlur={() => {
                            setAdformTaggingInputs({ ...adformTaggingInputs, javascriptUrl: validate_adform_url(adformTaggingInputs.javascriptUrl.value, false) });
                          }}
                          isError={adformTaggingInputs.javascriptUrl.errorMessage === "" ? false : true}
                          errorMessage={adformTaggingInputs.javascriptUrl.errorMessage}
                        />
                        <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={4} />
                        <ComponentLibrary.PCActionButton
                          text="Apply &amp; Request Tags"
                          iconLeft={"tag"}
                          textColor={"white"}
                          color={"background-color-primary-3"}
                          iconColor={"white"}
                          clickHandler={async () => {
                            if (adformTaggingInputs.impressionPixel.valid && adformTaggingInputs.clickPixel.valid && adformTaggingInputs.javascriptUrl.valid && adformTaggingInputs.destinationUrl.valid) {
                              await requestAdformTag();
                            } else {
                              return notificationDispatch({
                                type: "ADD",
                                payload: {
                                  header: "Error",
                                  message: "Adform Tagging inputs must be valid urls.",
                                  type: "error",
                                  autoDismiss: false,
                                },
                              });
                            }
                          }}
                        />
                      </>
                    </ComponentLibrary.PCDialogueBox>
                  </div>
                  <ComponentLibrary.PCSpacer type={"margin"} position={"top"} size={3} />
                </div>
              </ComponentLibrary.PCSpacer>
            </ComponentLibrary.PCSpacer>
          </ComponentLibrary.PCSpacer>
        </div>
      )}
    </>
  );
}
